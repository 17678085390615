export class ReqManageSlotModel{
    startTime: number;
    endTime: number;
    duration: number;
    slotMastId: number;
    slotType:number;
    public constructor() {}
}
       
     
   