export class MstDepartmentMast{
 deptName: string;
 deptShName: string;
 activeYn: string;
 deptCode: number;
 deptType: number;
 estCode: number;
 afiVersion: number;
 public constructor() {}
}
	
  
