export class MedPharmacyItemMast {
    itemName: string;
    itemShortName: string;
    activeYn: string;
    itemId: number;
    itemUnit: number;
    estCode: number;
    unitPrice: number;
    genericId: number;
    genericName: string;
    medGroupName: string;
    prevId: number;
    //checked?:boolean;
    public constructor() { }
}

