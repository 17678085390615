import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';

@Component({
    selector: 'app-his-patient-search-bar',
    templateUrl: './app-his-patient-search-bar.component.html',
    styleUrls: ['./app-his-patient-search-bar.component.css']

})

export class PatientSearchBarComponent implements OnInit {
    @Input()
    public patientNo: string;//number;
    public patientItem: any = [];

    searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();

    constructor(private icareservices: ICAREServices, public datepipe: DatePipe, private toastr: ToastrService) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.patientItem = [];
        this.searchVisit();
    }

    /* search patient list */
    searchVisit() {
        if (this.patientNo) {
            this.searchModel.patientNo = this.patientNo;
            this.icareservices.getPatientViewColl(this.searchModel.patientNo)
                .subscribe((response) => {
                    this.patientItem = response;
                    this.bindAge();
                }, err => {
                    this.toastr.warning(err.split(":")[3], 'Alert !');
                });
        }
    }

    bindAge() {

        if (this.patientItem.birthDate) {
          const bdate = new Date(this.patientItem.birthDate).toString();
          //const timeDiff = Math.abs(Date.now() - bdate.getTime());
          //Used Math.floor instead of Math.ceil
          //so 26 years and 140 days would be considered as 26, not 27.
          //this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

          var yearThen = parseInt(bdate.substring(0,4), 10);
          var monthThen = parseInt(bdate.substring(5,7), 10);
          var dayThen = parseInt(bdate.substring(8,10), 10);
          var today = new Date();
          var birthday = new Date(yearThen, monthThen-1, dayThen);
          var differenceInMilisecond = today.valueOf() - birthday.valueOf();
          var year_age = Math.floor(differenceInMilisecond / 31536000000);
          var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);

            if ((today.getMonth() == birthday.getMonth()) && (today.getDate() == birthday.getDate())) {
                alert("Happy B'day!!!");
            }

            var month_age = Math.floor(day_age / 30);

            day_age = day_age % 30;

            console.log("You are age :" + year_age + " years :" + month_age + " months :" + day_age + " days</span> old")
       
        
        //   var today = new Date();
        //   var birthDate = new Date(this.patientItem.birthDate);
        //   var age = today.getFullYear() - birthDate.getFullYear();
        //   var m = today.getMonth() - birthDate.getMonth();
        //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //       age--;
        //   }
        //   console.log(age)
        //   return age;
        }
      }

      getAge(dateString) {

        var birthdate = new Date(dateString).getTime();
        var now = new Date().getTime();
        // now find the difference between now and the birthdate
        var n = (now - birthdate)/1000;
      
        if (n < 604800) { // less than a week
          var day_n = Math.floor(n/86400);
          return day_n + ' D' + (day_n > 1 ? 's' : '');
        } else if (n < 2629743) {  // less than a month
          var week_n = Math.floor(n/604800);
          return week_n + ' W' + (week_n > 1 ? 's' : '');
        } else if (n < 63113852) { // less than 24 months
          var month_n = Math.floor(n/2629743);
          return month_n + ' M' + (month_n > 1 ? 's' : '');
        } else { 
          var year_n = Math.floor(n/31556926);
          return year_n + ' Y' + (year_n > 1 ? 's' : '');
        }
      }
}
