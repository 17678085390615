export class ClcExamTranDetl{
 examTime: Date;
 examValue: string;
 examRemarks: string;
 examId: number;
 examSrno: number;
 examValueId: number;
 templateId: number;
 examinedBy: number;
 paraId: number;
 estCode: number;
 runId: number;
 afiVersion: number;
 public constructor() {}
}
	
  
