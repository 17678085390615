import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MstDepartmentMast } from '../resources/MstDepartmentMast.resource';
import { IMASTServices } from '../services/Imast.Services';
import { MstDeptMastExt } from '../resources/ext/MstDeptMastExt';



@Component({
  selector: 'app-mast-departments',
  templateUrl: './app-mast-departments.html'
  //  , styleUrls: ['./app-mast-departments.component.css']
})
export class DeptMastComponent implements OnInit {

  addNewDept: boolean;
  editDept: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;


  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  constructor(private imastservices: IMASTServices,private toastr: ToastrService) { }

  modelColl: MstDepartmentMast[] = [];
  modelCollExt: MstDeptMastExt[] = [];
  modelItem: MstDepartmentMast = new MstDepartmentMast();
  retModelColl: any;


  retrieve() {
    this.listDepartment()
  }
  listDepartment() {
    this.imastservices.listMstDepartmentMast(this.modelItem)
      .subscribe((response) => {
        this.retModelColl = response;
        this.modelCollExt = this.retModelColl;
        for (let i in this.retModelColl) {
          this.modelCollExt[i].deptTypeName = this.setDeptTypeName(this.retModelColl[i].deptType);
        }
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  saveInfo() {
    if (isNaN(this.modelItem.deptCode)) {
      this.imastservices.createMstDepartmentMast(this.modelItem)
        .subscribe((response) => {
          this.retModelColl = response;
          this.listDepartment();
          this.toastr.success('Department created successfully', 'Save !');
          //alert('The Department created successfully');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.imastservices.editMstDepartmentMast(this.modelItem)
      .subscribe((response) => {
        this.retModelColl = response;
        this.listDepartment();
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
      this.toastr.success('Department Updated successfully', 'Updated !');
      //alert('The selected department modified successfully');
    }
    this.clearModel();
  }
  setDeptTypeName(typeCode) {
    if (typeCode === 1) {
      return 'Clinical';
    }
    else if (typeCode === 2) {
      return 'Paramedical';
    }
    else if (typeCode === 6) {
      return 'Maintenance';
    }

  }
  removeItem(data) {

    if (confirm("Are you sure to delete the Department : " + data.deptCode + "?")) {
      this.imastservices.deleteMstDepartmentMast(data.deptCode).subscribe
        ((response) => {
          this.listDepartment();
          this.toastr.success('Department : ' + data.deptCode + ' deleted successfully', 'Deleted !');          
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }

  };
  editItem(data) {
    this.addNewDept = true;
    this.modelItem.deptCode = data.deptCode;
    this.modelItem.deptName = data.deptName;
    this.modelItem.afiVersion = data.afiVersion;
    this.modelItem.activeYn = data.activeYn;
    this.modelItem.deptShName = data.deptShName;
    this.modelItem.estCode = data.estCode;
    this.modelItem.deptType = data.deptType;

  };

  clearModel() {
    this.modelItem = new MstDepartmentMast();
    this.addNewDept = false;
  };

  newDeptMast() {
    this.addNewDept = true;
  }

  refresh() {
    this.retrieve();
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printSectionDisplay').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
     <html>
         <head>
             <title></title>
             <style>

             #counter {
              font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              width: 100%;
          }
          
          #counter td, #counter th {
              border: 1px solid #ddd;
              padding: 8px;
          }
           #counter th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              color: #7a4c41;
          }

          
             </style>
         </head>
         <body onload="window.print();window.close()">

         <h1><center>Display Master Details</center></h1>
         
         ${printContents}
        
         </body>
     </html>`
    );
    popupWin.document.close();

    //window.print();
  };

  tableToExcel(table, name) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }


  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("deptMast");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("TR");
      for (i = 1; i < (rows.length - 1); i++) {

        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

}


