import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';

import { ClcTriageDetailView } from '../resources/ClcTriageDetailView.resource';
import {ClcExamTranDetl} from '../resources/ClcExamTranDetl.resource';
import { ClcExamTranCollView } from '../resources/ClcExamTranCollView.resource';
import { ClcPatientHistory } from '../resources/ClcPatientHistory.resource';

@Component({
  selector: 'app-his-visit-summary',
  templateUrl: './app-his-visit-summary.component.html',
  styleUrls: ['./app-his-visit-summary.component.css']
})
export class PatientVisitSummaryComponent implements OnInit {

  @Input()
  public visitId: number;
  
  @Input()
  public patientId: number;

  public persItem: any;
  public entryBy;
  private userColl=this._service.persInfo;
  public estInfo=this._service.estInfo;
  public examTemplate:any=[];
  listTemp: any=[];
  listColl: any[];
  vitalColl: any[];
  listIcdItem: any[];
  listVisitPres: any[];
  listServiceColl: any[];
  listAllergyItem: any[];
  listNoteItem: any[];
  printDate: any;

  triCollItem:any=[]; //: ClcTriageDetailView = new ClcTriageDetailView();
  clcExamTranCollView:ClcExamTranCollView = new ClcExamTranCollView();
  examTranDetail:ClcExamTranDetl=new ClcExamTranDetl();
  clcPatientHistoryColl: ClcPatientHistory[] = [];
  patHistoryColl: any ;

  constructor(private icareservices: ICAREServices, public datepipe: DatePipe, private _service: ErpServices,private toastr: ToastrService) {
    // this.retrieve();
    
  }

  ngOnInit() {
  
    this.printDate = this.formatDate();
  }

  ngOnChanges() {
 
    this.retrieve();
  }

  public retrieve() {
    this.examTranDetail = new ClcExamTranDetl();
    this.clcExamTranCollView = new ClcExamTranCollView();
    this.listTemp =[];
    this.listColl=[];
    
    this.persItem = [];
    this.triCollItem=[];    
    this.searchVisit();
    this.getVisitVitalSigns();
    this.listVisitIcdTran();
    this.listVisitPrescriptions();
    this.listServiceOrder();
    this.listAllergyTran();
    this.getVisitExamTran();
    this.getVisitTriageDetails();
    this.listVisitPatNotes();
    this.getExamtemplate();
    if (this.patientId) {
      this.getPatientHistory(this.patientId)
    }
    
  }

  /* search patient list */
  searchVisit() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.persItem = response;         
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

    /* vitals  */
  getVisitVitalSigns() {
    if (this.visitId) {
      this.icareservices.listVisitVitalSigns(this.visitId)
        .subscribe((response) => {
          this.vitalColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  listAllergyTran() {
    if (this.visitId) {
      this.icareservices.listVisitAllergyTran(this.visitId)
        .subscribe((response) => {
          this.listAllergyItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  listVisitIcdTran() {
    if (this.visitId) {
      this.icareservices.listVisitIcdTran(this.visitId)
        .subscribe((response) => {
          this.listIcdItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  listVisitPrescriptions() {
    if (this.visitId) {
      this.icareservices.listVisitPrescriptions(this.visitId)
        .subscribe((response) => {
          this.listVisitPres = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  listServiceOrder() {
    if (this.visitId) {
      this.icareservices.listVisitServiceOrders(this.visitId)
        .subscribe((response) => {
          this.listServiceColl = response;

        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

    /* Examination */
  getVisitExamTran() {  
      if (this.visitId) { 
        this.icareservices.listVisitExamTran(this.visitId)
          .subscribe((response) => {
            this.listColl=response;
           
            // this.listColl.forEach(element => {
            //   if (this.userColl.persCode == element.createdBy) {
            //     this.entryBy =  this.userColl.printName;
            //     }
            // });
  
            if (this.listColl.length>0) {
              this.entryBy =response[0].mstPersonalMast.printName;
              for(let rec of response){
                this.clcExamTranCollView=rec;
                //merging the exam template array and the transaction array into one
                const result = this.examTemplate.map(val => {
                  return Object.assign({}, val, this.clcExamTranCollView.clcExamTranDetl.filter(v => v.templateId === val.templateId)[0]);
                });
                for (let combined of result){
                  this.listTemp.push(combined);
                }
              }    
                
            }
            
            else{
              this.getExamtemplate();
            } 
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }  
      // else{
      //   this.getExamtemplate();
      // }  
  }
  
  getExamtemplate(){
    if (this.visitId) {
      this.icareservices.listGenExamTemplate()
      .subscribe((response) => {
        if (response && this.examTemplate.length<4) { //for i = 0; i < 5; i++ //let template of response
          for (let i=0; i<response.length; i++) {
            this.examTranDetail=response[i]; //template
            this.examTranDetail.examValue="";
            this.examTemplate.push(this.examTranDetail);
          }
        }
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      }); 
    }
  }

  getVisitTriageDetails(){
      if (this.visitId) {
         this.icareservices.getVisitTriageDetails(this.visitId)
            .subscribe((response) => {
               if(response){
                this.triCollItem = response;
               }
            }, err => {
              this.toastr.warning(err.split(":")[3], 'Alert !');
            }); 
      }
  }

  /* patient Notes */
  listVisitPatNotes() {
    if (this.visitId) {
      this.icareservices.listClcVisitNotes(this.visitId)
        .subscribe((response) => {
          this.listNoteItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  getPatientHistory(patId){
    this.clcPatientHistoryColl = [];
    this.patHistoryColl=[]; 
    this.icareservices.getPatientHistory(patId)
      .subscribe((response: any) => {
        this.patHistoryColl=this.groupByType(response.filter(item => item.status !== null || item.remarks !==null));

      }, err => {
        this.toastr.warning(err, 'Alert !');
      });
  }

  groupByType(array) {   
    let result1= array.reduce((r, { historyTypeName }) => {
      if (!r.some(o => o.historyTypeName == historyTypeName)) {
        r.push({ historyTypeName, groupItem: array.filter(v => v.historyTypeName == historyTypeName) });
      }
      return r;
    }, []);
    return result1;
  }

  formatDate() {
      let dt = new Date();
      let day;
      if (dt.getDate() < 10) {
        day = '0' + dt.getDate();
      } else {
        day = dt.getDate();
      }
      let month = dt.getMonth() + 1;
      let year = (dt.getFullYear() + '').substr(2, 2);
      let hours = dt.getHours();
      let minutes = dt.getMinutes();
      return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
    }

    getAge(dateString) {

      var birthdate = new Date(dateString).getTime();
      var now = new Date().getTime();
      // now find the difference between now and the birthdate
      var n = (now - birthdate)/1000;
    
      if (n < 604800) { // less than a week
        var day_n = Math.floor(n/86400);
        return day_n + ' D' + (day_n > 1 ? 's' : '');
      } else if (n < 2629743) {  // less than a month
        var week_n = Math.floor(n/604800);
        return week_n + ' W' + (week_n > 1 ? 's' : '');
      } else if (n < 63113852) { // less than 24 months
        var month_n = Math.floor(n/2629743);
        return month_n + ' M' + (month_n > 1 ? 's' : '');
      } else { 
        var year_n = Math.floor(n/31556926);
        return year_n + ' Y' + (year_n > 1 ? 's' : '');
      }
    }
}
