export class BillVTranExt{
    billNo: number;
    billType: number;
    billStatusDesc: string;
    billDate: String;
    createdTime: Date;
    patientNo: string;
    patientName: string;
    referenceDesc: string;
    policyPaymentFlag: string;
    lineNo: number;
    itemName: string;
    itemDesc: string;
     itemQty: number;
     packageItemQty: number;
     itemUom: number;
    unitPrice: number;
    deductionAmt: number;
    discountAmt: number;
    coPayAmt: number;
    netAmt: number;
    approvedByName: string;
 cancelledByName: string;
 policyName: string;
    public constructor() {}
   }