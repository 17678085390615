import { Component, OnInit,  Input, ElementRef, ViewChild,Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';

declare var $;

@Component({
  selector: 'app-his-patient-search',
  templateUrl: './app-his-patient-search.component.html',
  styleUrls: ['./app-his-patient-search.component.css']

})

export class PatientSearchComponent implements OnInit {
 
 @Input()
 public visitId: number;
 
 @Output() sendDataToParent = new EventEmitter<any>();


 searchItems: any;
 nationColl: any;
 genderColl: any;
 selectedRow: Number;
 public submitted: boolean = false;

 searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();

 @ViewChild('ModalPatientSearch', { static: false }) ModalPatientSearch: ElementRef;
  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  private retrieve() {

    this.imastservices.listMstCountryMast().subscribe(response => {
      this.nationColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

    this.imastservices.listMstSexMast().subscribe(response => {
      this.genderColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

  }

 bindPatientVisit(patColl) {
    this.selectedRow = patColl;
  } 



  selectedPatVisit(patColl) {

    this.sendDataToParent.emit(patColl);
   
    //if ($(this.ModalPatientSearch.nativeElement).modal('show')) {
     // $(this.ModalPatientSearch.nativeElement).modal('hide');
      /* this.icareservices.getPatientViewColl(patColl.patientNo)
        .subscribe((response) => {
          console.log( response);
          
        }); */
   // }
  }

  onKeyPress(event) {
    this.searchPatientList(this.searchModel);
  }

  searchPatientList(searchModel) {
    this.icareservices.listPatientColl(searchModel)
      .subscribe((response) => {
        this.searchItems = response;
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  clearModel(){
    this.searchModel = new ClcPatientQueryVO();
    this.searchPatientList(this.searchModel);
  }
  

}
