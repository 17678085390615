import {MstPersonalMast} from './MstPersonalMast.resource';
import {ClcExamTranDetl} from './ClcExamTranDetl.resource';
export class ClcExamTranCollView{
 createdTime: Date;
 examType: string;
 examId: number;
 deptCode: number;
 clinicCode: number;
 createdBy: number;
 rootTemplateId: number;
 visitId: number;
 pregnancyId: number;
 estCode: number;
 afiVersion: number;
 mstPersonalMast: MstPersonalMast;
 clcExamTranDetl: ClcExamTranDetl[];
 public constructor() {}
}
	
  
