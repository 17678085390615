
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MstDepartmentMast } from '../resources/MstDepartmentMast.resource';
import { IMASTServices } from '../services/Imast.Services';
import { ImasObservable } from '../services/Imast.Services.Observable';
import { MstEstClinics } from '../resources/MstEstClinics.resource';
// import { element } from '@angular/core/src/render3';
import { MstEstClinicsExt } from '../resources/ext/MstEstClinicsExt';



@Component({
  selector: 'app-mast-clinics',
  templateUrl: './app-mast-clinics.html'
  //  , styleUrls: ['./app-mast-clinics.component.css']
})
export class ClinicMastComponent implements OnInit {
  
  addNewClinic: boolean;
  editClinic: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();

  }

  constructor(private imastservices: IMASTServices,private toastr: ToastrService) { }

  modelColl: MstEstClinics[] = [];

  modelCollExt: any[];//MstEstClinicsExt=new MstEstClinicsExt();

  //modelItem:any;
  modelItemExt: any;
  modelItem: MstEstClinics = new MstEstClinics();
  //modelItemExt: MstEstClinicsExt=new MstEstClinicsExt('apptClinicYn', 'activeYn','clinicShName','clinicName','afiVersion','categoryCode','consultationCharge','visitTypeCode','deptCode','estCode','clinicCode','departmentName');
  deptColl: any;
  department: string;
  retColl: MstEstClinics[] = [];
  retModelColl: any = [];
  private filterResult: any;

  private retrieve() {
   
    this.imastservices.listClinicalDepartments().subscribe(response => {
      this.deptColl = response;
      if(this.deptColl){
        this.listClinicMast();
      }
   }, err => {
    this.toastr.warning(err.split(":")[3], 'Alert !');
  });

  }

  private listClinicMast() {
    this.imastservices.listClinics().subscribe(response => {
      this.retModelColl = response;
      this.modelCollExt = this.retModelColl;
      for (let i in this.retModelColl) {
        this.modelCollExt[i].deptName = this.setDeptName(this.retModelColl[i].deptCode);
      }
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
   
  };

  saveInfo() {  
    if (isNaN(this.modelItem.clinicCode)) {
      this.imastservices.createMstEstClinic(this.modelItem)
        .subscribe((response) => {
          this.retModelColl = response;
          this.listClinicMast();
          this.toastr.success(' Clinic created successfully', 'Save !');
          //alert('The Clinic created successfully');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.imastservices.editMstEstClinic(this.modelItem)
        .subscribe((response) => {
          this.retModelColl = response;
          this.listClinicMast();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
        this.toastr.success('Selected Clinic modified successfully ', 'Save !');
      //alert('The selected Clinic modified successfully');
    }
    this.clearModel();
  }

  setDeptName(deptCode) {
    //console.log(deptCode);
    //let deptName = this.deptColl.filter((item: MstDepartmentMast) => item.deptCode === deptCode)[0].deptName;
    if (deptCode) {
      return this.deptColl.filter((item: MstDepartmentMast) => item.deptCode === deptCode)[0].deptName;
    }
  }

  removeItem(data) {
    if (confirm("Are you sure to delete the clinic  "  + "?")) {
      this.imastservices.deleteMstEstClinic(data.clinicCode).subscribe
        ((response) => {
          this.listClinicMast();
          this.toastr.success('Selected Clinic deleted successfully '+ data.clinicCode , 'Deleted !');
          //alert('The Clinic : ' + data.clinicCode + ' deleted successfully');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  };

  editItem(data) {
    debugger;
    this.addNewClinic=true;
    this.modelItem.clinicCode = data.clinicCode;
    this.modelItem.clinicName = data.clinicName;
    this.modelItem.activeYn = data.activeYn;
    this.modelItem.apptClinicYn = data.apptClinicYn;
    this.modelItem.deptCode = data.deptCode;
    // this.modelItem.deptCode = data.map(item => item.deptCode == data.deptCode)
    this.modelItem.afiVersion = data.afiVersion;
  };

  clearModel() {
    this.modelItem = new MstEstClinics();
    this.modelItemExt = new MstEstClinicsExt();
    this.addNewClinic=false;
  };

  newClinicMast(){
    this.addNewClinic = true;
  }
  refresh() {
    this.retrieve();
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printSectionDisplay').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
       <html>
           <head>
               <title></title>
               <style>
  
               #counter {
                font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }
            
            #counter td, #counter th {
                border: 1px solid #ddd;
                padding: 8px;
            }
             #counter th {
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                color: #7a4c41;
            }
  
            
               </style>
           </head>
           <body onload="window.print();window.close()">
  
           <h1><center>Display Master Details</center></h1>
           
           ${printContents}
          
           </body>
       </html>`
    );
    popupWin.document.close();

    //window.print();
  };

  tableToExcel(table, name) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }



  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("deptMast");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("TR");
      for (i = 1; i < (rows.length - 1); i++) {

        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

}


