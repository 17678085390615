export class BillTranDtl {
    itemDesc: string;
    runId: number;
    billId: number;
    lineNo: number;
    itemType: number;
    itemId: number;
    itemQty: number;
    itemUom: number;
    unitPrice: number;
    coPayAmt: number;
    packageItemQty: number;
    deductionAmt: number;
    discountAmt: number;
    tranSource: number;
    sourceRunId: number;
    prevBillRunId: number;
    voucherRunId: number;
    estCode: number;
    afiVersion: number;
    netAmt: number;

    stockId: number;
    taxAmt: number;
    taxPerc: number;
    public constructor() { }
}
