export class ClcAllergyTran{
 invalidatedOn: Date;
 createdDt: Date;
 lastModifiedDt: Date;
 reaction: string;
 comments: string;
 status: string;
 allergenName: string;
 invalidatedVisitId: number;
 visitId: number;
 estCode: number;
 afiVersion: number;
 allergyTypeCode: number;
 allergyMastCode: number;
 yearFrom: number;
 patAllergyId: number;
 createdBy: number;
 public constructor() {}
}
	
  
