import { Component, OnInit, ElementRef, ViewChild,Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

import { ICAREServices } from '../services/Icare.Services';
import { ErpServices } from 'src/app/services/index';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';
import { ClcCertificates } from '../resources/ClcCertificates.resource';

declare var $;

@Component({
  selector: 'app-his-clinical-certificates',
  templateUrl: './app-his-clinical-certificates.component.html',
  styleUrls: ['./app-his-clinical-certificates.component.css']
})
export class ClinicalCertificateComponent implements OnInit {

  @Input()
  patColl: number;

  @Input()
  patVisitId: any;

  retColItem:any=[];
  selectedRow: Number;
  
  listCertTypeMast:any;
  listPatientCertColl:any;
  createdByName:String;
  certTypeDesc:any=[];
  selectedCertRow:any;
  public visitItem: any=[];
  public userColl=this._service.persInfo;
  public estInfo=this._service.estInfo;
  public patientNo:number;
  private patientColl: any; 
  public submitted:boolean=false;
  public btnDisableEditCert:boolean=false;
  public btnEditCertRow:boolean=false;
  public btnAprroveCertRow:boolean=false;
  public btnCancelCertRow:boolean=false;
  daysCount:any;
  
  searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();
  certificateModel: ClcCertificates =new ClcCertificates();
   //today's date
  todayDate:Date = new Date();

  vDateOfBirth: any;
  dateOfBirth: any;
  noOfDays: any;
  currentYear: any;
  birthYear: any;
  tempValidToDate:any;
  d1: any;

  @ViewChild('patientSearchModal', { static: true }) patientSearchModal: ElementRef;
  @ViewChild('listVisitViewModal', { static: true }) listVisitViewModal: ElementRef;
  @ViewChild('cancelCertificateModal', { static: true }) cancelCertificateModal: ElementRef;
  @ViewChild('modalPrintCertificate', { static: true }) modalPrintCertificate: ElementRef;
  
  constructor(private icareservices: ICAREServices,private _service: ErpServices, public datepipe: DatePipe,private toastr: ToastrService) { }

  ngOnInit() {
    this.retrieve();
  }

  ngOnChanges() {
    if (this.patColl) {
       this.searchVisit();     
    }
}

 //sexCode

 retrieve() {
    this.icareservices.listClcCertTypeMast().subscribe(response => {
      this.listCertTypeMast = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
  });
    this.btnEnable();
  }

  searchVisit() {
    if (this.patColl) {     
      this.setPatientColl(this.patColl); 
      this.visitItem = this.patColl;  
      this.listPatientCertificates();   
    }
  }

  /* on button click open patientSearchListModal */
  patientSearchListModal() {
      $(this.patientSearchModal.nativeElement).modal('show');
  }

  /* event patient search list binding from visit search modal */
  eventPatientSearch(data) {
     this.setPatientColl(data);
    $(this.patientSearchModal.nativeElement).modal('hide');
  }

  setPatientColl(data){
    this.patientNo=data.patientNo;
    this.patientColl = data; 
    this.visitItem =[];  
    this.certificateModel=new ClcCertificates();

    if(this.patientColl.sexCode=='M'){  
      let temp=this.listCertTypeMast.filter(item => item.typeCode !== 4);   
       return this.listCertTypeMast = temp;
    }else{
      this.retrieve();
    }

    this.listPatientCertificates();
  }

  /* on button click open modal search patient visit list*/
  getPatientVisitList() {
    if (this.patientColl) {
      this.btnEnable();
      if (this.certificateModel.certTypeCode) {
        this.submitted = true;
        $(this.listVisitViewModal.nativeElement).modal('show');
        this.searchModel.patientNo = this.patientColl.patientNo;
        this.icareservices.getPatientViewColl(this.searchModel.patientNo)
          .subscribe((response) => {
            this.retColItem = response;
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      } else {
        this.btnEnable();
        //swal("Oops!", 'Please Select Certificate Type !', 'warning');
        this.toastr.warning('Please Select Certificate Type !','Alert !');
      }
    }
    else {
      this.btnEnable();
      //swal("Oops!", 'Please Select Patient !', 'warning');
      this.toastr.warning('Please Select Certificate Type !','Alert !');
    }
  }

  /* selectPatVisit(visitColl) {
    this.selectedRow = visitColl;
  } */

  selectedPatVisit(visitColl) {
    if (visitColl) {
      this.listPatientCertificates();
      this.visitItem = visitColl;
      this.submitted = false;
      $(this.listVisitViewModal.nativeElement).modal('hide');
    }
  }

  listPatientCertificates(){
   if (this.patientColl.patientNo) {
      this.icareservices.listPatientCertificates(this.patientColl.patientId)
        .subscribe((response) => {
          this.listPatientCertColl=response;
        });
    }
  }

  createCertificate() {
    if (this.patientColl.patientNo) {
      if(this.visitItem.visitId && this.certificateModel.certTypeCode){
        this.certificateModel.visitId=this.visitItem.visitId;
        this.certificateModel.createdBy=this.userColl.persCode;
        this.certificateModel.createdTime=new Date();
        this.certificateModel.noOfDays=this.daysCount;
        this.certificateModel.validTo= new Date(this.dateFormat(this.certificateModel.validTo));
        this.icareservices.createClcCertificates(this.certificateModel)
          .subscribe((response) => {
            if (response) {
              this.listPatientCertificates();
              this.SelectEditCertificate(response);
              this.toastr.success('Certificate Created !','Save !');
              //swal("SAVE", 'Certificate Created ', 'success');           
            }
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }else{
        this.toastr.warning('Please Select Visit and Certificate Type !','Alert !');
        //swal("Oops!", 'Please Select Visit and Certificate Type', 'warning');         
      }
    }
    else {
      this.toastr.warning('Please Select Patient !','Alert !');
      //swal("Oops!", 'Please Select Patient !', 'warning');
    }
  } 

  getVisitView(visitId) {
    this.icareservices.getClcVisitView(visitId)
      .subscribe((response) => {
        this.visitItem = response;
      });
}

getClcCertContent(certId){
  this.icareservices.getClcCertContent(certId)
      .subscribe((response) => {
        document.getElementById("htmldata").innerHTML = response.htmldata;​
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
    }); 
}

btn_dblClick_SelectPatVisit(certItem) {
    if(certItem.certStatus==null){
      this.selectedRow=certItem;     
      this.SelectEditCertificate(certItem);
    }   
    if (certItem.certStatus == 'A') { 
      this.toastr.warning('Already Approved !','Alert !');   
      //swal("Oops!", 'Already Approved !', 'warning');
    }
    if (certItem.certStatus == 'C') {
      this.toastr.warning('Already Cancelled !','Alert !');
      //swal("Oops!", 'Already Cancelled!', 'warning');
    }
  }

  _btnClickEditCertificate(certItem) {
    if(certItem.certStatus==null){
      this.selectedRow=certItem;
      this.btnEnable();
      this.SelectEditCertificate(certItem);
    }   
    if (certItem.certStatus == 'A') {
      this.SelectEditCertificate(certItem);
      this.btnDisable(); 
      this.toastr.warning('Already Approved Unable To Edit !','Alert !');   
      //swal("Oops!", 'Already Approved Unable To Edit!', 'warning');
    }
    if (certItem.certStatus == 'C') {
      this.toastr.warning('Already Cancelled !','Alert !');
      //swal("Oops!", 'Already Cancelled!', 'warning');
    }
  }

  SelectEditCertificate(certItem){

      this.certificateModel.visitId = certItem.visitId;
      this.certificateModel.certStatus = certItem.certStatus;
      this.getVisitView(certItem.visitId);
      this.getClcCertContent(certItem.certId);
      this.certTypeDesc = certItem.typeDesc;
      this.certificateModel.certId = certItem.certId;
      this.createdByName = certItem.createdByName;
      this.certificateModel.createdBy = certItem.createdBy;
      this.certificateModel.afiVersion = certItem.afiVersion;
      this.certificateModel.certTypeCode = certItem.certTypeCode;
      this.certificateModel.validFrom = new Date(certItem.validFrom);
      this.certificateModel.validTo = new Date(certItem.validTo);
      this.certificateModel.noOfDays = certItem.noOfDays;
      this.certificateModel.remarks = certItem.remarks;
      this.certificateModel.jobDetails = certItem.jobDetails;
      this.certificateModel.accompanyName = certItem.accompanyName;
      this.certificateModel.createdTime = new Date(certItem.createdTime);
  }

  editCertificate() {
    if (this.patientColl.patientNo) {
      if (this.certificateModel.createdBy == this.userColl.persCode) {
        this.certificateModel.visitId = this.visitItem.visitId;
        this.certificateModel.createdBy = this.userColl.persCode;
        this.certificateModel.createdTime = new Date();
        this.certificateModel.noOfDays=this.daysCount;
        this.certificateModel.validTo= new Date(this.tempValidToDate);
        this.icareservices.editClcCertificates(this.certificateModel)
          .subscribe((response) => {
            if (response) {
              this.SelectEditCertificate(response);
              this.listPatientCertificates();
              //swal("Edit", 'Certificate Updated Successfully ', 'success');
              this.toastr.success('Certificate Updated Successfully !','Edit !');
              this.certificateModel=new ClcCertificates;
            }
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.toastr.warning('Unable To Edit,Please Contact Admin !','Alert !');
        //swal("Oops!", 'Unable To Edit,Please Contact Admin !', 'warning');
      }
    }
    else {
      this.toastr.warning('Please Select Patient!','Alert !');
      //swal("Oops!", 'Please Select Patient !', 'warning');
    }
  }

  approveCertificate(approveCertItem){
    if (approveCertItem.certStatus == null) {
      this.SelectEditCertificate(approveCertItem);
      swal({
        title: "Are you sure?",
        text: "You want to approve this certificate !",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, approve it!"

      }).then((result) => {
        if (result.value) {
          if (this.certificateModel.certTypeCode && this.certificateModel.validFrom && this.certificateModel.validTo && this.certificateModel.noOfDays && this.certificateModel.remarks) {
            this.icareservices.approveClcCertificate(approveCertItem.certId)
              .subscribe((response) => {
                this.listPatientCertificates();
                this.SelectEditCertificate(response);
                this.btnDisable();
              }, err => {
                this.toastr.warning(err.split(":")[3], 'Alert !');
            });
          }
          else {
            this.toastr.warning('Certificate Type OR Valid From OR Valid To OR Days OR Medical Condition is Missing !','Alert !');
            //swal("Oops!", 'Certificate Type OR Valid From OR Valid To OR Days OR Medical Condition is Missing !', 'warning');
          }

        }
      });
    }
    if (approveCertItem.certStatus == 'A') {
      this.toastr.warning('Already Approved !','Alert !');
      //swal("Oops!", 'Already Approved !', 'warning');
    }
    if (approveCertItem.certStatus == 'C') {
      this.toastr.warning('Cancel Certificate cannot approve !','Alert !');
      //swal("Oops!", 'Cancel Certificate cannot approve !', 'warning');
    }
  }

  cancelCertModal(cancelCertItem) {
    //this.selectedCertRow = cancelCertItem;
   
    if (cancelCertItem.certStatus == null) {
      this.SelectEditCertificate(cancelCertItem)
      this.btnEnable();
      this.submitted = true;
      $(this.cancelCertificateModal.nativeElement).modal('show');
    
    }
    if (cancelCertItem.certStatus == 'A') {
      this.SelectEditCertificate(cancelCertItem)
      this.btnDisable();
      //swal("Oops!", 'Already Approved Unable To Edit !', 'warning');
      this.toastr.warning('Already Approved Unable To Edit !','Alert !');
    }
    if (cancelCertItem.certStatus == 'C') {
      //swal("Oops!", 'Already Cancelled !', 'warning');
      this.toastr.warning('Already Cancelled  !','Alert !');
    }
  }

  cancelCertificate() {
    if (this.patientColl.patientNo) {
       /*  this.certificateModel.visitId = this.selectedCertRow.visitId;
        this.certificateModel.certId = this.selectedCertRow.certId;
        this.certificateModel.afiVersion = this.selectedCertRow.afiVersion; */
        this.certificateModel.createdBy = this.userColl.persCode;
        this.certificateModel.createdTime = new Date();
        this.icareservices.cancelClcCertificate(this.certificateModel)
          .subscribe((response) => {
            if (response) {
              this.btnDisable();
              this.SelectEditCertificate(response);              
              this.listPatientCertificates();
              //swal("Cancel", 'Certificate Cancel Successfully', 'warning');
              this.toastr.info('Certificate Cancel Successfully !','Cancel !');
              $(this.cancelCertificateModal.nativeElement).modal('hide');
              this.certificateModel=new ClcCertificates;
            }
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      //swal("Oops!", 'Please Select Patient !', 'warning');
      this.toastr.warning('Please Select Patient !','Alert !');
    }
  }

  clearCertificateModel(){
    this.certificateModel=new ClcCertificates();
    this.createdByName=null; 
    this.visitItem=[];
  }

  _printCertificateModal(){
    $(this.modalPrintCertificate.nativeElement).modal('show');
  }

  printCertificateModal(): void {
   if(this.certificateModel.certStatus=='A'){
    let printContents, popupWin;
    printContents = document.getElementById('printClinicalCertificate').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
              <head>
                  <title></title>
                  <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
              <style>
             
               modal-body{
               
                font-size: 11px;
               }
              .text_space{
                white-space: pre;
               }
               .html_content{
                border: 1px solid coral;
                padding-top: 15px;
                border-radius: 5px;
                margin-top: -15px;
                height: 200mm;
               }
               }
                @media print{
                  .panel_border{
                    border: 1px solid #ee1c25;
                } 
                }
              </style>
                  </head>
              <body onload="window.print();window.close()">
      
              ${printContents}
              
              </body>
          </html>`
    );
    popupWin.document.close();
   }
   if(this.certificateModel.certStatus=='C'){
    //swal("Oops!", 'Cancelled Certificate!', 'warning');
    this.toastr.warning('Cancelled Certificate !','Alert !');
   }
   if(this.certificateModel.certStatus==null){
    //swal("Oops!", 'Please Approve the Certificate First!', 'warning');
    this.toastr.warning('Please Approve the Certificate First !','Alert !');
   }
 
  };

  btnDisable(){
     this.btnDisableEditCert=true;
    // this.btnEditCertRow=true;
     //this.btnAprroveCertRow=true;
     //this.btnCancelCertRow=true;
  }

  btnEnable(){
    this.btnDisableEditCert=false;
   // this.btnEditCertRow=false;
    //this.btnAprroveCertRow=false;
    //this.btnCancelCertRow=false;
 }

 dateFormat(param) {
  return this.datepipe.transform(param, 'yyyy-MM-dd');
}

 bindDate() {
  this.tempValidToDate;
  this.noOfDays =+ this.certificateModel.noOfDays;
  var d = new Date();
  this.certificateModel.validFrom =new Date(this.datepipe.transform(d));
  this.tempValidToDate=this.addDays(this.noOfDays);//new Date(d.getFullYear(),"0"+(d.getMonth(),d.setDate(d.getDate() + this.noOfDays)+this.noOfDays);//new Date(d.getDate()+this.noOfDays);
  this.certificateModel.validTo=this.tempValidToDate;
};

  addDays(n){
    var t = new Date();
    t.setDate(t.getDate() + n); 
    var month = "0"+(t.getMonth()+1);
    var date = "0"+(t.getDate()-1);
    //month = month.slice(-2);
    date = date.slice(-2);
    var date = date +"/"+month +"/"+t.getFullYear();
    return (date);
  }
                     

  calculateDiff(StartDate, EndDate) {
    
    if (StartDate != null && EndDate != null && this.certificateModel.noOfDays==null) {

      const oneDay = 1000 * 60 * 60 * 24;

      // A day in UTC always lasts 24 hours (unlike in other time formats)
      const start = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());
      const end = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    
       this.daysCount=((end - start) / oneDay)+1;
      // so it's safe to divide by 24 hours
      //return this.daysCount;
      this.certificateModel.noOfDays=this.daysCount;
    }
    //return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
    
  }

  onChangeCertType(event){
    if(this.patientColl.sexCode=='M' && event.target.value.split(":")[1].trim()==4){  
      let temp=this.listCertTypeMast.filter(item => item.typeCode !== 4);   
       return this.listCertTypeMast = temp;
    }else{
      this.retrieve();
    }
  }

  getAge(dateString) {

    var birthdate = new Date(dateString).getTime();
    var now = new Date().getTime();
    // now find the difference between now and the birthdate
    var n = (now - birthdate)/1000;
  
    if (n < 604800) { // less than a week
      var day_n = Math.floor(n/86400);
      return day_n + ' D' + (day_n > 1 ? 's' : '');
    } else if (n < 2629743) {  // less than a month
      var week_n = Math.floor(n/604800);
      return week_n + ' W' + (week_n > 1 ? 's' : '');
    } else if (n < 63113852) { // less than 24 months
      var month_n = Math.floor(n/2629743);
      return month_n + ' M' + (month_n > 1 ? 's' : '');
    } else { 
      var year_n = Math.floor(n/31556926);
      return year_n + ' Y' + (year_n > 1 ? 's' : '');
    }
  }
}
