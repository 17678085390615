export class ClcGenAppointment{
 appDate: Date;
 createTime: Date;
 appRemarks: string;
 appId: number;
 appFromTime: number;
 appToTime: number;
 appClinic: number;
 patientId: number;
 estCode: number;
 createdBy: number;
 afiVersion: number;
 public constructor() {}
}
	
  
