import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler } from '@angular/common/http';
import * as AppUtils from '../common/app.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';
import { _PersInfo } from '../resources/_PersInfo.resource';
import { _PersProcess } from '../resources/_PersProcess.resource';
import { MstEstablishments } from '../HIMS/resources/MstEstablishments.resource';

@Injectable()
export class ErpServices {

  public persInfo:_PersInfo;
  public estInfo:MstEstablishments;
  public processInfo:any;
  public Process:boolean=false;
  public currentToken: string;
  public billableItems: any;
  public icdMast: any;
  public listMstParams: any;

  constructor(private http: HttpClient) { }

  getMyEstablishment(){
    return this.http.get(AppUtils.LIST_GET_ESTABLISMENT ).pipe(map(res => {
      return res;
    }));
  }

  getMenu(){
    return this.http.get(AppUtils.MENU_URL ).pipe(map(res => {
      return res;
    }));
  }

  getSessionPerson(){
    return this.http.get(AppUtils.SESSION_PERSON ).pipe(map(res => {
      return res;
    }));
  }

  getSessionProcesses(){
    return this.http.get(AppUtils.LIST_SESSION_PROCESSES ).pipe(map(res => {
      return res;
    }));
  }

  listUsers(){
    return this.http.get(AppUtils.LIST_USERS).pipe(map(res => {
      return res;
    }));
  }

  getToken(){
    return localStorage.getItem(AppUtils.ACCESS_TOKEN);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  doLogin(body) {  
	return this.http.post(AppUtils.AUTHENTICATION_URL, body, { headers: AppUtils.contentHeaders })
	.map((response: Response) => response.json())
	.catch(error => {
		console.log(error);
	return Observable.throw(error.json());
	});
  }


  _checkProcessExist(response):any {

  
  if (this.processInfo == undefined) {
    this.processInfo = response;
  }
  else if (this.processInfo != undefined) {

    for (let m of this.processInfo) {
      if (m.processCode.split('_')[1] == response) {
        this.Process = true;
      }
    }

  }
  //console.log(this.Process)
  return this.Process;
}
  
       
}
