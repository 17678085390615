import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler } from '@angular/common/http';
import * as AppUtils from 'src/app/common/app.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';

import { MstEstablishments } from '../resources/MstEstablishments.resource'
import { MstMaritalStatusMast } from '../resources/MstMaritalStatusMast.resource'
import { MstDepartmentMast } from '../resources/MstDepartmentMast.resource'
import { MstCountryMast } from '../resources/MstCountryMast.resource'
import { MstSexMast } from '../resources/MstSexMast.resource'
import { MstRelationshipMast } from '../resources/MstRelationshipMast.resource'
import { MstSalutationMast } from '../resources/MstSalutationMast.resource'
import { MstCurrencyMast } from '../resources/MstCurrencyMast.resource'
import { MstEstClinics } from '../resources/MstEstClinics.resource';

import { ReqEntityMast } from '../resources/app-resource/ReqEntityMast.resource';
import { ReqEntityTags } from '../resources/app-resource/ReqEntityTags.resource';
import { ReqManageScheduleModel } from '../resources/app-resource/ReqManageScheduleModel.resource';
import { ReqManageSlotModel } from '../resources/app-resource/ReqManageSlotModel.resource';
import { ReqSlotMast } from '../resources/app-resource/ReqSlotMast.resource';
import { MedPharmacyItemMast } from '../resources/MedPharmacyItemMast.resource';
 
@Injectable()
 
 export class IMASTServices  {
	
	private AFFINITY_URL_BASE= AppUtils.BACKEND_API_URL ;

	public headerData;
	constructor(private http: HttpClient) { }

	private handleError(error: any): Promise<any> {
	 console.error('Could not complete requested service operation', error);
	 return Promise.reject(error.message || error);
	}
  
  public listMstCountryMast():Observable<MstCountryMast>{
    return this.http.get<MstCountryMast>(AppUtils.LIST_COUNTRY_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public listMstMaritalStatusMast():Observable<MstMaritalStatusMast>{
    return this.http.get<MstMaritalStatusMast>(AppUtils.LIST_MARITAL_STATUS_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public listMstSalutationMast():Observable<MstSalutationMast>{
    return this.http.get<MstSalutationMast>(AppUtils.LIST_SALUTATION_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public listMstSexMast():Observable<MstSexMast>{
    return this.http.get<MstSexMast>(AppUtils.LIST_SEX_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public listMstRelationshipMast():Observable<MstRelationshipMast>{
    return this.http.get<MstRelationshipMast>(AppUtils.LIST_RELATIONSHIP_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public getMyEstablishment():Observable<MstEstablishments>{
    return this.http.get<MstEstablishments>(AppUtils.LIST_GET_ESTABLISMENT ).pipe(map(res => {
      return res;
    }));
  }

  public createMstEstClinic(mstEstClinics:MstEstClinics):Observable<any>{
    return this.http.post<any>(AppUtils.CREATE_CLINIC ,mstEstClinics).pipe(map(res => {
      return res;
    }));
  }
  public listClinics():Observable<MstEstClinics>{
    return this.http.get<MstEstClinics>(AppUtils.LIST_CLINIC_MAST).pipe(map(res => {
      return res;
    }));
  }
  
  public editMstEstClinic(mstEstClinics:MstEstClinics):Observable<any>{
    return this.http.post<any>(AppUtils.EDIT_CLINIC_MAST,mstEstClinics).pipe(map(res => {
      return res;
    }));
  }
  public deleteMstEstClinic(clinicCode : number):Observable<any>{
    return this.http.delete<any>(AppUtils.DELETE_CLINIC+`${clinicCode}`).pipe(map(res => {
      return res;
    }));
  }
  public getMstEstClinic(clinicCode: number) :Observable<any> {
    return this.http.get<any>(AppUtils.GET_CLINIC+`${clinicCode}`).pipe(map(res => {
      return res;
    }));
  }
  public listClinicalDepartments():Observable<any>{
    return this.http.get<any>(AppUtils.LIST_CLINICAL_DEPT_MAST ).pipe(map(res => {
      return res;
    }));
  }

  public listMstDepartmentMast(mstdepartmentmast : MstDepartmentMast):Observable<any>{
    return this.http.post<any>(AppUtils.LIST_DEPT_MAST,mstdepartmentmast).pipe(map(res => {
      return res;
    }));
  }
  public createMstDepartmentMast(mstdepartmentmast : MstDepartmentMast):Observable<any>{
    return this.http.post<any>(AppUtils.CREATE_DEPT_MAST,mstdepartmentmast).pipe(map(res => {
      return res;
    }));
  }
  public editMstDepartmentMast(mstdepartmentmast : MstDepartmentMast):Observable<any>{
    return this.http.post<any>(AppUtils.EDIT_DEPT_MAST,mstdepartmentmast).pipe(map(res => {
      return res;
    }));
  }
  public deleteMstDepartmentMast(deptCode : number):Observable<any>{
    return this.http.delete<any>(AppUtils.DELETE_DEPT+`${deptCode}`).pipe(map(res => {
      return res;
    }));
  }

  public listPersClinicView():Observable<any>{
    return this.http.get<any>(AppUtils.LIST_PERS_CLINIC_VIEW ).pipe(map(res => {
      return res;
    }));
  }

  public listReqEntityMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_ENTITY_MAST)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

   //listReqItemMast
   public listReqItemMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_ITEM_MAST)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

   //createReqEntityMast : createReqEntityMast
   public createReqEntityMast(reqEntityMast: ReqEntityMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_REQ_ENTITY_MAST, reqEntityMast)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //createReqEntityMast : createReqEntityMast
  public editReqEntityMast(reqEntityMast: ReqEntityMast): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_REQ_ENTITY_MAST, reqEntityMast)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

   //listReqEntityTags
   public listReqEntityTags(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_ENTITY_TAGS)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //listReqEntityTags
  public listReqTags(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_TAGS)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }
  
  //getListEntitySchedule 
  getListEntitySchedule(entityCode: number): Observable<any> {
    return this.http.get(AppUtils.GET_LIST_ENTITY_SCHEDULE + `${entityCode}`).pipe(map(res => {
      return res;
    }));
  }

  //ReqManageScheduleModel : ReqManageScheduleModel
  public createEntitySchedule(reqManageScheduleModel: ReqManageScheduleModel): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_ENTITY_SCHEDULE, reqManageScheduleModel)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //removeEntitySchedule 
  removeEntitySchedule(reqManageScheduleModel: ReqManageScheduleModel): Observable<any> {
    return this.http.post(AppUtils.REMOVE_ENTITY_SCHEDULE, reqManageScheduleModel).pipe(map(res => {
      return res;
    }));
  }

  //getReqEntityTags 
  getReqEntityTags(tagId: number): Observable<any> {
    return this.http.get(AppUtils.GET_REQ_ENTITY_TAGS + `${tagId}`).pipe(map(res => {
      return res;
    }));
  }

  //getReqEntityMast 
  getReqEntityMast(entityCode: number): Observable<any> {
    return this.http.get(AppUtils.GET_REQ_ENTITY_MAST + `${entityCode}`).pipe(map(res => {
      return res;
    }));
  }

   //getReqEntityMast 
   createReqEntityTags(reqEntityTags: ReqEntityTags): Observable<any> {
    return this.http.post(AppUtils.CREATE_REQ_ENTITY_TAGS ,reqEntityTags).pipe(map(res => {
      return res;
    }));
  }

    //getReqEntityMast 
    editReqEntityTags(reqEntityTags: ReqEntityTags): Observable<any> {
      return this.http.post(AppUtils.EDIT_REQ_ENTITY_TAGS ,reqEntityTags).pipe(map(res => {
        return res;
      }));
    }
  
    //deleteReqEntityTags 
    deleteReqEntityTags(tagId: number): Observable<any> {
      return this.http.delete(AppUtils.DELETE_REQ_ENTITY_TAGS + `${tagId}`).pipe(map(res => {
        return res;
      }));
    }

  //ReqManageSlotModel : ReqManageSlotModel
  public createScheduleSlots(reqManageSlotModel: ReqManageSlotModel): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_SCHEDULE_SLOTS, reqManageSlotModel)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

   //listReqSlotMast
   public listReqSlotMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_SLOT_MAST)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //listReqSlotDtl
  public listReqSlotDtl(slotMastId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_SLOT_DTL + `${slotMastId}`)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //createReqEntityMast : createReqEntityMast
  public createReqSlotMast(reqSlotMast: ReqSlotMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_REQ_SLOT_MAST, reqSlotMast)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //deleteReqSlotDtl 
  deleteReqSlotDtl(slotDtlId: number): Observable<any> {
    return this.http.delete(AppUtils.DELETE_REQ_SLOT_DTL + `${slotDtlId}`).pipe(map(res => {
      return res;
    }));
  }

    //listMstCityMast
    public listMstCityMast(): Observable<any> {
      return this.http.get<any>(AppUtils.LIST_MST_CITY_MAST)
        .pipe(map(res => {
          return res;
        }));//.catch(this.handleError);
    }

  //listMstVillageMast
  public listMstVillageMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_MST_VILLAGE_MAST)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //listMstApplicationParams
  public listMstApplicationParams(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_MST_APPLICATION_PARAMS)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }
  
  //isSingleClinicEstablishment
  public isSingleClinicEstablishment(): Observable<any> {
    return this.http.get<any>(AppUtils.Is_SINGLE_CLINIC_ESTABLISHMENT)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }

  //medPharmacyItemMast : MedPharmacyItemMast
  public saveMedicine(medPharmacyItemMast: MedPharmacyItemMast): Observable<any> {
    return this.http.post<any>(AppUtils.SAVE_MEDICINE, medPharmacyItemMast)
      .pipe(map(res => {
        return res;
      }));//.catch(this.handleError);
  }
/* public deleteMstDepartmentMast( deptCode : number) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.delete(`${this.AFFINITY_URL_BASE}deleteMstDepartmentMast?deptCode=${deptCode} ` ,  {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  
public getMstDepartmentMast( deptCode : number) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.get(`${this.AFFINITY_URL_BASE}getMstDepartmentMast?deptCode=${deptCode} ` ,  {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  
public listMstCurrencyMast( ) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.get(`${this.AFFINITY_URL_BASE}listMstCurrencyMast` ,  {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  

  
public listMstCountryMast( ) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.get(`${this.AFFINITY_URL_BASE}listMstCountryMast` ,  {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  

public createMstDepartmentMast( mstdepartmentmast : MstDepartmentMast) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.post(`${this.AFFINITY_URL_BASE}createMstDepartmentMast` , mstdepartmentmast, {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  

public editMstDepartmentMast( mstdepartmentmast : MstDepartmentMast) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.post(`${this.AFFINITY_URL_BASE}editMstDepartmentMast` , mstdepartmentmast, {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
  

public listMstDepartmentMast( mstdepartmentmast : MstDepartmentMast) : Promise<any> {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));
    
    return this.http.post(`${this.AFFINITY_URL_BASE}listMstDepartmentMast` , mstdepartmentmast, {headers: this.headerData} )
    .toPromise()
    .then (response => response.json())
    .catch(this.handleError);
  }      
 */  

 
}

  
