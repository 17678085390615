export class ClcServiceOrders{
 orderTime: Date;
 orderRemarks: string;
 entryMode: string;
 orderId: number;
 visitId: number;
 itemId: number;
 billRunId: number;
 estCode: number;
 orderBy: number;
 serviceType: number;
 orderStatus: number;
 itemQty: number;
 currTrfId: number;
 workPriority: number;
 operationId: number;
 enteredBy: number;
 prevOrderId: number;
 afiVersion: number;
 public constructor() {}
}
	
  
