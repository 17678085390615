import {ClcPatientPolicies} from './ClcPatientPolicies.resource';
import {ClcPatientPackages} from './ClcPatientPackages.resource';
import {ClcPatientNextkin} from './ClcPatientNextkin.resource';
export class ClcPatientColl{
 birthDate: Date;
 identityNo: string;
 printName: string;
 sexCode: string;
 contactDetail: string;
 cityName : string;
 patientType: string;
 firstName: string;
 secondName: string;
 thirdName: string;
 fourthName: string;
 mobileNo: string;
 phoneHome: string;
 patientNo: string;
 patientPhoto: string;
 patientId: number;
 nationalityCode: number;
 maritalstatusCode: number;
 registerStatus: number;
 confidentialityLevel: number;
 estCode: number;
 salutationCode: number;
 motherPatientId: number;
 lastFileTranId: number;
 mergedToPatientId: number;
 afiVersion: number;
 villageName:string;
 clcPatientPolicies: ClcPatientPolicies[];
 clcPatientPackages: ClcPatientPackages[];
 clcPatientNextkin: ClcPatientNextkin[];
 public constructor() {}
}
	
  
