export class ReqEntityMast{
 entityDescNl: string;
 entityDesc: string;
 entityEstRefCode: string;
 entityNameNl: string;
 entityName: string;
 entityUrl:string;
 serviceType: number;
 entityStatus: number;
 afiVersion: number;
 estCode: number;
 entityCode: number;
 itemId: number;
 public constructor() {}
}
	















