import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';

import { OrderReportComponent } from 'src/app/HIMS/app-his-order-report/app-his-order-report.component';
import { PatientVisitSummaryComponent } from 'src/app/HIMS/app-his-visit-summary/app-his-visit-summary.component';


declare var $;

@Component({
  selector: 'app-his-tabs',
  templateUrl: './app-his-tabs.component.html',
  styleUrls: ['./app-his-tabs.component.css']

})

export class CommonTabsComponent implements OnInit {
  @Input()
  public visitId: number;
  @Input()
  public patientId: number;
  
  @Input()
  public editYN:string;
  //public printVisitId:number;
  public submitted: boolean = false;
  public btnDisablVisitSummary: boolean = false;
  public btnDisablPrescription:boolean=false;

  @ViewChild('ModalOrderPrescription', { static: true }) ModalOrderPrescription: ElementRef;
  @ViewChild('modalOpNotes', { static: true }) modalOpNotes: ElementRef;
  @ViewChild(OrderReportComponent, { static: true })
  private child: OrderReportComponent

  @ViewChild(PatientVisitSummaryComponent, { static: true })
  private visitSummary: PatientVisitSummaryComponent

  constructor() { }

  ngOnInit() {
    this.patientId = this.patientId;
  }

  ngOnChanges() {
   //console.log(this.editYN)
  }

  modalPrescription() {
    this.btnDisablPrescription=true;
    this.visitId = this.visitId;
    this.submitted = true;
    $(this.ModalOrderPrescription.nativeElement).modal('show');
    this.child.ngOnInit();
    this.btnDisablPrescription=false;
  }

  modalPrintOpNotes() {
    this.btnDisablVisitSummary = true;
    this.visitId = this.visitId;
    $(this.modalOpNotes.nativeElement).modal('show');
    this.visitSummary.retrieve();
    this.btnDisablVisitSummary = false;
  }

  printPatientVisitSummary(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printVisitSummary').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
           <html>
               <head>
                   <title></title>
                   <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
               <style>
                  img {
                    position: initial;
                    top: 0%;
                    left: 0%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(3%, 0%);
                    padding-top: 0px;
                    margin-bottom: 20px;
                    height: 100px;
                    margin-left: 20%;
                }
                body{
                  font-family: monospace; 
               }
               .panel {
                margin-bottom: 5px!important;
                }
                .panel-body {
                  padding: 5px;
              }
               </style>
                   </head>
               <body onload="window.print();window.close()">
      
               ${printContents}
              
               </body>
           </html>`
    );
    popupWin.document.close();
  };
}
