import {MstRelationshipMast} from './MstRelationshipMast.resource';
export class ClcPatientNextkinView{
 givenName: string;
 familyName: string;
 contactDetail: string;
 phoneNo: string;
 patientId: number;
 relationshipCode: number;
 runId: number;
 nextkinPriority: number;
 estCode: number;
 afiVersion: number;
 mstRelationshipMast: MstRelationshipMast;
 public constructor() {}
}
	
  
