import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild ,Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv' ;
import { ClcBillRcpQueryVO } from 'src/app/HIMS/resources/ClcBillRcpQueryVO .resource';
import { ICAREServices } from '../services/Icare.Services';
import { ErpServices } from 'src/app/services/index';
import { DatePipe } from '@angular/common';
declare var $;

@Component({
  selector: 'app-daily-bill-view',
  templateUrl: './app-daily-bill-view.component.html',
  styleUrls: ['./app-daily-bill-view.component.scss']
})

export class AppDailyBillViewComponent implements OnInit {

  public estInfo = this._service.estInfo;
  billFrom:any;
  listUsers: any;
  listBillHdrsColl
  listBillRecptTranView: any = [];
  billRecptPayment:ClcBillRcpQueryVO[];

  billRcpQueryModel: ClcBillRcpQueryVO = new ClcBillRcpQueryVO();
  @ViewChild('printDailyBillModal', { static: true }) printDailyBillModal: ElementRef;

  constructor(private icareservices: ICAREServices,public datepipe: DatePipe,private toastr: ToastrService,private _service: ErpServices) { }

  ngOnInit() {   
    this.retrive();
  }

  dateFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd');
  }

  private retrive(){
    this._service.listUsers().subscribe(response => {
      this.listUsers = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }


  searchPatientBillList() {
    this.icareservices.listDailyBillRecptPayment(this.billRcpQueryModel)
      .subscribe((response) => {
        this.listBillRecptTranView = response;
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  downloadRecptBillPayment() {

    this.billRecptPayment=[];
    for ( let rec of this.listBillRecptTranView)
    {
    }
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      datePattern: "yyyy-MM-dd HH:mm:ss",
      showLabels: true,
      useBom: true,
      headers: Object.keys(this.listBillRecptTranView)
    };
    new Angular2Csv(this.listBillRecptTranView, 'Bill Report', options);
  }

  get netTranAmount() {
    let total = 0;
    if (this.listBillRecptTranView) {
        for (var i = 0; i < this.listBillRecptTranView.length; i++) {
            if (this.listBillRecptTranView[i].amount) {
                total += this.listBillRecptTranView[i].amount;
            }
        }
    }
    return total;
}

  /* on button click getPatientVisitModal open visit search modal */
  getDailyBillViewModal() {
  
    $(this.printDailyBillModal.nativeElement).modal('show');
}

printDailyBillTran(): void {
  let printContents, popupWin;
  printContents = document.getElementById('billReceptTran').innerHTML;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
        <html>
            <head>
                <title></title>
                <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
            <style>
           
              html,body{
              -webkit-print-color-adjust: exact !important;
                background: transparent; 
                font-size: 12px;
              }
              table.billItem td.price {
                  text-align: right!important;
                }
               
              table {
                  font-size: 11px;
                  border: 2px solid darksalmon !important;
              }
              
            .panel {
              margin-bottom: 5px!important;
              }
              .panel-body {
                padding: 5px;
            }
            .text_space{
              white-space: pre;
             }
              ._td{
                  white-space: pre;
              }
             .tbl{
              white-space: nowrap;
             }
    
             
            </style>
                </head>
            <body onload="window.print();window.close()">
    
        
            ${printContents}
            
            </body>
        </html>`
  );
  popupWin.document.close();
};

}
