import { Component, OnInit, OnChanges, Input,ViewChild,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup,FormControl, FormArray, Validators } from '@angular/forms';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';


import { ClcExamTranColl } from '../resources/ClcExamTranColl.resource';
import {ClcExamTranDetl} from '../resources/ClcExamTranDetl.resource';
import { MedPrescriptionTran } from '../resources/MedPrescriptionTran.resource';
import { ClcServiceOrders } from '../resources/ClcServiceOrders.resource';
import { ClcExamTranCollView } from '../resources/ClcExamTranCollView.resource';
import swal from 'sweetalert2';
import { element } from 'protractor';

declare var $;

@Component({
  selector: 'app-his-examiniation',
  templateUrl: './app-his-examination-bar.component.html',
  styleUrls: ['./app-his-examination-bar.component.css']

})

export class ExaminationComponent implements OnInit {
  @Input()
  public visitId: number;
  @Input()
  public editYN: string;
  htmlContent = '';
  examDate =new Date();
  genericColl: any;
  medicineColl: any;
  courseColl: any;
  listVisitPres: any;
  listserviceItems:any;
  listServiceColl:any;

  public entryBy:any[];
  public buttonDisabled: boolean=false;
  private userColl=this._service.persInfo;
  public examTemplate:any[];
  listTemp: any[];
  listColl: any=[];
  examTranItem: ClcExamTranColl = new ClcExamTranColl();
  clcExamTranCollView:ClcExamTranCollView = new ClcExamTranCollView();
  examTranDetail:ClcExamTranDetl=new ClcExamTranDetl();
  presItem: MedPrescriptionTran = new MedPrescriptionTran();
  editPresItem: MedPrescriptionTran = new MedPrescriptionTran();
  serviceModel:ClcServiceOrders=new ClcServiceOrders();
  editserviceModel:ClcServiceOrders=new ClcServiceOrders();
  AngularEditorConfig:AngularEditorConfig
  selectedItem:any;
  config:any;
  dynamicForm: FormGroup;
  submitted = false;
  examTranVal:any={};
  testhtml;
  
  requestQRForm: FormGroup;
  cloudQR_FormTemplate: any;
  myArray = [];  

  @ViewChild('examTranModal', { static: true }) examTranModal: ElementRef;
  
  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: 'initial',
    sanitize: true,
    toolbarHiddenButtons: [
      ['underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices,private toastr: ToastrService,private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.config=this.angularEditorConfig;
    // this.dynamicForm = this.formBuilder.group({
    //   History: ['', Validators.required],
    //   Problem1: ['', Validators.required],
    //   Problem2: ['', Validators.required],
    //   cheifComplain: ['', Validators.required]   
    // });    
  }


  ngOnChanges() {
    this.buttonDisabled=false;
    this.examTranDetail = new ClcExamTranDetl();
    this.clcExamTranCollView = new ClcExamTranCollView();
    this.listTemp =[];
    this.listColl=[];
    this.getVisitExamTran();
    this.entryBy=[];
    this.listClcExamJsonTemplate();
  }

  listClcExamJsonTemplate() {
    let group = {}
    this.icareservices.listAllClcExamJsonTemplate().subscribe((response) => {
      if (response) {
        this.cloudQR_FormTemplate = response;
        this.cloudQR_FormTemplate.forEach(input_template => {

          if (input_template.reqField == 'Y') {
            group[input_template.colName] = new FormControl(null, Validators.required);
            this.myArray.push(input_template.colName);
          }
          if (input_template.reqField == 'N') {
            group[input_template.colName] = new FormControl('');
            this.myArray.push(input_template.colName);
          }

        })
        this.requestQRForm = new FormGroup(group);
      }

    });
  }

  clearFromControl(){
    this.requestQRForm.reset();
  }

  /* Examination */
  getVisitExamTran() {  
    if (this.visitId) { 
     
      //console.log(this.editYN)

      this.icareservices.listVisitExamTran(this.visitId)
        .subscribe((response) => {
          this.listColl=response;
          if (this.listColl.length>0) {
            this.entryBy =response[0].mstPersonalMast.printName;
            for(let rec of response){
              this.clcExamTranCollView=rec;
              //merging the exam template array and the transaction array into one
              const result = this.examTemplate.map(val => {
                return Object.assign({}, val, this.clcExamTranCollView.clcExamTranDetl.filter(v => v.templateId === val.templateId)[0]);
              });
              for (let combined of result){
                this.listTemp.push(combined);
              }
            }        
          }
          else{
            this.getExamtemplate();
          }
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }else{
      this.getExamtemplate();
    }
  }

  getExamtemplate(){
    this.icareservices.listGenExamTemplate()
    .subscribe((response) => {
      if (response) {
        for (let template of response) {
          this.examTranDetail=template;
          this.examTranDetail.examValue="";
          this.listTemp.push(this.examTranDetail);
          this.examTemplate = this.listTemp;
        }
      }
    }); 
  }
 
  createExamTran() {

    if (this.visitId) {
      this.buttonDisabled=true;
      if (this.listColl.length>0){
        this.examTranItem.examId=this.clcExamTranCollView.examId;    
        this.examTranItem.afiVersion=this.clcExamTranCollView.afiVersion;  
      }
      this.examTranItem.createdBy=this.userColl.persCode;
      this.examTranItem.createdTime=this.examDate;
      this.examTranItem.visitId=this.visitId;
      this.examTranItem.rootTemplateId=1;
      this.examTranItem.clcExamTranDetl = this.listTemp;

      if (this.examTranItem.examId>0){
        this.icareservices.editClcGenExam(this.examTranItem)
        .subscribe((response) => {
          if (response) {
            this.buttonDisabled=false;
            this.toastr.success('Examination Updated successfully :' + this.visitId,'Edited !');
            //swal("Edit", 'Examination Updated successfully :' + this.visitId, 'success');
            this.listTemp =[];
            this.listColl=[];
            this.getVisitExamTran();
          }
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
      }else{
        this.icareservices.createClcGenExam(this.examTranItem)
        .subscribe((response) => {
          if (response) {
            this.buttonDisabled=false;
            this.listTemp =[];
            this.listColl=[];
            this.getVisitExamTran();
            this.toastr.success('Examination Save successfully :' + this.visitId,'Save !');
            //swal("Save", 'Examination Save successfully :' + this.visitId, 'success');
          }
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
      }
    }
    else{
      this.toastr.warning( 'Please select patient first !','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  examininationTranModal(item){
    // this.htmlContent='';
    // this.config=this.angularEditorConfig;
    this.selectedItem=item;
    $(this.examTranModal.nativeElement).modal('show');
  }

  
  createTemplateForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.requestQRForm.invalid) {
      return;
    }
    
     let D = this.parseObjectProperties(this.requestQRForm.getRawValue());
    
    let updateItem =  this.listTemp.findIndex((obj => obj.paraId == this.selectedItem.paraId));
    this.listTemp[updateItem].examValue = this.objToString(this.requestQRForm.value);//JSON.stringify(this.examTranVal);
    // let arr = [];  
    // const result= Object.keys( this.examTranVal).map(function(key){  
    //     arr.push({[key]: this.examTranVal[key]})  
    //     return arr;  
    // }); 
    // const resultArray = Object.keys(this.examTranVal).map(index => {
    //   let person = this.examTranVal[index];
    //   return person;
    // });

    this.requestQRForm.reset();
    $(this.examTranModal.nativeElement).modal('hide');

  }

  parseObjectProperties (obj) {
    for (var k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        this.parseObjectProperties(obj[k])
      } else if (obj.hasOwnProperty(k) && obj[k] !== "") {
       return (k+ ":" +obj[k]);
      }
    }
  }

 
  objToString(object) {
    var str = '';
    for (var k in object) {
      if (object.hasOwnProperty(k) && object[k] !== "" && object[k] !=null) {
        str += `<b>`+ k + `</b>`+' : ' + object[k] + `<br/>`;
      }
    }
    return str;
  }

  bindExamTranDetail(){
    (document.getElementById(this.selectedItem.paraId) as HTMLInputElement).value=this.htmlContent;
    this.config=this.angularEditorConfig;
    $(this.examTranModal.nativeElement).modal('hide');
  }

  get f() { return this.dynamicForm.controls; }

 
}
