export class MstEstClinics{
 clinicName: string;
 clinicShName: string;
 activeYn: string;
 apptClinicYn: string;
 clinicCode: number;
 estCode: number;
 deptCode: number;
 visitTypeCode: number;
 consultationCharge: number;
 categoryCode: number;
 afiVersion: number;
 public constructor() {}
}
	
  
