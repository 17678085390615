import { Component, OnInit,  Input, ElementRef, ViewChild,Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv' ;
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';
import { DatePipe } from '@angular/common';
import { ClcPatientViewExt } from '../resources/ext/ClcPatientViewExt';

declare var $;

@Component({
  selector: 'app-rep-patient-view',
  templateUrl: './app-rep-patient-view.component.html',
  styleUrls: ['./app-rep-patient-view.component.css']

})

export class PatientStatisticsComponent implements OnInit {
 
 //variables for the pagination
 currentPage = 1;
 page: number;

@Input()
 public visitId: number;
 patientReport:any;

 searchItems: any;
 nationColl: any;
 genderColl: any;
 selectedRow: Number;
 public submitted: boolean = false;

  

 @Output() sendDataToParent = new EventEmitter<any>();
 searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();

 patientViewExtModel:ClcPatientViewExt;
 patientViewExtColl:ClcPatientViewExt[];

 @ViewChild('ModalPatientSearch', { static: false }) ModalPatientSearch: ElementRef;
  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices,public datepipe: DatePipe,private toastr: ToastrService) { }

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  private retrieve() {

    this.imastservices.listMstCountryMast().subscribe(response => {
      this.nationColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
   
    this.imastservices.listMstSexMast().subscribe(response => {
      this.genderColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

 bindPatientVisit(patColl) {
    this.selectedRow = patColl;
  } 

  dateFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd');
  }
  
  timeFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd HH:mm');
  }
  
  selectedPatVisit(patColl) {

    this.sendDataToParent.emit(patColl);
  
  }

  onKeyPress(event) {
    this.searchPatientList(this.searchModel);
  }

  searchPatientList(searchModel) {
    this.icareservices.listPatientColl(this.searchModel)
      .subscribe((response) => {
        this.searchItems = response;
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }
  exportPatientList(){
    this.icareservices.listPatientColl(this.searchModel)
    .subscribe((response) => {
        this.patientViewExtColl=[];
      for ( let rec of response)
      {
          this.patientViewExtModel=new ClcPatientViewExt();
          
          this.patientViewExtModel.patientNo=rec.patientNo;
          this.patientViewExtModel.printName=rec.printName;
          this.patientViewExtModel.birthDate=this.dateFormat(rec.birthDate);
          this.patientViewExtModel.identityNo=rec.identityNo;
          this.patientViewExtModel.genderDesc=rec.genderDesc;
          this.patientViewExtModel.mobileNo=rec.mobileNo;
          this.patientViewExtModel.nationalityDesc=rec.nationalityDesc;
          this.patientViewExtModel.maritalstatusDesc=rec.maritalstatusDesc;
       
          this.patientViewExtColl.push(this.patientViewExtModel);
      }
          var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            useBom: true,
            headers: Object.keys(this.patientViewExtModel)
          };
        
          new Angular2Csv(this.patientViewExtColl, 'Patient Statistics',options);
  
});
  }


  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('patientList').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
   <html>
       <head>
           <title></title>
           <style>

           #visit {
            font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }
        
        #visit td, #visit th {
            border: 1px solid #ddd;
            padding: 8px;
        }
         #visit th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            color: #7a4c41;
        }
      
        .my-header {
            background: red;
            top: 0;
            left: 0;
            position: fixed;
            right: 0;
        }

    
           </style>
       </head>
       <body onload="window.print()">
       <div class="my-header">
       <img src="assets/HOSP_LOGO.jpg"></div>
       
       <h1><center>Patient List</center></h1>
     
       ${printContents}
      
       </body>
   </html>`
    );
    popupWin.document.close();

    //window.print();
};

    tableToExcel(table, filename) {
    let uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
        , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
        , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById('patientList')
    var ctx = { worksheet: name || 'patientList', table: table.innerHTML }
    //window.location.href = uri + base64(format(template, ctx))

    let link = document.createElement('a');
    link.setAttribute('href', uri + base64(format(template, ctx)));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
} 
clearModel()
{
  this.searchModel=new ClcPatientQueryVO();
  this.searchPatientList(this.searchModel);
}

}
