import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { MedicineMasterQueryVO } from '../resources/MedicineMasterQueryVO.resource';
import { MedPharmacyItemMast } from '../resources/MedPharmacyItemMast.resource';

declare var $;

@Component({
  selector: 'app-mast-medicine',
  templateUrl: './app-mast-medicine.component.html',
  styleUrls: ['./app-mast-medicine.component.scss']
})
export class AppMastMedicineComponent implements OnInit {

  config = {
    id: 'medMst',
    currentPage: 1,
    itemsPerPage: 10
  };
  page: number[] = [];
  genericColl: any;
  groupColl: any;
  active_YN:boolean;
  public presGenericName:any;
  public presTradeName:any;
  public presCourseName:any;
  public medicineMstColl:any;
  fillterGenericColl:any;
  public addGenericName:any;  
  filteredGenericColl: any[];
  medicineColl:any;

  medicineMstModel: MedicineMasterQueryVO = new MedicineMasterQueryVO();
  medPharmItemMastModel:MedPharmacyItemMast=new MedPharmacyItemMast();

  @ViewChild('ModalMedicineMst', { static: true }) ModalMedicineMst: ElementRef;
  @ViewChild('medForm', { static: true }) medForm: NgForm;

  constructor(private icareservices: ICAREServices,private toastr: ToastrService,private _sanitizer: DomSanitizer, private imastservices: IMASTServices) { }

  ngOnInit() {
    this.retrieve();
  }

  private retrieve() {
    this.icareservices.listMedGenericMast().subscribe(response => {
      this.genericColl = response;
      this.fillterGenericColl=response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });   
    this.listMedMst();  
    this.listMedGroupMst();  
   
  }

  listMedGroupMst(){
    this.icareservices.listMedicineGroupMaster().subscribe(response => {
      this.groupColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }
  
  listMedMst(){
    this.icareservices.listMedicineMast().subscribe(response => {
      this.medicineColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  valueChangedOnAddTradeName(persVal) {
    if(persVal.itemId){
      this.medicineMstModel.itemId=persVal.itemId;
    }
    else{
      this.presTradeName='';
      this.listMedMst();
    }
  }
  onChangeGenericPres(persVal) {   
    if(persVal.genericId ){
      this.medicineMstModel.genericId=persVal.genericId;       
    }    
    else{
      this.presGenericName='';
      this.listMedGroupMst();      
    }
  }

  searchMedicineMst(){
    this.icareservices.listAllMedicineMast(this.medicineMstModel)
    .subscribe((response) => {
      this.medicineMstColl=response;
      
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  clearMedicineMst(){
    this.presTradeName=null;
    this.presGenericName=null;
    this.medicineMstColl=null;
    //this.page = [];
    this.config.currentPage = 1;
    this.medicineMstModel=new MedicineMasterQueryVO();
  }

  addMedicineMst(){
    this.addGenericName='';
    this.medPharmItemMastModel = new MedPharmacyItemMast();
    this.active_YN=true;
    $(this.ModalMedicineMst.nativeElement).modal('show');
  }

  onChangeGeneric(item) {   
    if(item.genericId ){
      this.medPharmItemMastModel.genericName=item.genericName;
      this.medPharmItemMastModel.genericId=item.genericId;       
    }    
    else{
      this.addGenericName='';
      this.listMedGroupMst();      
    }
  }

  createMedicineMst() {
    if (this.medPharmItemMastModel) {
      if (this.active_YN) {
        this.medPharmItemMastModel.activeYn = 'Y';
      }else{
        this.medPharmItemMastModel.activeYn = 'N';
      }
      this.imastservices.saveMedicine(this.medPharmItemMastModel).subscribe
        ((response) => {
          if (response) {
            this.toastr.success('Medicine Created successfully!', 'Save !');
            //this.medPharmItemMastModel = new MedPharmacyItemMast();
           //$(this.ModalMedicineMst.nativeElement).modal('hide');
           this.searchMedicineMst();
           this.closeMedicineMstModal();
          }
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  editItem(item){
    if(item){ 
      this.medPharmItemMastModel=item;   
      if(item.activeYn='Y'){
        this.active_YN=true;
      }
      this.addGenericName=item.genericName;   
      $(this.ModalMedicineMst.nativeElement).modal('show');
    }
  }

  closeMedicineMstModal(){
    //this.medForm.resetForm();
    this.addGenericName='';
    this.medPharmItemMastModel = new MedPharmacyItemMast();
    $(this.ModalMedicineMst.nativeElement).modal('hide');
  }

 
  filterGenericItemMSt(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.genericColl.length; i++) {
      let generic = this.genericColl[i];
      if (generic.genericName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(generic);
      }
    }

    this.filteredGenericColl = filtered;
  }

  onChangeGenericItem(item: any){    
    if (typeof item === 'object') {
      this.medPharmItemMastModel.genericName=item.genericName;
      this.medPharmItemMastModel.genericId=item.genericId;
    }  
    else{
         this.medPharmItemMastModel=new MedPharmacyItemMast();
    }
  }

  autocompleteTradeName = (data: any): SafeHtml => {
    let html = `<span>${data.itemName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  
  autocompleteGeneric = (data: any): SafeHtml => {
    let html = `<span>${data.genericName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}
