
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';

import * as AppUtils from 'src/app/common/app.utils';
	
	

@Injectable()
 
 export class IBILLServices  {
	
	private AFFINITY_URL_BASE= AppUtils.BACKEND_API_URL ;

	public headerData;
	constructor(private http: Http) { }

	private handleError(error: any): Promise<any> {
	 console.error('Could not complete requested service operation', error);
	 return Promise.reject(error.message || error);
	}
	
 
}

  
