import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { ClcPatientNotes } from '../resources/ClcPatientNotes.resource';
import { ClcNotesTypeMast } from '../resources/ClcNotesTypeMast.resource';

import swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-his-patient-notes',
  templateUrl: './app-his-patient-notes.component.html',
  styleUrls: ['./app-his-patient-notes.component.css']

})

export class PatientNotesComponent implements OnInit {
  @Input()
  public visitId: number;

  @Input()
  public notesTypeCode: number;

  @Input()
  public editYN:string;

  noteDate = new Date();
  private userColl = this._service.persInfo;
  public listTemp: any = [];
  public submitted: boolean = false;
  notesTypeColl: ClcNotesTypeMast;
  listNoteItem: any;
  patientItem: any;
  config:any;
  addNotes='';
  editNotes='';
  //variables for the pagination
  currentPage = 1;
  page: number;
  addNotesModel: ClcPatientNotes = new ClcPatientNotes();
  editNotesModel: ClcPatientNotes = new ClcPatientNotes();

  @ViewChild('ModalPatientNotes', { static: true }) ModalPatientNotes: ElementRef;
  @ViewChild('ModalEditPatientNotes', { static: true }) ModalEditPatientNotes: ElementRef;
  addPatientNotesDiv: boolean;
  editPatientNotesDiv: boolean;
  AngularEditorConfig:AngularEditorConfig

  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: 'initial',
    sanitize: true,
    toolbarHiddenButtons: [
      ['underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
      ]
  };

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.config=this.angularEditorConfig;
    this.listNoteTypeMast(this.notesTypeCode);
  }

  ngOnChanges() {
    this.listVisitPatNotes();
    this.getPatientId();
  }

 listNoteTypeMast(typeCode) {    
    this.icareservices.listNoteTypesMast().subscribe(response => {
      if(typeCode){
        this.notesTypeColl= response.filter(item => item.notesTypeCode === +typeCode);  
      }
      else{
        let typeCode=2;
        this.notesTypeColl = response.filter(item => ![typeCode].includes(item.notesTypeCode));
      }      
    })

  }

  modalAddPatientNotes() {
    this.submitted = true;
    this.editPatientNotesDiv = false;
    this.addPatientNotesDiv = true;
   // $(this.ModalPatientNotes.nativeElement).modal('show');
  }

  modalEditPatientNotes() {
    this.submitted = true;
    this.addPatientNotesDiv = false;
    this.editPatientNotesDiv = true;
   // $(this.ModalEditPatientNotes.nativeElement).modal('show');
  }

  clearModel() {
    this.addNotes='';
    this.editNotes='';
    this.addPatientNotesDiv = false;
    this.editPatientNotesDiv = false;
    this.addNotesModel = new ClcPatientNotes();
    this.editNotesModel = new ClcPatientNotes();
  }

  listVisitPatNotes() {
    if (this.visitId) {
      this.icareservices.listClcVisitNotes(this.visitId)
        .subscribe((response) => {
          this.listNoteItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  getPatientId() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.patientItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  selectNote(noteColl) {
    this.editNotesModel = noteColl;
    this.editNotes=noteColl.notes;
  }

  createPatientNotes() {
    if (this.visitId) {
      if (this.addNotes) {
        this.addNotesModel.visitId = this.visitId;
        //this.addNotesModel.notesTypeCode = this.notesTypeCode;
        this.addNotesModel.createdTime = new Date();
        this.addNotesModel.notesDate = new Date();
        this.addNotesModel.createdBy = this.userColl.persCode;
        this.addNotesModel.notes=this.addNotes;
        this.icareservices.createClcPatientNotes(this.addNotesModel)
          .subscribe((response) => {
            this.listVisitPatNotes();
            this.clearModel();
            //this.addPatientNotesDiv = false;            
            //swal("Save", 'Notes Added successfully :' + response.visitId, 'success');
            //this.addNotesModel = new ClcPatientNotes();
            this.toastr.success('Notes Added successfully :' + response.visitId,'Save !');
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.toastr.warning('Please Enter Notes !','Alert !');
        //swal("Oops!", 'Please Enter Notes !', 'warning');
      }
    }
    else {
      this.toastr.warning('Please enter Visit Id !','Alert !');
      //swal("Alert", 'Please enter Visit Id', 'warning');
    }

  }
  editPatientNotes() {
    if (this.visitId) {
      if (this.editNotesModel.createdBy == this.userColl.persCode) {
        if (this.editNotes) {
          this.editNotesModel.visitId = this.visitId;  //only created user can edit the notes//
          this.editNotesModel.createdTime = new Date();
          this.editNotesModel.createdBy = this.userColl.persCode;
          this.editNotesModel.afiVersion = this.editNotesModel.afiVersion;
          this.icareservices.editClcPatientNotes(this.editNotesModel)
            .subscribe((response) => {
              this.listVisitPatNotes();
              this.clearModel();
              //this.editPatientNotesDiv = false;
              //swal("Edited", 'Notes Edited successfully :' + response.visitId, 'success');
              //this.toastr.success('Notes Edited successfully :' + response.visitId,'Save !');
            }, err => {
              this.toastr.warning(err.split(":")[3], 'Alert !');
          });
        }
        else {
          //swal("Oops!", 'Please Enter the Notes !', 'warning');
          this.toastr.warning('Please Enter the Notes !','Alert !');
        }
      }
      else {
        this.listVisitPatNotes();
        this.toastr.warning('Edit Previllage For Created User Only !','Alert !');
        //swal("Oops!", 'Edit Previllage For Created User Only !', 'warning');
      }
    }
    else {
      this.toastr.warning('Please enter Visit Id !','Alert !');
      //swal("Oops!", 'Please enter Visit Id!', 'warning');
    }
  }


  deleteNotes() {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.value) {
        this.icareservices.deleteClcPatientNotes(this.editNotesModel.notesId)
          .subscribe((response) => {
            this.listVisitPatNotes();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
    });
  }

}
