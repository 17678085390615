export class ClcVisitViewExt{
    visitTime: string;
     birthDate: string;
      patientNo: string;
    patientName: string;
    sexCode: string;
     mobileNo: number;
    deptName: string;
    clinicName: string;
    visitStatusDesc: string;
    visitTypeDesc: string;
    packageDesc: string;
    policyName: string;
     patientId: number;
    public constructor() {}
   }
       