import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Observable } from 'rxjs/Observable';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { ErpServices } from 'src/app/services/index';
import * as AppUtils from 'src/app/common/app.utils';

import { MstPersClinicView } from '../resources/MstPersClinicView.resource';
import { MstSalutationMast } from '../resources/MstSalutationMast.resource';
import { MstSexMast } from '../resources/MstSexMast.resource';
import { MstCountryMast } from '../resources/MstCountryMast.resource';
import { MstMaritalStatusMast } from '../resources/MstMaritalStatusMast.resource';
import { MstPolicyMast } from '../resources/MstPolicyMast.resource';
import { MstPackageMast } from '../resources/MstPackageMast.resource';
import { MstRelationshipMast } from '../resources/MstRelationshipMast.resource';
import { ClcPatients } from '../resources/ClcPatients.resource';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';
import { ClcPatientViewColl } from '../resources/ClcPatientViewColl.resource';
import { ClcPatientColl } from '../resources/ClcPatientColl.resource';
import { ClcPatientPolicyCollView } from '../resources/ClcPatientPolicyCollView.resource';
import { ClcPatientPolicyColl } from '../resources/ClcPatientPolicyColl.resource';
import { ClcPatientPackages } from '../resources/ClcPatientPackages.resource';
import { ClcPatientNextkin } from '../resources/ClcPatientNextkin.resource';
import { ClcPatientPackageView } from '../resources/ClcPatientPackageView.resource';
import { ClcPatientNextkinView } from '../resources/ClcPatientNextkinView.resource';
import { ClcVisits } from '../resources/ClcVisits.resource';
import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';
import { YEAR } from 'ngx-bootstrap/chronos/units/constants';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { ClcVisitView } from '../resources/ClcVisitView.resource';
import {ClcPatPolicyCoPays} from '../resources/ClcPatPolicyCoPays.resource';

declare var $;

@Component({
  selector: 'app-his-patient-register',
  templateUrl: './app-his-patient-register.html',
  styleUrls: ['./app-his-patient-register.css']
})

export class PatRegisterComponent implements OnInit {
  datepicker = {
    format: 'MM/dd/yyyy'
  }

  selectedRow: Number;
  filterResult: Array<any>=[];
  public submitted: boolean = false;
  
  private nextKinFilter: any;
  private policyFilter: any;
  private packageFilter: any;
  private tempColl: ClcPatientColl;
  private birthDate;
  private policyStartDate;
  private policyEndtDate;
  private packageStart;
  private packageEnd;
  private patNo;
  private motherPatId;
  private buttonEnableDisable;
  public btnDisablePatVisit: boolean = false;
  data: Observable<Array<any>>;
  newVisit: boolean;
  editVisit: boolean;
  addNewPolicy: boolean;
  editPolicyDiv: boolean;
  addnewPackageDiv: boolean;
  editPackageDiv: boolean;
  addnewNextKinDiv: boolean;
  editNextKinDiv: boolean;
  config: any;

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  @ViewChild('someModal', { static: true }) someModal: ElementRef;
  @ViewChild('patientVisitModal', { static: true }) patientVisitModal: ElementRef;
  @ViewChild('patientEditVisit', { static: true }) patientEditVisit: ElementRef;
  @ViewChild('billModal', { static: true }) billModal: ElementRef;
  @ViewChild('coPayModal', { static: true }) coPayModal: ElementRef;
  

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, public datepipe: DatePipe, private _sanitizer: DomSanitizer,private toastr: ToastrService,private _service: ErpServices) { }

  modelColl: ClcPatients[] = [];
  searchColl: ClcPatientQueryVO[] = [];
 
  nationColl: any;
  marryColl: any;
  saluteColl: any;
  genderColl: any;
  policyColl: any;
  packageColl: any;
  relationColl: any;
  searchItems: any;
  listPatPolicyCOll: any;
  listPatPackageCOll: any;
  patVisitView: any;
  patVisitList: any;
  listClinics: any;
  listClinicView: any;
  listConsultant: any;
  listMstVillage:any;
  listMstCity:any;
  listPolicyCoPay:any=[];
  listCoPayDtl:any;
  
  dOb: any;

  vDateOfBirth: any;
  dateOfBirth: any;
  ageOfPatient: any;
  currentYear: any;
  birthYear: any;
  d1: any;
  defaultPolType: any;
  clinicEst:boolean;

  public visitId: string;
  public age: number;
  public consultantItem: any;
  public villageName: any;
  public cityName:any;
  public formGroupValidation=this._service.listMstParams;

  options:boolean=true;
  patVisitfollow:boolean;
  //variables for the pagination
  //currentPage = 1;
  pageVisit: number = 1;
  /* print component */
  isValidDate:any;
  //: MstPersClinicView=new MstPersClinicView();
  visitQuery: ClcVisitQueryVO = new ClcVisitQueryVO();
  visitItem: ClcVisits = new ClcVisits();
  editVisitItem: ClcVisits = new ClcVisits();
  modelItem: ClcPatients = new ClcPatients();
  searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();
  retColItem: ClcPatientViewColl = new ClcPatientViewColl();
  reqItem: ClcPatientColl = new ClcPatientColl();
  saluteItem: MstSalutationMast = new MstSalutationMast();
  genderItem: MstSexMast = new MstSexMast();
  nationitem: MstCountryMast = new MstCountryMast();
  marryItem: MstMaritalStatusMast = new MstMaritalStatusMast();
  policyItem: ClcPatientPolicyCollView = new ClcPatientPolicyCollView();
  inPolicy: ClcPatientPolicyColl = new ClcPatientPolicyColl();
  outPolicy: ClcPatientPolicyColl = new ClcPatientPolicyColl();
  inPackage: ClcPatientPackages = new ClcPatientPackages();
  outPackage: ClcPatientPackages = new ClcPatientPackages();
  packageItem: ClcPatientPackageView = new ClcPatientPackageView();
  inNextKin: ClcPatientNextkin = new ClcPatientNextkin();
  outNextKin: ClcPatientNextkin = new ClcPatientNextkin();
  nextKinView: ClcPatientNextkinView = new ClcPatientNextkinView();
  patPolicyCoPays:ClcPatPolicyCoPays[] = []; //ClcPatPolicyCoPays=new ClcPatPolicyCoPays();
  eidtCoPayDtl:ClcPatPolicyCoPays[] = []; 
  private retrieve() {

    this.inPolicy.focDays=AppUtils.CONSULTATION_CHARGE;

    this.imastservices.listMstCountryMast().subscribe(response => {
      this.nationColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listMstMaritalStatusMast().subscribe(response => {
      this.marryColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });
    
    this.imastservices.listMstSalutationMast().subscribe(response => {
      this.saluteColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listMstSexMast().subscribe(response => {
      this.genderColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.icareservices.listMstPolicyMast().subscribe(response => {
      this.policyColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.icareservices.listMstPackageMast().subscribe(response => {
      this.packageColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listMstRelationshipMast().subscribe(response => {
      this.relationColl = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.isSingleClinicEstablishment().subscribe(response => {
      this.clinicEst = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.icareservices.listClinics().subscribe(response => {
      this.listClinics = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.icareservices.listPersClinicView().subscribe(response => {
      this.listClinicView = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listPersClinicView().subscribe(response => {
      this.listConsultant = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listMstVillageMast().subscribe(response => {
      this.listMstVillage = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.imastservices.listMstCityMast().subscribe(response => {
      this.listMstCity = response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.icareservices.getPolicyCoPayList().subscribe(response => {
      this.listPolicyCoPay=response;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    });

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      //totalItems: this.collection.count
    };
  }

  pageChanged(event){
    this.config.currentPage = event;
  }
  
  setClickedRow(index) {
    this.selectedRow = index;
  }

  onKeydown(event) {
    this.searchPatient();
  }

  onKeyPress(event) {
    this.searchPatientList(this.searchModel);
  }

  dateFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd');
  }


  dateTimeFormat() {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, 16);
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 8);
  }

  validateDates(sDate: string, eDate: string){
    this.isValidDate = true;
    if((sDate == null || eDate ==null)){          
     this.toastr.warning('Start date and end date are required !','Alert !');
      this.isValidDate = false;
    }

    if((sDate != null && eDate !=null) && (eDate) < (sDate)){     
      this.toastr.warning('End date should be grater then start date !','Alert !');
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  /** onchange event **/
  onChangeClinicCode(id) {
    let clinicCode;
    if(id.target.value.includes(':')){
       clinicCode=id.target.value.split(":")[1];
       //this.filterConsultant(clinicCode);
       this.filterResult = this.listConsultant.filter(item => item.clinicCode === +clinicCode);
    }
  
  }

 filterConsultant(clinicCode){  
  this.filterResult = this.listConsultant.filter(item => item.clinicCode === +clinicCode);
  this.visitItem.consultantCode=this.filterResult[0].mstPersonalMast.persCode;
 }

  selectedClinicCode(id) {
   // this.consultantItem = this.onChangeClinicCode(id).mstPersonalMast.printName;
  }

  bindClinicCode(id) {
    this.onChangeClinicCode(id);
   // this.consultantItem = this.filterResult.mstPersonalMast.printName;
  }

  bindAge() {
    if (this.retColItem.birthDate) {
      const bdate = new Date(this.retColItem.birthDate);
      const timeDiff = Math.abs(Date.now() - bdate.getTime());
      //Used Math.floor instead of Math.ceil
      //so 26 years and 140 days would be considered as 26, not 27.
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

    }
  }

  bindDOB() {
    this.ageOfPatient = this.age;
    var d = new Date();
    this.d1 = this.datepipe.transform(d, 'yyyy');
    this.currentYear = this.d1;
    this.birthYear = this.currentYear - this.ageOfPatient;
    this.vDateOfBirth = '1-1-' + this.birthYear;
    this.dateOfBirth = this.vDateOfBirth;
    this.birthDate = this.dateFormat(this.vDateOfBirth);
    this.retColItem.birthDate = this.birthDate;
  };

  agePatient() {
    var d = new Date();
    this.d1 = this.datepipe.transform(d, 'yyyy');
    this.currentYear = this.d1;
    this.birthYear = this.datepipe.transform(this.retColItem.birthDate, 'yyyy');
    this.ageOfPatient = this.currentYear - this.birthYear;
    this.age = this.ageOfPatient;
  }

   /** onchange event **/
   valueChangedOnVillageItem(itemName) {
    if (itemName) {
      this.villageName=itemName;
      //this.reqItem.villageName=itemName;         
    }
  }

  valueChangedOnCityItem(cityName) {
    if (cityName) {
      this.cityName=cityName;
      //this.reqItem.cityName = cityName;
    }
  }

  /* search patient list */
  searchPatient() {
    if (this.searchModel.patientNo) {
      this.icareservices.getPatientViewColl(this.searchModel.patientNo)
        .subscribe((response) => {
          this.retColItem = response;
          if (this.retColItem.villageName) {          
            this.villageName = this.retColItem.villageName;
          }
          if (this.retColItem.cityName) {
            this.cityName = this.retColItem.cityName;
          }  
          this.birthDate = this.dateFormat(this.retColItem.birthDate);
          this.retColItem.birthDate = this.birthDate;
          this.agePatient();
        }, err => {
          swal("Oops!", 'No Patient Id Found !', 'warning');
          //this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
    else if (this.searchModel.mobileNo || this.searchModel.identityNo) {
      this.icareservices.listPatientColl(this.searchModel)
        .subscribe((response) => {
          this.submitted = true;
          $(this.someModal.nativeElement).modal('show');
          this.searchItems = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
    else {
      $(this.someModal.nativeElement).modal('show');
    }
  }

  /* save patient details */
  saveInfo() {
    this.reqItem = new ClcPatientColl();
    this.reqItem.clcPatientNextkin = new Array();
    this.reqItem.clcPatientPolicies = new Array();
    this.reqItem.clcPatientPackages = new Array();
    this.reqItem.patientId = this.retColItem.patientId;
    this.reqItem.patientNo = this.retColItem.patientNo;
    this.reqItem.salutationCode = this.retColItem.salutationCode;
    this.reqItem.identityNo = this.retColItem.identityNo;
    this.reqItem.birthDate = this.retColItem.birthDate;
    this.reqItem.maritalstatusCode = this.retColItem.maritalstatusCode;
    this.reqItem.sexCode = this.retColItem.sexCode;
    this.reqItem.firstName = this.retColItem.firstName;
    this.reqItem.secondName = this.retColItem.secondName;
    this.reqItem.thirdName = this.retColItem.thirdName;
    this.reqItem.mobileNo = this.retColItem.mobileNo;
    this.reqItem.nationalityCode = this.retColItem.nationalityCode;
    this.reqItem.contactDetail = this.retColItem.contactDetail;
    this.reqItem.villageName=this.villageName;//this.retColItem.villageName;
    this.reqItem.cityName=this.cityName;
    if (isNaN(this.retColItem.patientId)) {
     
      if (this.reqItem.firstName && this.reqItem.mobileNo && this.reqItem.secondName && this.reqItem.sexCode && this.reqItem.birthDate) {

        this.icareservices.createPatient(this.reqItem).subscribe
          ((response) => {
            this.retColItem = response;
            //this.filterResult = response;
            this.birthDate = this.dateFormat(this.retColItem.birthDate);
            this.retColItem.birthDate = this.birthDate;
            
            this.toastr.success('Patient registered successfully!','Save !');
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
      else {
        this.toastr.warning('Please Enter FirstName,SecondName,MobileNo,Birthdate and Gender!','Alert !');
        //swal("Oops!", 'Enter the FirstName,SecondName,MobileNo,Birthdate and Gender  !', 'warning');
      }
    }


  }

  /* edit patient details */
  editPatient() {
    this.modelItem.patientId = this.retColItem.patientId;
    this.modelItem.patientNo = this.retColItem.patientNo;
    this.modelItem.salutationCode = this.retColItem.salutationCode;
    this.modelItem.identityNo = this.retColItem.identityNo;
    this.modelItem.maritalstatusCode = this.retColItem.maritalstatusCode;
    this.dOb = this.dateFormat(this.retColItem.birthDate);
    this.modelItem.birthDate = this.dOb;
    this.modelItem.sexCode = this.retColItem.sexCode;
    this.modelItem.firstName = this.retColItem.firstName;
    this.modelItem.secondName = this.retColItem.secondName;
    this.modelItem.thirdName = this.retColItem.thirdName;
    this.modelItem.mobileNo = this.retColItem.mobileNo;
    this.modelItem.nationalityCode = this.retColItem.nationalityCode;
    this.modelItem.contactDetail = this.retColItem.contactDetail;    
    if (this.retColItem.villageName) {
      this.modelItem.villageName = this.retColItem.villageName;
      this.villageName = this.retColItem.villageName;
    } else {
      this.modelItem.villageName = this.villageName;
    }
    if (this.retColItem.cityName) {
      this.modelItem.cityName = this.retColItem.cityName;
      this.cityName = this.retColItem.cityName;
    } else {
      this.modelItem.cityName = this.cityName;
    }  
    this.modelItem.afiVersion = this.retColItem.afiVersion;
    if(this.formGroupValidation[0].paramValue=="Y"){
      return this.toastr.warning( this.formGroupValidation[0].remarks);
    }
   
      this.icareservices.editPatient(this.modelItem).subscribe((response) => {
        this.toastr.success('Patient Details Updated successfully!', 'Updated !');
        this.refreshPatientDetails();
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
        //swal("Alert", err.split(":")[3], 'warning');
        //this.error = err;
      });

   

  }

  /*********** policy **********/
  changeDefaultPolicy() {
    if(this.defaultPolType==true){
      if(this.retColItem.patientId !=undefined && this.retColItem.firstName !=undefined){
        this.inPolicy.insuredName=this.retColItem.firstName+" "+this.retColItem.secondName;
        this.inPolicy.insuredPatRelation=-1;       
      }else{
        this.defaultPolType=false;
        this.toastr.warning('Please Select Patient Visit', 'Alert !');
      }
    }
    else if(this.defaultPolType==false){
      this.inPolicy.insuredName=null;
      this.inPolicy.insuredPatRelation=null;
    }
    
  
  }

  newPolicy(){
    this.editPolicyDiv = false;  
    this.addNewPolicy = true;
    this.defaultPolType=false;
    this.inPolicy.focDays=AppUtils.CONSULTATION_CHARGE;
  }
  closePolicy(){
    this.addNewPolicy = false;
    this.inPolicy = new ClcPatientPolicyColl();
  } 
  showEditPolicy(){
    this.addNewPolicy = false;
    this.editPolicyDiv = true; 
  }
  closeEditPolicy(){
    this.editPolicyDiv = false; 
    this.refreshPatientDetails();
    this.outPolicy=new ClcPatientPolicyColl();
  }

  addOneYear(date) {
    let tempDate:any;
    var startDate = date;
    var numOfYears = 1;
    var expireDate = new Date(startDate);
    expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
    expireDate.setDate(expireDate.getDate() - 1);
    tempDate = this.dateFormat(expireDate);

    return tempDate;

  }

  calculatePolicyDate() {
     let tempDate:any;
    if (this.inPolicy.startDate) {     
      // var startDate = this.inPolicy.startDate;
      // var numOfYears = 1;
      // var expireDate = new Date(startDate);     
      // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
      // expireDate.setDate(expireDate.getDate() -1);
      // tempDate = this.dateFormat(expireDate);
      this.inPolicy.expiryDate = this.addOneYear(this.inPolicy.startDate);      
    }
    if (this.outPolicy.startDate) {     
      // var startDate = this.outPolicy.startDate;
      // var numOfYears = 1;
      // var expireDate = new Date(startDate);     
      // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
      // expireDate.setDate(expireDate.getDate() -1);
      // tempDate = this.dateFormat(expireDate);
      this.outPolicy.expiryDate = this.addOneYear(this.outPolicy.startDate);      
    }

  }  
  // this.isValidDate = this.validateDates(this.datepipe.transform(this.inPolicy.startDate,'yyyy-MM-dd'), this.datepipe.transform(this.inPolicy.expiryDate,'yyyy-MM-dd'));
  createPolicy() {
    if (this.retColItem.patientId) {
      if (this.inPolicy.policyCode) {
        this.inPolicy.patientId = this.retColItem.patientId;
        this.inPolicy.estCode = this.retColItem.estCode;
        this.inPolicy.insuredPatRelation = -1;
        this.inPolicy.startDate = new Date();
        this.inPolicy.expiryDate = new Date(new Date().setMonth(new Date().getMonth() + 12));

        //console.log(this.inPolicy);       
        //this.patPolicyCoPays.push(this.listPolicyCoPay);

        for(let coPay of this.listPolicyCoPay){
            let temPatPolicyCoPay=new ClcPatPolicyCoPays();
            temPatPolicyCoPay.patPolicyId=coPay.patPolicyId;
            temPatPolicyCoPay.itemId=coPay.itemId;
            temPatPolicyCoPay.coPayment=coPay.coPayment;
            temPatPolicyCoPay.coPayPercent=coPay.coPayPercent;
            this.patPolicyCoPays.push(temPatPolicyCoPay);                      
        }
        
        this.inPolicy.clcPatPolicyCoPays=this.patPolicyCoPays;//this.listPolicyCoPay;

        this.icareservices.createClcPatientPolicyColl(this.inPolicy)
          .subscribe
          ((response) => {
            this.refreshPatientDetails();
            this.addNewPolicy = false;
            this.toastr.success('Policy successfully added !','Save !');           
            this.inPolicy = new ClcPatientPolicyColl();
            //this.patPolicyCoPays=new ClcPatPolicyCoPays();
            this.patPolicyCoPays=[];
          }, err => {
            this.toastr.warning(err.split(":")[3],'Alert !');            
          });
      }
      else {       
        this.toastr.warning('Enter Policy Name !','Alert !');        
      }

    }
    else {
      this.toastr.warning('Please select patient first !','Alert !');   
    }
  }

  selectedPolicy(pol) {
    this.patPolicyCoPays=[];    
    this.outPolicy = pol;
    this.selectedRow = pol;
    this.policyStartDate = this.dateFormat(this.outPolicy.startDate);
    this.outPolicy.startDate = this.policyStartDate;
    this.policyEndtDate = this.dateFormat(this.outPolicy.expiryDate);
    this.outPolicy.expiryDate = this.policyEndtDate;

  //   for(let coPay of pol.clcPatPolicyCoPayView){
  //     let temPatPolicyCoPay=new ClcPatPolicyCoPays();
  //     temPatPolicyCoPay.patPolicyId=coPay.patPolicyId;
  //     temPatPolicyCoPay.itemId=coPay.itemId;
  //     temPatPolicyCoPay.coPayment=coPay.coPayment;
  //     temPatPolicyCoPay.coPayPercent=coPay.coPayPercent;
  //     temPatPolicyCoPay.estCode=coPay.estCode;
  //     temPatPolicyCoPay.afiVersion=coPay.afiVersion;
  //     this.patPolicyCoPays.push(temPatPolicyCoPay);                      
  // }
    this.patPolicyCoPays=pol.clcPatPolicyCoPayView;
  }

  getCoPayItemName(itemId) {
    if (itemId) {
      return this.listPolicyCoPay.find(item => item.itemId === itemId).name;
    }
  }

  editPolicy() {  
    if (this.retColItem.patientId) {

      if (this.outPolicy.policyCode) {

        for (let coPay of this.patPolicyCoPays) {
          let temPatPolicyCoPay = new ClcPatPolicyCoPays();
          temPatPolicyCoPay.runId=coPay.runId;
          temPatPolicyCoPay.patPolicyId = coPay.patPolicyId;
          temPatPolicyCoPay.itemId = coPay.itemId;
          temPatPolicyCoPay.coPayment = coPay.coPayment;
          temPatPolicyCoPay.coPayPercent = coPay.coPayPercent;
          temPatPolicyCoPay.estCode = coPay.estCode;
          temPatPolicyCoPay.afiVersion = coPay.afiVersion;          
          this.eidtCoPayDtl.push(temPatPolicyCoPay);
        }

        this.outPolicy.clcPatPolicyCoPays=this.eidtCoPayDtl;

        this.icareservices.editClcPatientPolicyColl(this.outPolicy)
          .subscribe
          ((response) => {
            this.editPolicyDiv = false; 
            this.toastr.success('Policy details updated for the patient','Updated !');
            //swal("Updated", 'Policy details updated for the patient ', 'success');
            this.refreshPatientDetails();
            this.outPolicy=new ClcPatientPolicyColl();
            this.eidtCoPayDtl=[];
          }, err => {
            this.toastr.warning(err.split(":")[3],'Alert !');
            //swal("Alert", err.split(":")[3], 'warning');
            //this.error = err;
          });
      }
      else {
        this.toastr.warning('Enter Policy Name !','Alert !');
        //swal("Oops!", 'Enter Policy Name !', 'warning');
      }
    }
  }  

  toggleListCoPayDetails(patPolicyCoPay){
    if(patPolicyCoPay){
      this.listCoPayDtl=patPolicyCoPay.clcPatPolicyCoPayView;
      $(this.coPayModal.nativeElement).modal('show');
    }   
  }

  closeCoPayModal(){
    $(this.coPayModal.nativeElement).modal('hide');
  }

  clearEditPolicy(){ 
    this.editPolicyDiv = false; 
    this.outPolicy=new ClcPatientPolicyColl();
    this.outPolicy=null;
  }
 

  deletePolicy() {
    this.icareservices.deleteClcPatientPolicyColl(this.outPolicy.patPolicyId)
      .subscribe
      ((response) => {
        this.refreshPatientDetails();
        //swal("Deleted", 'Policy deleted for the patient ', 'success');
        this.toastr.warning('Policy deleted for the patient !','Deleted !');
      }, err => {
        this.toastr.warning(err.split(":")[3],'Alert !');
        //swal("Alert", err.split(":")[3], 'warning');
        //this.error = err;
      });
  }
  /*********  policy ends ***********/

  /*********** NextKin *************/
  showNewNextKin(){
    this.editNextKinDiv = false;
    this.addnewNextKinDiv = true;
  }  
  closeNextKin(){
    this.addnewNextKinDiv = false;
    this.inNextKin = new ClcPatientNextkin();
  }
  showEditNextkin(){
    this.addnewNextKinDiv = false;
    this.editNextKinDiv = true;
  }
  closeEditNextkin(){    
    this.editNextKinDiv = true;
    this.addnewNextKinDiv = false;
    this.refreshPatientDetails();
    this.outNextKin = new ClcPatientNextkin();
  }
 

  selectedNextKin(dep) {
    this.outNextKin = dep;
    if (this.retColItem.patientId == this.outNextKin.patientId) {
      return this.buttonEnableDisable = false;
    }
    else {
      return this.buttonEnableDisable = true;
    }
  }

  createNextKin() {
    if (this.retColItem.patientId != undefined) {
      if (this.inNextKin.relationshipCode && this.inNextKin.givenName) {
        this.inNextKin.patientId = this.retColItem.patientId;
        this.inNextKin.estCode = this.retColItem.estCode;
        this.icareservices.createClcPatientNextkin(this.inNextKin)
          .subscribe
          ((response) => {
            this.refreshPatientDetails();
            this.addnewNextKinDiv = false;
            this.toastr.success('NextKin details added for the patient !','Save !');
            //swal("Save", 'NextKin details added for the patient ', 'success');           
            this.inNextKin = new ClcPatientNextkin();
          }, err => {
            this.toastr.warning(err.split(":")[3],'Alert !');
            //swal("Alert", err.split(":")[3], 'warning');
            //this.error = err;
          });
      }
      else {
        this.toastr.warning('Enter GivenName and Relation !','Alert !');
        //swal("Oops!", 'Enter GivenName and Relation!', 'warning');
      }
    }
    else {
      this.toastr.warning('Please select patient first !','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editNextKin() {   
    if (this.retColItem.patientId != undefined) {
      if (this.outNextKin.relationshipCode && this.outNextKin.givenName) {
        this.outNextKin.patientId = this.retColItem.patientId;
        this.outNextKin.estCode = this.retColItem.estCode;
        this.icareservices.editClcPatientNextkin(this.outNextKin)
          .subscribe
          ((response) => {
            this.editNextKinDiv = false;
            //swal("Updated", 'NextKin details updated for the patient ', 'success');
            this.toastr.success('NextKin details updated for the patient !','Updated !');
            //alert('The Nextkin details updated for patient :  ' + this.retColItem.patientId + ' successfully');
            this.refreshPatientDetails();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.toastr.warning('Enter GivenName and Relation !','Alert !');
        //swal("Oops!", 'Enter GivenName and Relation!', 'warning');
      }
    }


  }
  /*********** End of NextKin ******/

  /******* package begins *********/
  showNewPackage(){
    this.addnewPackageDiv = true;
    this.editPackageDiv = false;
  }
  closePackage(){
    this.addnewPackageDiv = false;
    this.inPackage = new ClcPatientPackages();
  }
  showEditPackage(){
    this.addnewPackageDiv = false;
    this.editPackageDiv = true;
  }
  closeEditPackage(){
    this.editPackageDiv = false;
    this.refreshPatientDetails();
    this.outPackage = new ClcPatientPackages();
  }

  selectedPackage(dep) {
    this.outPackage = dep;
    this.selectedRow = dep;
    this. packageStart = this.dateFormat(this.outPackage.packageStartDate);
    this.outPackage.packageStartDate = this. packageStart;
    this.packageEnd = this.dateFormat(this.outPackage.packageEndDate);
    this.outPackage.packageEndDate = this.packageEnd;
  }

  createPackage() {
   
    if (this.retColItem.patientId) {
      if (this.inPackage.packageCode && this.inPackage.packageStartDate && this.inPackage.packageEndDate) {
        this.inPackage.patientId = this.retColItem.patientId;
        this.inPackage.estCode = this.retColItem.estCode;
        this.icareservices.createClcPatientPackage(this.inPackage)
          .subscribe
          ((response) => {
            this.refreshPatientDetails();
            this.addnewPackageDiv = false;
            this.toastr.success('Package details added for the patient !','Save !');
            //swal("Save", 'Package details added for the patient ', 'success');
            this.inPackage = new ClcPatientPackages();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.toastr.success('Enter PackageName,StartDate and Expirydate !','Alert !');
        //swal("Oops!", 'Enter PackageName,StartDate and Expirydate !', 'warning');
      }
    }
    else {
      this.toastr.warning('Please select patient first !','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editPackage() {
    if (this.retColItem.patientId != undefined) {
      if (this.outPackage.packageCode && this.outPackage.packageStartDate && this.outPackage.packageEndDate) {
        this.icareservices.editClcPatientPackage(this.outPackage)
          .subscribe
          ((response) => {
            this.editPackageDiv = false;
            this.toastr.success('Package details updated for the patient','Save !');
            //swal("Updated", 'Package details updated for the patient ', 'success');
            this.refreshPatientDetails();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.toastr.warning('Enter PackageName,StartDate and Expirydate','Alert !');
        //swal("Oops!", 'Enter PackageName,StartDate and Expirydate !', 'warning');
      }
    }

  }

  deletePackage() {
    this.icareservices.deleteClcPatientPackage(this.outPackage.patPackageId)
      .subscribe
      ((response) => {
        this.refreshPatientDetails();
        this.toastr.warning('Package details deleted for the patient','Alert !');
        //swal("Deleted", 'Package details deleted for the patient ', 'success');
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }
  /******** package ends *******/

  /********* clear fields ********/
  clearModel() {
   
    this.searchModel = new ClcPatientQueryVO();
    this.reqItem = new ClcPatientColl();
    this.retColItem = new ClcPatientViewColl();
    this.modelItem = new ClcPatients();
    this.inPolicy = new ClcPatientPolicyColl();
    this.outPolicy = new ClcPatientPolicyColl();
    this.inPackage = new ClcPatientPackages();
    this.outPackage = new ClcPatientPackages();
    this.inNextKin = new ClcPatientNextkin();
    this.outNextKin = new ClcPatientNextkin();
    this.visitItem= new ClcVisits();
    this.editVisitItem = new ClcVisits();
    
    this.visitId=null;
    this.age=null;
    this.villageName=null;
    this.cityName=null;
    this.searchItems = [];
    this.defaultPolType=false;
  };
  /********* clear fields ********/

  refresh() {
    this.retrieve();
  }

  refreshPatientDetails() {
    if (this.retColItem.patientNo != undefined) {
      this.icareservices.getPatientViewColl(this.retColItem.patientNo)
        .subscribe(response => {
          this.retColItem = response;
          this.dOb = this.dateFormat(this.retColItem.birthDate)
          this.birthDate = this.dOb;
          this.retColItem.birthDate = this.birthDate;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  /****** search Patient List modal******/
  bindPatientVisit(patColl) {   
    this.selectedRow = patColl;
  }

  closeBillModal(){
    $(this.billModal.nativeElement).modal('hide');
  }

  selectedPatVisit(patColl) {
    if ($(this.someModal.nativeElement).modal('show')) {
      $(this.someModal.nativeElement).modal('hide');
      this.icareservices.getPatientViewColl(patColl.patientNo)
        .subscribe((response) => {
          this.retColItem = response;
          if (this.retColItem.villageName) {          
            this.villageName = this.retColItem.villageName;
          }
          if (this.retColItem.cityName) {
            this.cityName = this.retColItem.cityName;
          }  
          this.birthDate = this.dateFormat(this.retColItem.birthDate);
          this.retColItem.birthDate = this.birthDate;
          this.agePatient();
        });
    }
  }

  searchPatientList(searchModel) {
    this.icareservices.listPatientColl(searchModel)
      .subscribe((response) => {
        this.searchItems = response;
      }, err => {       
        this.toastr.warning(err.split(":")[3], 'Alert !'); 
    });
  }

  bindPatientDetails(coll) {
    if ($(this.someModal.nativeElement).modal('show')) {
      $(this.someModal.nativeElement).modal('hide');
      this.icareservices.getPatientViewColl(coll.patientNo)
        .subscribe((response) => {
          this.retColItem = response;
          this.birthDate = this.dateFormat(this.retColItem.birthDate);
          this.retColItem.birthDate = this.birthDate;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  /****** search Patient List modal******/

  /******** patient visit ********/


  getPatPackagePolicyDetails() {
    this.icareservices.listClcPatientPackages(this.retColItem.patientId).subscribe(response => {
      this.listPatPackageCOll = response;
    })
    this.icareservices.listClcPatientPolicyColl(this.retColItem.patientId).subscribe(response => {
      this.listPatPolicyCOll = response;
    })
  }

  getPatientVisitList() {
    if (this.retColItem.patientId) {
      this.submitted = true;
      this.visitItem = new ClcVisits();
      this.visitItem.visitTime= this.dateTimeFormat();
      //$(this.patientVisitModal.nativeElement).modal('show');
      this.editVisit = false;
      this.newVisit = true;
      if(this.clinicEst==true){
        this.visitItem.clinicCode=this.listClinics[0].clinicCode;
        this.filterConsultant(this.visitItem.clinicCode);
    
      }
      this.getPatPackagePolicyDetails();
    }
    else {
      this.toastr.warning('Please select patient first','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  closeAddVisit() {
    this.newVisit = false;
    this.visitItem = new ClcVisits();
    this.consultantItem = null;
    this.filterResult = null;
  }

  createPatientVisit() {
    if (this.retColItem.patientId) {
      this.btnDisablePatVisit = true;
      if(this.formGroupValidation[1].paramValue=="Y"){
        return this.toastr.warning( this.formGroupValidation[1].remarks);
      }
      if (this.visitItem.clinicCode) {
        this.visitItem.patientId = this.retColItem.patientId;
        this.visitItem.visitType = 1;
        this.visitItem.visitTime=this.toDateString(new Date(this.visitItem.visitTime));//new Date(this.visitItem.visitTime).toString();
        this.icareservices.createVisit(this.visitItem)
          .subscribe((response) => {
            this.newVisit = false;
            this.btnDisablePatVisit = false;
            this.patVisitView = response;
            this.listPatientVisits();
            this.toastr.success('Patient Visit Added successfully','Save !');
            //swal("Save", 'Patient Visit Added successfully ', 'success');
            this.visitItem=new ClcVisits();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
      else {
        this.btnDisablePatVisit = false;
        this.toastr.warning('Please Enter Clinic Name','Alert !');
        //swal("Oops!", 'Please Enter Clinic Name !', 'warning');
      }

    }
    else {
      this.btnDisablePatVisit = false;
      this.toastr.warning('Please select patient first','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  getPatientVisitBillModal(visitColl){
    this.visitId = visitColl; //visitId
    $(this.billModal.nativeElement).modal('show');
  }
  
  selectPatVisit(visitColl) {
    this.selectedRow = visitColl;
  }

  selectedPatVisitRow(visitColl) {
    this.submitted = true;    
    this.newVisit = false;
    this.editVisit = true;
    
    this.getPatPackagePolicyDetails();
    this.editVisitItem = visitColl;
    this.editVisitItem.visitTime=this.toDateString(new Date(visitColl.visitTime));
    //this.editVisitItem.visitTime= this.dateTimeFormat(visitColl.visitTime);
    
    this.filterResult=this.listConsultant.filter(item => item.clinicCode === visitColl.clinicCode);
  }
  closeEditVisit(){
    this.editVisit = false;
  }

   

  listPatientVisits() {
    if (this.retColItem.patientId) {
      this.visitQuery.patientId = this.retColItem.patientId
      this.icareservices.listClcVisitView(this.visitQuery)
        .subscribe(response => {
          //return this.patVisitView = response;
          this.retColItem.clcVisitView = response;
          this.birthDate = this.dateFormat(this.retColItem.birthDate);
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });

    }
  }

  editPatientVisit(editPatVisit) {
    if (this.retColItem.patientId) {
      //$(this.patientEditVisit.nativeElement).modal('show');
      this.editVisitItem.patientId = this.retColItem.patientId;
      this.editVisitItem.clinicCode = editPatVisit.clinicCode;
      this.editVisitItem.consultantCode = editPatVisit.consultantCode; //editPatVisit.consultantCode;
      this.editVisitItem.visitType = 1;
      this.editVisitItem.patPolicyId = editPatVisit.patPolicyId;
      this.editVisitItem.patPackageId = editPatVisit.patPackageId;
      this.editVisitItem.prevVisitId = editPatVisit.prevVisitId;
     
      this.editVisitItem.afiVersion = this.editVisitItem.afiVersion;
      //this.editVisitItem.visitTime=new Date(editPatVisit.visitTime);
      this.icareservices.editVisit(this.editVisitItem)
        .subscribe((response) => {
          this.patVisitView = response;
          //this.refreshPatientDetails();
          this.editVisit = false;
          this.listPatientVisits();
          this.toastr.success('Patient Visit Updated successfully','Updated !');
          //swal("Updated", 'Patient Visit Updated successfully ', 'success');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }
  /******** patient visit ********/

  /* bind autocomplete list on search of list icd items */
  autocompleteListVillageMst = (data: any): SafeHtml => {
    let html = `<span>${data.villageName }</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  /* bind autocomplete list on search of list icd items */
  autocompleteListCityMst = (data: any): SafeHtml => {
    let html = `<span>${data.cityName }</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}

