export class MstPoilicyMastExt {
    paymentFlag: string;
 activeYn: string;
 shortCode: string;
 policyName: string;
 afiVersion: number;
 policyType: number;
 schemeCode: number;
 policyCompanyEstCode: number;
 estCode: number;
 policyCode: number;
 policyTypeName:string;
 schemeName:string;
    public constructor() {}
}