import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';

@Component({
    selector: 'app-his-triage',
    templateUrl: './app-his-triage.component.html',
    styleUrls: ['./app-his-triage.component.css']
})
export class PatientTriageComponent implements OnInit {
    @Input()
    public visitId: number;

    @Input()
    public editYN:string;
    
    public persItem: any;
    triCollItem: any = []; //: ClcTriageDetailView = new ClcTriageDetailView();

    constructor(private icareservices: ICAREServices,private toastr: ToastrService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.retrieve();
    }

    public retrieve() {
        this.triCollItem = [];
        this.getVisitTriageDetails();
    }

    getVisitTriageDetails() {
        if (this.visitId) {
            this.icareservices.getVisitTriageDetails(this.visitId)
                .subscribe((response) => {
                    if (response) {
                        this.triCollItem = response;
                    }
                }, err => {
                    this.toastr.warning( err.split(":")[3],'Alert !');           
                  });
        }
    }


}
