import {ClcExamTranDetl} from './ClcExamTranDetl.resource';
export class ClcExamTranColl{
 createdTime: Date;
 examType: string;
 examId: number;
 deptCode: number;
 clinicCode: number;
 createdBy: number;
 rootTemplateId: number;
 visitId: number;
 pregnancyId: number;
 estCode: number;
 afiVersion: number;
 clcExamTranDetl: ClcExamTranDetl[];
 public constructor() {}
}
	
  
