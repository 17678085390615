import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services';
import { MstSchemeMast } from '../resources/MstSchemeMast.resource';
import swal from 'sweetalert2';

@Component({
  selector: 'app-mast-schemes',
  templateUrl: './app-mast-schemes.html'
  //, styleUrls: ['./app-mast-schemes.component.css']
})
export class SchemeMastComponent implements OnInit {
  addNewScheme: boolean;
  editScheme: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;

  ngOnInit() {
    this.listScheme();
  }

  constructor(private icareservices: ICAREServices,private toastr: ToastrService) { }

  modelColl: MstSchemeMast[] = [];
  modelItem: MstSchemeMast = new MstSchemeMast();

   listScheme() {
    this.icareservices.listMstSchemeMast(this.modelItem).subscribe(response => {
      this.modelColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  saveInfo() {
    if (isNaN(this.modelItem.schemeCode)) {
      this.icareservices.createMstSchemeMast(this.modelItem)
        .subscribe((response) => {
          this.modelColl = response;
          this.listScheme();
          this.toastr.success('Scheme Created successfully !', 'Save !');
          //alert('The scheme created successfully');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.icareservices.editMstSchemeMast(this.modelItem)
        .subscribe((response) => {
          this.modelColl = response;
          this.listScheme();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
        this.toastr.success('Scheme Updated successfully !', 'Updated !');
     // alert('The selected scheme is modified successfully');
    }
    this.clearModel();
  }

  removeItem(data) {
    if (confirm("Are you sure to delete the Scheme : " + data.schemeDesc + "?")) {
      this.icareservices.deleteMstSchemeMast(data.schemeCode).subscribe
        ((response) => {
          this.listScheme();
          this.toastr.success(data.schemeDesc + '&nbsp; Scheme deleted successfully !', 'Updated !');
          //swal("Save", data.schemeDesc + '&nbsp; Scheme deleted successfully !', 'success');
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  };

  editItem(data) {
    this.addNewScheme = true;
    this.modelItem = data;
    this.modelItem.schemeCode = data.schemeCode;
    this.modelItem.schemeDesc = data.schemeDesc;
    this.modelItem.shortCode = data.shortCode;
    this.modelItem.activeYn = data.activeYn;
    this.modelItem.afiVersion = data.afiVersion;
  };

  clearModel() {
    this.modelItem = new MstSchemeMast();
    this.addNewScheme = false;
  };

  newSchemeMast() {
    this.addNewScheme = true;
  }

  refresh() {
    this.listScheme();
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printSectioncounter').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
     <html>
         <head>
             <title></title>
             <style>

             #counter {
              font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              width: 100%;
          }
          
          #counter td, #counter th {
              border: 1px solid #ddd;
              padding: 8px;
          }
           #counter th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              color: #7a4c41;
          }

          
             </style>
         </head>
         <body onload="window.print();window.close()">

         <h1><center>Counter Master Details</center></h1>
         
         ${printContents}
        
         </body>
     </html>`
    );
    popupWin.document.close();
    //window.print();
  };

  tableToExcel(table, name) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }

  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("schemeMast");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("TR");
      for (i = 1; i < (rows.length - 1); i++) {

        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
}



