import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcPatientViewColl } from '../resources/ClcPatientViewColl.resource';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';

declare var $;

@Component({
  selector: 'app-his-patient-summary',
  templateUrl: './app-his-patient-summary.component.html',
  styleUrls: ['./app-his-patient-summary.component.css']
})
export class patientSummaryComponent implements OnInit {

  @Input()
  public visitId: number;
  
  bsValue = new Date();
  nationColl: any;
  marryColl: any;
  saluteColl: any;
  genderColl: any;
  policyColl: any;
  packageColl: any;
  relationColl: any;
  searchItems: any;
  patVisitView: any;
  patVisitList: any;
  listClinics: any;

  private submitted: boolean = false;
  public menuItem;
  public counter: number = 0;
  public showStyle: boolean = false;
  public homeTabSelect: boolean = true;
  public menus: any;
  public tabs = [];

  retColItem: ClcPatientViewColl = new ClcPatientViewColl();
  searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();

  @ViewChild('visitSearchModal', { static: true }) visitSearchModal: ElementRef;

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, public datepipe: DatePipe, private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.retrieve();
  }

  ngOnChanges() {

  }

  doInit() {

  }
  public retrieve() {

    this.imastservices.listMstCountryMast().subscribe(response => {
      this.nationColl = response;
    })
    this.imastservices.listMstMaritalStatusMast().subscribe(response => {
      this.marryColl = response;
    })
    this.imastservices.listMstSalutationMast().subscribe(response => {
      this.saluteColl = response;
    })
    this.imastservices.listMstSexMast().subscribe(response => {
      this.genderColl = response;
    })
    this.icareservices.listMstPolicyMast().subscribe(response => {
      this.policyColl = response;
    })
    this.icareservices.listMstPackageMast().subscribe(response => {
      this.packageColl = response;
    })
    this.imastservices.listMstRelationshipMast().subscribe(response => {
      this.relationColl = response;
    })

    this.icareservices.listClinics().subscribe(response => {
      this.listClinics = response;
    })
  }
  
  dateFormat(param) {
    return this.datepipe.transform(param, 'DD/MM/YYYY');
  }

  eventPatientSummary(data) {
    this.searchModel.patientNo = data.patientNo;
    this.getPatientViewColl(this.searchModel.patientNo)
    this.submitted = false;
    $(this.visitSearchModal.nativeElement).modal('hide');

}

  /* search patient list */
  searchPatient() {

    if (this.searchModel.patientNo) {
     this.getPatientViewColl(this.searchModel.patientNo)
    }
    else{
      this.submitted = true;
      $(this.visitSearchModal.nativeElement).modal('show');
    }

  }

  getPatientViewColl(data){
    this.icareservices.getPatientViewColl(this.searchModel.patientNo)
    .subscribe((response) => {
      this.retColItem = response;
      this.clearOpenTabs();
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  onKeydown(event) {
    this.searchPatient();
  }

  openNav() {
    this.showStyle = !this.showStyle;
  }

  closeNav() {
    this.showStyle = false;
  }

  getSidenavStyle() {
    if (this.showStyle) {
      return "250px";
    } else {
      return "0";
    }
  }

  getMainStyle() {
    if (this.showStyle) {
      return "250px";
    } else {
      return "0";
    }
  }

  getToogle() {
    if (this.showStyle) {
      return "toggled";
    } else {
      return "toggled-2";
    }
  }

  addTab(data) {

    this.homeTabSelect = false;
    let id: number = this.counter + 1;
    let recentCheck: number = 0;
    let tabNumber: number = 0;
    for (let tab of this.tabs) {
      if (tab.visitId == data.visitId) {
        tabNumber = tab.id;
        console.log('Main-For-TabNumer')
        recentCheck++;
      }
    }
    if (recentCheck == 0) {

      this.tabs.push({
        title: this.datepipe.transform(data.visitTime, 'dd/MM/yyyy HH:mm'), active: true, id: id,
        visitId: data.visitId, removable: true, customClass: "customClass", url: "app-his-visit-summary"
      });


      this.counter++;

      for (let i in this.tabs) {
        if (this.tabs[i].id == id) {
          this.tabs[i].active = true;
          this.visitId = data.visitId;
          //console.log('For-if-true 1')
        } else {
          this.tabs[i].active = false;
         // console.log('For-if-false 2')
        }
      }
    }
    else {
      for (let j in this.tabs) {
        if (this.tabs[j].id == tabNumber) {
          this.tabs[j].active = true;
         // console.log('For-else--true 3')
        } else {
          this.tabs[j].active = false;
         // console.log('For-else--False 4')
        }
      }
    }

  };

  tabSelect(tabData) {
    for (let j in this.tabs) {
      /* 	if (tabData.url == "home") {
          this.homeTabSelect = true;
        } else {
          this.homeTabSelect = false;
        }
        } this.tabs[j].id == tabData.id
         */
      if (this.tabs[j].visitId == tabData.visitId) {
        this.tabs[j].active = true;
        //console.log('tabselect-if--true 5')
        this.visitId=this.tabs[j].visitId;
      } else {
        //console.log('tabselect-else--false 6')
        this.tabs[j].active = false;
      }
    }
  }

  removeTabHandler(data1) {
    for (let k in this.tabs) {
      if (this.tabs[k].id == data1.id) {
        this.tabs.splice(+k, 1);
      }
    }
  };

  clearOpenTabs(){
    for (var i = 0; i < this.tabs.length - 1; i++) {
      this.tabs.splice(i);
    }
    this.tabs.splice(i);
  }

  clearSearchPatient() {
    this.clearOpenTabs();
    this.retColItem = new ClcPatientViewColl();
    this.searchModel = new ClcPatientQueryVO();
  }

  getAge(dateString) {

    var birthdate = new Date(dateString).getTime();
    var now = new Date().getTime();
    // now find the difference between now and the birthdate
    var n = (now - birthdate)/1000;
  
    if (n < 604800) { // less than a week
      var day_n = Math.floor(n/86400);
      return day_n + ' D' + (day_n > 1 ? 's' : '');
    } else if (n < 2629743) {  // less than a month
      var week_n = Math.floor(n/604800);
      return week_n + ' W' + (week_n > 1 ? 's' : '');
    } else if (n < 63113852) { // less than 24 months
      var month_n = Math.floor(n/2629743);
      return month_n + ' M' + (month_n > 1 ? 's' : '');
    } else { 
      var year_n = Math.floor(n/31556926);
      return year_n + ' Y' + (year_n > 1 ? 's' : '');
    }
  }
  
  printPatientVisitSummary(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printVisitSummary').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
           <html>
               <head>
                   <title></title>
                   <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
               <style>
                  img {
                    position: initial;
                    top: 0%;
                    left: 0%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(3%, 0%);
                    padding-top: 0px;
                    margin-bottom: 20px;
                    height: 100px;
                    margin-left: 20%;
                }
                body{
                  font-family: monospace; 
               }
               .panel {
                margin-bottom: 5px!important;
                }
                .panel-body {
                  padding: 5px;
              }
               </style>
                   </head>
               <body onload="window.print();window.close()">
      
               ${printContents}
              
               </body>
           </html>`
    );
    popupWin.document.close();
  };
}
