import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcServiceOrders } from '../resources/ClcServiceOrders.resource';
import { AppHisLabOrderReportComponent } from 'src/app/HIMS/app-his-lab-order-report/app-his-lab-order-report.component';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

declare var $;

@Component({
  selector: 'app-his-orders',
  templateUrl: './app-his-orders-component.html',
  styleUrls: ['./app-his-orders-component.css']

})

export class ServiceOrdersComponent implements OnInit {

  @Input()
  public visitId: number;
 
  @Input()
  public serviceTranCode:number;

  @Input()
  public editYN:string;
  
  dateNow : Date = new Date();
  courseColl: any;
  listVisitPres: any;
  listserviceItems:any;
  listServiceColl:any;
  listBillItemMst:any;
  
  public presServiceName:any;
  public userColl=this._service.persInfo;
  listBillItems:any;//this._service.billableItems;

  serviceModel:ClcServiceOrders=new ClcServiceOrders();
  editserviceModel:ClcServiceOrders=new ClcServiceOrders();
  addOrdersDiv: boolean;
  editOrdersDiv: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;

  @ViewChild('ModalPrintOrders', { static: true }) ModalPrintOrders: ElementRef;
  @ViewChild(AppHisLabOrderReportComponent, { static: true })
  private child: AppHisLabOrderReportComponent

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices,private _sanitizer: DomSanitizer,private toastr: ToastrService) { }

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }


  ngOnChanges() {

    this.listServiceOrder();
  }

  private retrieve() {
    //listBillableItems
    // this.icareservices.listClcServiceItems().subscribe(response => {
    //   this.listserviceItems = response;
    // })
    
    this.icareservices.listBillItemTypeMast().subscribe(response => {
      this.listBillItemMst = response;
    }, err => {
      this.toastr.warning( err.split(":")[3],'Alert !');           
    });
  }
  
  clearOrders() {
    this.addOrdersDiv = false;
    this.serviceModel=new ClcServiceOrders();
    this.presServiceName=null;   
  }

  clearEditOrders(){
    this.editOrdersDiv = false;
    this.editserviceModel=new ClcServiceOrders();
    this.presServiceName=null;
  }

  /** Orders **/

  selectService(presColl) {
    this.editserviceModel=presColl;
    if(presColl.clcServiceItems){
      this.editserviceModel.itemId=presColl.clcServiceItems.itemId;
    }
    if(presColl.clcServiceItems){
     this.presServiceName=presColl.clcServiceItems.itemName;
    } 
    
    //
  }

  onChangeBillItemEvent(billTypeId){
    let typeId;
    if(isNaN(billTypeId)){
      typeId = +billTypeId.split(":")[1] || 0;
    }else{
      typeId=billTypeId;
    }
    this.listBillItems=this._service.billableItems.filter(itemId => itemId.itemType === +typeId);
    this.presServiceName=null;
  }

  valueChangedOnAddOrders(persVal) {
    if(persVal.itemId){
       this.serviceModel.itemId=persVal.itemId;
    }
  }

  valueChangedOnEditServiceOrders(persVal) {
    if(persVal.itemId){
       this.editserviceModel.itemId=persVal.itemId;
    }
  }

  addOrder(){
   
    this.editOrdersDiv = false;
    this.addOrdersDiv = true;
  }

  createServiceOrder() {   

    if (this.visitId) {
      this.serviceModel.orderTime=new Date();
      this.serviceModel.visitId = this.visitId;
      this.serviceModel.orderBy=this.userColl.persCode;
      this.icareservices.createClcServiceOrder(this.serviceModel)
        .subscribe((response) => {
          this.listServiceOrder();
          this.addOrdersDiv = false;
          this.toastr.success('ServiceOrder Added successfully !','Save !');
          //swal("Save", 'ServiceOrder Added successfully ', 'success');
          this.serviceModel=new ClcServiceOrders();  
          this.presServiceName=null;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
    else{
      this.toastr.warning('Please select patient first !','Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editServiceOrder() {    

    if (this.visitId) {
      if(this.presServiceName==undefined||this.presServiceName==""){
        this.editserviceModel.itemId=null;
      }
      this.editserviceModel.orderTime=new Date();
      this.editserviceModel.visitId = this.visitId;
      this.editserviceModel.orderBy=this.userColl.persCode;
      this.editserviceModel.afiVersion=this.editserviceModel.afiVersion;
      this.icareservices.editClcServiceOrder(this.editserviceModel)
        .subscribe((response) => {
          this.listServiceOrder();
          this.editOrdersDiv = false;
          this.toastr.success('ServiceOrder Edited successfully !','Edited !');
          //swal("Edited", 'ServiceOrder Edited successfully ', 'success');
          this.editserviceModel=new ClcServiceOrders();
          this.presServiceName=null;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
  }

  listServiceOrder() {
    if (this.visitId) {
      this.icareservices.listVisitServiceOrders(this.visitId)
        .subscribe((response) => {
         this.listServiceColl=response;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
  }
  editOrders(){
    this.editOrdersDiv = true;
  }

  deleteOrders(){

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"
     
  }).then((result) => {
      if (result.value) {
        this.icareservices.deleteClcServiceOrder(this.editserviceModel.orderId)
        .subscribe((response) => {
          this.listServiceOrder();
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
      }
  });
  
  }

  printOrdersModal(){
    this.visitId = this.visitId;
    $(this.ModalPrintOrders.nativeElement).modal('show');
    this.child.ngOnInit();
  }

  autocompleteServiceOrders = (data: any): SafeHtml => {
    let html = `<span>${data.itemName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }


}
