import { Component, OnInit,TemplateRef, ViewChild, ElementRef,Output } from '@angular/core';
import { BsDatepickerDirective, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { IMASTServices } from '../services';
import { ReqEntitySearchModel } from '../resources/app-resource/ReqEntitySearchModel.resource';
import { ReqEntityMast } from '../resources/app-resource/ReqEntityMast.resource';
import { ReqEntityTags } from '../resources/app-resource/ReqEntityTags.resource';
import { ReqRequestView } from '../resources/app-resource/ReqRequestView.resource';
import { ReqEntitySchedule } from '../resources/app-resource/ReqEntitySchedule.resource';
import { ReqManageScheduleModel } from '../resources/app-resource/ReqManageScheduleModel.resource';
import { ReqManageSlotModel } from '../resources/app-resource/ReqManageSlotModel.resource';

import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $;

@Component({
  selector: 'app-req-entities-mast',
  templateUrl: './app-req-entities-mast.component.html',
  styleUrls: ['./app-req-entities-mast.component.scss']
})

export class AppReqEntitiesMastComponent implements OnInit {

  public isEnable:boolean =false;  
  error:any;
  listReqEntityMast: any;
  listReqEntityTags: any;
  listReqEntityTagsWithId: any;
  listReqTags:any
  listReqItemMast: any;
  listReqSlotMst: any;
  ListEntitySchedule:any;
  tagDesc:any;
  
  //variables for the pagination
  currentPage = 1;
  page: number;
  currentPageTag = 1;
  pageTag:number;
 
  pageSchedule:number;

  reqEntityMast: ReqEntityMast = new ReqEntityMast();
  reqEntityTags: ReqEntityTags = new ReqEntityTags();
  reqManageSchedule: ReqManageScheduleModel = new ReqManageScheduleModel();
  reqManageSlotModel:ReqManageSlotModel=new ReqManageSlotModel();

  @ViewChild('addEntityModal', { static: false }) addEntityModal: ElementRef;
  @ViewChild('addTagModal', { static: false }) addTagModal: ElementRef;
  @ViewChild('addScheduleModal', { static: false }) addScheduleModal: ElementRef;
  @ViewChild('addSlotModal', { static: false }) addSlotModal: ElementRef;

  scheduleDays: any[] = [{ day: 'Mon', checked: false }, { day: 'Tue', checked: false }, { day: 'Wed', checked: false }, { day: 'Thu', checked: false }, { day: 'Fri', checked: false }, { day: 'Sat', checked: false }, { day: 'Sun', checked: false },];


  constructor( private imastservices: IMASTServices, public datepipe: DatePipe, public _bsDatepickerConfig: BsDatepickerConfig) { }
  ngOnInit() {
    this.getEntitiyMast();
  }

  dateFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd');
  }

  getEntitiyMast() {
    this.imastservices.listReqEntityMast()
      .subscribe((response) => {
        this.listReqEntityMast = response;
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  getReqItemMast() {
    this.imastservices.listReqItemMast()
      .subscribe((response) => {
        this.listReqItemMast = response;
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  addEntity() {
    this.getReqItemMast();
    $(this.addEntityModal.nativeElement).modal('show');
  }

  closeModal() {
    $(this.addEntityModal.nativeElement).modal('hide');
    this.reqEntityMast = new ReqEntityMast();
    this.error = null;
  }

  editReqEntityMast(reqEntity) {
    this.getReqItemMast();
    this.reqEntityMast = reqEntity;
    $(this.addEntityModal.nativeElement).modal('show');
  }

  createReqEntityMast() {
    if (this.reqEntityMast.itemId == undefined || this.reqEntityMast.itemId == null) {
      return this.error = "Please Select Item from List ";
    }
    if (this.reqEntityMast.entityDesc == undefined || this.reqEntityMast.entityDesc == "") {
      return this.error = "Please Enter Entity Description";
    }
    if (this.reqEntityMast.entityName == undefined || this.reqEntityMast.entityName == "") {
      return this.error = "Please Enter Entity Name";
    }
    if (this.reqEntityMast.entityNameNl == undefined || this.reqEntityMast.entityNameNl == null) {
      return this.error = "Please Enter Entity Name_Nl";
    }
    if (this.reqEntityMast.entityStatus == undefined || this.reqEntityMast.entityStatus == null) {
      return this.error = "Please Select Entity Status";
    }
    this.reqEntityMast.serviceType = 1;
    if (this.reqEntityMast.afiVersion) {
      this.imastservices.editReqEntityMast(this.reqEntityMast)
        .subscribe((response) => {
          if (response) {
            this.getEntitiyMast();
            this.error = "Entity Updated Successfully";
            //this.closeModal();
            $(this.addEntityModal.nativeElement).modal('hide');
          }
        }, err => {
          this.isEnable = false;
          this.error = err;
        });
    }
    else {
      this.imastservices.createReqEntityMast(this.reqEntityMast)
        .subscribe((response) => {
          if (response) {
            this.getEntitiyMast();
            this.error = "Entity Create Successfully";
            $(this.addEntityModal.nativeElement).modal('hide');
          }
        }, err => {
          this.isEnable = false;
          this.error = err;
        });
    }

  }
  /* Entity Tag Module */
  addReqEntityTag(reqEntityTag) {
    if (reqEntityTag) {
      this.reqEntityTags.entityCode = reqEntityTag.entityCode;
      this.reqEntityMast.entityName = reqEntityTag.entityName;
      $(this.addTagModal.nativeElement).modal('show');
      this.getListReqTags();
      this.listEntityTag(reqEntityTag.entityCode);
    }
  }

  listEntityTag(entityCode) {
    this.imastservices.listReqEntityTags()
      .subscribe((response) => {
        if (response) {
          this.listReqEntityTags = response.filter(response => response.entityCode === entityCode);
        }
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  getListReqTags() {
    this.imastservices.listReqTags()
      .subscribe((response) => {
        if (response) {
          this.listReqTags = response;
        }
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  closeTagModal() {
    $(this.addTagModal.nativeElement).modal('hide');
    this.reqEntityTags = new ReqEntityTags();
    this.reqEntityMast = new ReqEntityMast();
    this.error = null;
  }

  createReqEntityTag() {

    if (this.reqEntityTags.tagDesc =="- ALL-") {
      return this.error = "Please Select Tag Desciption";
    }
    if (this.reqEntityTags.tagDesc ==undefined) {
      return this.error = "Please Enter Tag Desciption";
    }
    this.isEnable = true;
    //this.reqEntityTags.tagDesc = this.tagDesc;
    if (this.reqEntityTags.afiVersion) {
      this.imastservices.editReqEntityTags(this.reqEntityTags)
        .subscribe((response) => {
          if (response) {
            this.error = "Entity Tag Updated Successfully !";
            this.getListReqTags();
            this.listEntityTag(response.entityCode);            
            this.isEnable = false;
            this.reqEntityTags=new ReqEntityTags();
            $(this.addTagModal.nativeElement).modal('hide');
            this.error="";
          }
        }, err => {
          this.isEnable = false;
          this.error = err;
        });
    }
    else {
      this.imastservices.createReqEntityTags(this.reqEntityTags)
        .subscribe((response) => {
          if (response) {
            this.error = "Entity Tag Created Successfully !";
            this.getListReqTags();
            this.listEntityTag(response.entityCode);            
            this.isEnable = false;
            this.reqEntityTags=new ReqEntityTags();
            $(this.addTagModal.nativeElement).modal('hide');
            this.error="";
          }
        }, err => {
          this.isEnable = false;
          this.error = err;
        });
    }

  }

  editReqEntityTagEvent(editTag) {
    if (editTag) {
      this.reqEntityTags = editTag;
      //this.tagDesc = editTag.tagDesc;
    }
  }

  deleteReqEntityTag(entityTag) {
    if (entityTag) {
      Swal.fire({
        title: 'Do you want to Delete Tag ?',
        text: entityTag.tagDesc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'No..',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.imastservices.deleteReqEntityTags(entityTag.tagId)
            .subscribe((response) => {
              if (response == 0) {
                this.getListReqTags();
                this.listEntityTag(entityTag.entityCode);
                this.error = "Entity Tag Deleted Successfully !";
                this.reqEntityTags=new ReqEntityTags();
              }
              else {
                this.error = "Entity Tag Not Deleted !";
              }
            }, err => {
              this.isEnable = false;
              this.error = err;
            });
        }
      })
    }
  }

  /* Creating of Schedule Module */
  listReqSlotMast() {
    this.imastservices.listReqSlotMast()
      .subscribe((response) => {
        if (response) {
          this.listReqSlotMst = response;
        }
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  getListEntitySchedule(entityCode) {
    this.imastservices.getListEntitySchedule(entityCode)
      .subscribe((response) => {
        if (response) {
          this.ListEntitySchedule = response;
        }
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  onSelectScheduleDays() {
    const str = [];
    const selectedSchedule = this.scheduleDays.filter((x) => x.checked);
    for (let i of selectedSchedule) {
      str.push(i.day)
    }
    this.reqManageSchedule.dayStr = str.toString();
  }

  reqEntityScheduleModal(reqEntitySchedule) {
    if (reqEntitySchedule) {
      
      this.reqEntityTags.entityCode = reqEntitySchedule.entityCode;
      this.reqEntityMast.entityName = reqEntitySchedule.entityName;
      this.getListEntitySchedule(this.reqEntityTags.entityCode);
      $(this.addScheduleModal.nativeElement).modal('show');
      this.scheduleDays = this.scheduleDays;
      this.listReqSlotMast();
      this.resetScheduleChecklist();
    }
  }

  closeScheduleModal() {
    $(this.addScheduleModal.nativeElement).modal('hide');
    this.reqManageSchedule = new ReqManageScheduleModel();
    this.error = null;
    this.resetScheduleChecklist();
  }

  resetScheduleChecklist() {
    for (let s of this.scheduleDays) {
      if (s.checked == true) {
        s.checked = false;
      }
    }
  }

  createReqEntitySchedule() {
    if (this.reqManageSchedule.slotMastId == undefined || this.reqManageSchedule.slotMastId == null) {
      return this.error = "Please Select Schedule Slot";
    }
    if (this.reqManageSchedule.startDate == undefined || this.reqManageSchedule.startDate == null) {
      return this.error = "Please Select Start Date";
    }
    if (this.reqManageSchedule.endDate == undefined || this.reqManageSchedule.endDate == null) {
      return this.error = "Please Select End Date";
    }
    if (this.reqManageSchedule.startDate) {
      this.reqManageSchedule.startDate = this.dateFormat(this.reqManageSchedule.startDate);
    }
    if (this.reqManageSchedule.endDate) {
      this.reqManageSchedule.endDate = this.dateFormat(this.reqManageSchedule.endDate);
    }
    this.reqManageSchedule.entityCode = this.reqEntityTags.entityCode;
    this.imastservices.createEntitySchedule(this.reqManageSchedule)
      .subscribe((response) => {       
        if (response) {
          this.error = "Entity Schedule Create Successfully";
          this.getListEntitySchedule(this.reqEntityTags.entityCode);
          this.isEnable = false;
          this.reqManageSchedule = new ReqManageScheduleModel();
        }
      }, err => {
        this.isEnable = false;
        this.error = err;
      });
  }

  deleteReqEntitySchedule(scheduleItem) {
    if (scheduleItem) {
     
      this.reqManageSchedule.startDate=scheduleItem.scheduleDate.split('T')[0];
      this.reqManageSchedule.endDate=scheduleItem.scheduleDate.split('T')[0];
      this.reqManageSchedule.entityCode=scheduleItem.entityCode;
      this.reqManageSchedule.slotMastId=scheduleItem.slotMastId;
      this.imastservices.removeEntitySchedule(this.reqManageSchedule)
        .subscribe((response) => {
          if (response) {
            this.error = 'Schedule Record Deleted Successfully !';
            this.getListEntitySchedule(this.reqEntityTags.entityCode);
            this.reqManageSchedule=new ReqManageScheduleModel();
          }
        }, err => {
          this.isEnable = false;
          this.error = err;
        });
    }
  }
  /* Entity Slot Module */

  reqEntitySlotModal(reqEntitySlot){
    if (reqEntitySlot) {
      this.listReqSlotMast();
      this.reqEntityTags.entityCode = reqEntitySlot.entityCode;
      this.reqEntityMast.entityName = reqEntitySlot.entityName;
      $(this.addSlotModal.nativeElement).modal('show');
     
    }
  }

  closeSlotModal(){
    $(this.addSlotModal.nativeElement).modal('hide');
    this.reqManageSchedule = new ReqManageScheduleModel();
    this.error = null;
    
  }

  createReqEntitySlot(){
    
  }

  formatDate(date: Date): string {
    // console.log(date)
    if (date) {
      return ("" + date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate())

    } else {
      return undefined;
    }
  }

  getDate(date): Date {
    // console.log(date)
    if (date !== undefined) {
      var dateParts = date.split("-");
      // console.log(dateParts)
      return new Date(Date.parse(date)); // month is 0-based
    }
  }

}
