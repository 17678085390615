
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MstPolicyMast } from '../resources/MstPolicyMast.resource';
import { ICAREServices } from '../services';
import { MstPoilicyMastExt } from '../resources/ext/MstPoilicymastExt';
import { MstSchemeMast } from '../resources/MstSchemeMast.resource';
import swal from 'sweetalert2';

@Component({
  selector: 'app-mast-policy',
  templateUrl: './app-mast-policy.html'
  //  , styleUrls: ['./app-mast-policy.component.css']
})
export class PolicyMasterComponent implements OnInit {

  addNewPolicy: boolean;
  editPolicy: boolean;

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  constructor(private icareservices: ICAREServices,private toastr: ToastrService) { }

  modelColl: MstPolicyMast[] = [];
  modelCollExt: MstPoilicyMastExt[] = [];
  modelItem: MstPolicyMast = new MstPolicyMast();
  modelItemExt: MstPoilicyMastExt = new MstPoilicyMastExt();
  schemeItem: MstSchemeMast = new MstSchemeMast();
  schemeColl: any;
  retModelColl: any;
  //policyTypeColl:[{1,'INSURANCE'},{0,'UNKNOWN'},{2,'CORPORATE'}]


  private retrieve() {
    this.icareservices.listMstSchemeMast(this.schemeItem).subscribe(response => {
      this.schemeColl = response;     
      this.listPolicy();
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  private listPolicy() {
    this.icareservices.listMstPolicyMast().subscribe(response => {
      this.retModelColl = response;
      this.modelCollExt = this.retModelColl;
      for (let i in this.retModelColl) {
        this.modelCollExt[i].schemeName = this.setSchemeName(this.retModelColl[i].schemeCode);
        this.modelCollExt[i].policyTypeName = this.setPolicyType(this.retModelColl[i].policyType);
      }

    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

  };


  saveInfo() {

    if (isNaN(this.modelItem.policyCode)) {
      this.icareservices.createMstPolicyMast(this.modelItem)
        .subscribe((response) => {
          this.retModelColl = response;
          this.listPolicy();
          this.toastr.success('Policy created successfully !', 'Save !');
          //swal("Save", 'Policy created successfully !', 'success');          
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.icareservices.editMstPolicyMast(this.modelItem)
      .subscribe((response) => {
        this.retModelColl = response;
        this.listPolicy();
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
      this.toastr.success('Policy Updated successfully !', 'Save !');
      //swal("Save", 'Policy Updated successfully !', 'success');    
    }
    this.clearModel();
  }
  private setSchemeName(schCode) {
    return this.schemeColl.filter((item: MstSchemeMast) => item.schemeCode === schCode)[0].schemeDesc;
  }
  private setPolicyType(typeCode) {
    if (typeCode === 0) {
      return 'UNKNOWN';
    }
    else if (typeCode === 1) {
      return 'INSURANCE';
    }
    else if (typeCode === 2) {
      return 'CORPORATE';
    }

  }
  removeItem(data) {

    if (confirm("Are you sure to delete the clinic : " + data.policyCode + "?")) {
      this.icareservices.deleteMstPolicyMast(data.policyCode).subscribe
        ((response) => {
          this.listPolicy();
          this.toastr.success(data.policyCode + 'Policy deleted successfully !', 'Save !');
          //swal("Save", data.policyCode + 'Policy deleted successfully !', 'success');              
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }

  };

  editItem(data) {
    this.addNewPolicy=true;
    this.modelItem.policyCode = data.policyCode;
    this.modelItem.policyName = data.policyName;
    this.modelItem.activeYn = data.activeYn;
    this.modelItem.policyCompanyEstCode = data.policyCompanyEstCode;
    this.modelItem.paymentFlag = data.paymentFlag;
    this.modelItem.policyType = data.policyType;
    this.modelItem.shortCode = data.shortCode;
    this.modelItem.schemeCode = data.schemeCode;
    this.modelItem.afiVersion = data.afiVersion;

  };

  clearModel() {
    this.modelItem = new MstPolicyMast();
    this.modelItemExt = new MstPoilicyMastExt();
    this.addNewPolicy=false;
  };

  newPolicyMast(){
    this.addNewPolicy=true;
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printSectionDisplay').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
     <html>
         <head>
             <title></title>
             <style>

             #counter {
              font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              width: 100%;
          }
          
          #counter td, #counter th {
              border: 1px solid #ddd;
              padding: 8px;
          }
           #counter th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              color: #7a4c41;
          }

          
             </style>
         </head>
         <body onload="window.print();window.close()">

         <h1><center>Display Master Details</center></h1>
         
         ${printContents}
        
         </body>
     </html>`
    );
    popupWin.document.close();

    //window.print();
  };

  tableToExcel(table, name) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }


  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("deptMast");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("TR");
      for (i = 1; i < (rows.length - 1); i++) {

        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

}


