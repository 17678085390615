import { Component, OnInit } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import { VersionCheckService } from 'build/version-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LoginComponent,VersionCheckService]
})

export class AppComponent implements OnInit {

	public authenticated: boolean;

	constructor(private router: Router, private _loginComponent: LoginComponent,private versionCheckService:VersionCheckService) {

		router.events.subscribe(e => {
			if (e instanceof NavigationStart) {
				if (e.url === '/login') {
					console.log(_loginComponent.isAuthenticated());
					if (!_loginComponent.isAuthenticated()) {
						this.authenticated = false;
					} else {
						this.authenticated = true;
						router.navigate(['/home']);
					}
				} if (e.url === '/home') {
					console.log(_loginComponent.isAuthenticated());
					if (!_loginComponent.isAuthenticated()) {
						this.authenticated = false;
						router.navigate(['/login']);
					} else {
						this.authenticated = true;
					}
				}
			}
		});
	}
	ngOnInit() {

		//this.versionCheckService.initVersionCheck(environment.versionCheckURL);
		//console.log(this.versionCheckService.initVersionCheck(environment.versionCheckURL))

		if (!this._loginComponent.isAuthenticated()) {
			this.authenticated = false;
		} else {
			this.authenticated = true;
		}
	}

	logout() {
		this.authenticated = false;
		this._loginComponent.logout();
	}
	isAuthenticated() {
		if (!this._loginComponent.isAuthenticated()) {
			this.authenticated = false;
		} else {
			this.authenticated = true;
		}
		return this.authenticated;
	}
}
