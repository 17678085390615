import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

import { ICAREServices } from '../services/Icare.Services';

@Component({
    selector: 'app-his-visit-bar',
    templateUrl: './app-his-visit-bar.html',
    styleUrls: ['./app-his-visit-bar.css']

})

export class VisitBarComponent implements OnInit {
    @Input()
    public visitId: number;
    public patientItem: any[];

    constructor(private icareservices: ICAREServices, public datepipe: DatePipe,private toastr: ToastrService) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.patientItem = [];
        this.searchVisit();
    }

    /* search patient list */
    searchVisit() {
        if (this.visitId) {
            this.icareservices.getClcVisitView(this.visitId)
                .subscribe((response) => {
                    this.patientItem = response;
                }, err => {
                    this.toastr.warning( err.split(":")[3],'Alert !');           
                  });
        }

    }

    formatDate(birthDate) {

        let dt = new Date(birthDate);
    
        let day;
        if (dt.getDate() < 10) {
          day = '0' + dt.getDate();
        } else {
          day = dt.getDate();
        }
    
        let month = dt.getMonth() + 1;
    
        let year = (dt.getFullYear() + '').substr(2, 2);
    
        let hours = dt.getHours();
    
        let minutes = dt.getMinutes();

        return day + '/' + month + '/' + year +'Y';
      }

      getAge(dateString) {

        var birthdate = new Date(dateString).getTime();
        var now = new Date().getTime();
        // now find the difference between now and the birthdate
        var n = (now - birthdate)/1000;
      
        if (n < 604800) { // less than a week
          var day_n = Math.floor(n/86400);
          return day_n + ' D' + (day_n > 1 ? 's' : '');
        } else if (n < 2629743) {  // less than a month
          var week_n = Math.floor(n/604800);
          return week_n + ' W' + (week_n > 1 ? 's' : '');
        } else if (n < 63113852) { // less than 24 months
          var month_n = Math.floor(n/2629743);
          return month_n + ' M' + (month_n > 1 ? 's' : '');
        } else { 
          var year_n = Math.floor(n/31556926);
          return year_n + ' Y' + (year_n > 1 ? 's' : '');
        }
      }
}

