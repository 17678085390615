import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';

declare var $;

@Component({
  selector: 'app-his-nurse-desk',
  templateUrl: './app-his-nurse-desk.html',
  styleUrls: ['./app-his-nurse-desk.css']
})
export class NursingDeskComponent implements OnInit {

  bsValue = new Date();
  private birthDate;
  selectedRow: Number;
  listVisitItem: any;
  listClinics: any;
  public visitId:string; //number;
  public notesTypeCode=2;//:number;
  public submitted: boolean = false;

  visitModel: ClcVisitQueryVO = new ClcVisitQueryVO();

  @ViewChild('visitSearchModal', { static: true }) visitSearchModal: ElementRef;

  constructor(private icareservices: ICAREServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.emoji();
    this.searchPatientList();

    this.icareservices.listClinics().subscribe(response => {
      this.listClinics = response;
    }, err => {
      this.toastr.warning( err.split(":")[3],'Alert !');           
    });
  }

  onKeyPress(visitId) {
    this.visitIdChanged();
    
  }

    /* search patient list */
    visitIdChanged() {

     /*  if (this.visitModel.patientNo) {
        this.visitId = this.visitModel.patientNo//visitId;
        this.notesTypeCode=2;
      } */
     // else {
        this.submitted = true;
        $(this.visitSearchModal.nativeElement).modal('show');
          this.notesTypeCode=2;
      //}

    }

    selectedVisit(visitColl) {
      this.selectedRow = visitColl;
    }
  
    selectedVisitLisitRow(visitColl) {
  
      if (visitColl.visitId) {
        this.visitId = visitColl.visitId;
        this.submitted = false;
        $(this.visitSearchModal.nativeElement).modal('hide');
      }
    }

    searchPatientList() {
         if (this.visitModel.visitFromDate) {      
          this.icareservices.listClcVisitView(this.visitModel)
            .subscribe(response => {
              this.listVisitItem = response;              
            }, err => {
              this.toastr.warning( err.split(":")[3],'Alert !');           
            });
        }
        else {
          var today = new Date();
          var yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
          this.visitModel.visitFromDate = yesterday;
          this.icareservices.listClcVisitView(this.visitModel)
            .subscribe(response => {
              this.listVisitItem = response;
            }, err => {
              this.toastr.warning( err.split(":")[3],'Alert !');           
            });
        }
     }
   
     clearVisitList(){
       this.visitModel=new ClcVisitQueryVO();
     }

     emoji(){
      const $canvas = $('body');
      const $eyes = $('.eye');
      const $rateInputs = $('.rate-input');
      
      
      function vendorize(key,value){
        const vendors = ['webkit','moz','ms','o',''];
        var result = {};
        
        vendors.map((vendor)=>{
          const vKey = vendor ? '-'+vendor+'-'+key : key;
          
          result[vKey] = value;
        });
        
        return result;
      }
      
      //https://github.com/jfmdev/jqEye/blob/master/Source/jqeye.js
      function circle_position(x, y, r) {
        // Circle: x^2 + y^2 = r^2
        var res = {x: x, y: y};
        if(x*x + y*y > r*r) {
          if(x !== 0) {
            var m = y/x;
            res.x = Math.sqrt(r*r / (m*m + 1));
            res.x = (x > 0)? res.x : -res.x;
            res.y = Math.abs(m * res.x);
            res.y = (y > 0)? res.y : -res.y;
          } else {
            res.y = y > 0? r : -r;
          }
        }
        return res;
      };
      
      function findCenter(coords, sizeX, sizeY){
        return {
          x: coords.left + sizeX/2,
          y: coords.top + sizeY/2
        }
      }
      
      function changeEyesPosition(px, py){
        function changePosition(){
          const $t = $(this);
          const $pupil = $t.find('.pupil');
          const t_w = $t.width();
          const t_h = $t.height();
          const t_o = $t.offset();
          const t_p = $t.position();
          const abs_center = findCenter(t_o, t_w, t_h);
          const pos_x = px - abs_center.x + $(window).scrollLeft();
          const pos_y = py - abs_center.y + $(window).scrollTop();
          const cir = circle_position(pos_x, pos_y, t_w/20);
          const styles = vendorize('transform', 'translateX('+cir.x+'px) translateY('+cir.y+'px)');
      
          $pupil.css(styles);
        }
        
        $eyes.each(changePosition);
      }
      
      function handleMouseMove(e){
        const px = e.pageX,
              py = e.pageY;
        
        changeEyesPosition(px,py);
      }
      
      $canvas.on('mousemove', handleMouseMove);
      
      
      function getFace($element){
        return $element.parent('.face-wrapper').find('.face');
      }
      
      
      function handleFaceHover($face){
        const $hint = $('.faces-hint');
        const hintText = $face.attr('data-hint') || $hint.attr('data-default-hint');
        $hint.text(hintText);
      }
      
      
      function handleFacesHover(e){
        const $face = getFace($(e.target));
        
        handleFaceHover($face); 
      }
      
      $('.feedback-faces').on('mousemove', handleFacesHover);
      
      
      
      function handleFeedbackTitleHover(e){
        const isHover = e.type === 'mouseenter';
        $(this).parent().toggleClass('title-hovered', isHover);
      }
      
      $('.feedback-title').on('mouseenter mouseleave', handleFeedbackTitleHover);
      
      
      function handleFeedbackToggle(){
        const $this = $(this),
              $parent = $this.parent();
        
        $parent.toggleClass('at-bottom');
        
        $parent.find('.face-wrapper').each(function(index) {
          setTimeout(function(face) {
            face.toggleClass('slide-out-y-alt', $parent.hasClass('at-bottom'));
          }, index * 60, $(this));
        });
      }
      $('.feedback-title').on('click', handleFeedbackToggle);
      
      
      
      function handleRateInputChange(){
        const rating = parseInt($(this).val());
        
        getFace($rateInputs).addClass('grayscale');
        getFace($(this)).removeClass('grayscale');
      }
      
      $rateInputs.on('change', handleRateInputChange);
      
      
      
      //Firebase stuff
      
      function setCounter(stats){
        const $counters = $('.face-counter');
        
        function setTitle($counter, size){
          var titleType = '',
              titlePrefix = '';
          if(size === 0){
            titleType = 'none';
          } else if(size === 1){
            titleType = 'one';
          } else {
            titleType = 'many';
            titlePrefix = `${size} `;
          }
          
          $counter.attr({
            'title' : titlePrefix + $counter.attr(`data-title-${titleType}`)
          });
        }
        
        $counters.each((index) => {
          const $counter = $counters.eq(index),
                size = stats[index] || 0;
          
          $counter.text(size);
          setTitle($counter, size);
          $counter.removeClass('invisible');
        });
        
      }
    
     }
}
