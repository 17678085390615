export class MstSchemeMast{
 schemeDesc: string;
 shortCode: string;
 activeYn: string;
 schemeCode: number;
 estCode: number;
 afiVersion: number;
 public constructor() {}
}
	
  
