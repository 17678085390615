import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';

import { ClcTriageDetailView } from '../resources/ClcTriageDetailView.resource';
import {ClcExamTranDetl} from '../resources/ClcExamTranDetl.resource';
import { ClcExamTranCollView } from '../resources/ClcExamTranCollView.resource';

@Component({
  selector: 'app-his-opnotes',
  templateUrl: './app-his-opnotes.component.html',
  styleUrls: ['./app-his-opnotes.component.css']

})

export class OpNotesReportComponent implements OnInit {
  @Input()
  public visitId: number;
  public persItem: any;
  public entryBy;
  private userColl=this._service.persInfo;
  public examTemplate:any=[];
  listTemp: any=[];
  listColl: any[];
  vitalColl: any[];
  listIcdItem: any[];
  listVisitPres: any[];
  listServiceColl: any[];
  listAllergyItem: any[];
  printDate: any;

  triCollItem:any=[]; //: ClcTriageDetailView = new ClcTriageDetailView();
  clcExamTranCollView:ClcExamTranCollView = new ClcExamTranCollView();
  examTranDetail:ClcExamTranDetl=new ClcExamTranDetl();

  constructor(private icareservices: ICAREServices, public datepipe: DatePipe, private _service: ErpServices,private toastr: ToastrService) {
    // this.retrieve();
  }

  ngOnInit() {
    this.retrieve();
    this.printDate = this.formatDate();
  }

  ngOnChanges() {
    this.examTranDetail = new ClcExamTranDetl();
    this.clcExamTranCollView = new ClcExamTranCollView();
    this.listTemp =[];
    this.listColl=[];
    
    this.retrieve();
  }

  private retrieve() {
    this.examTranDetail = new ClcExamTranDetl();
    this.clcExamTranCollView = new ClcExamTranCollView();
    this.listTemp =[];
    this.listColl=[];
 

    this.persItem = [];
    this.triCollItem=[];
    this.searchVisit();
    this.getVisitVitalSigns();
    this.listVisitIcdTran();
    this.listVisitPrescriptions();
    this.listServiceOrder();
    this.listAllergyTran();
    this.getVisitExamTran();
    this.getVisitTriageDetails();
  }

  /* search patient list */
  searchVisit() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.persItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

    /* vitals  */
  getVisitVitalSigns() {
    if (this.visitId) {
      this.icareservices.listVisitVitalSigns(this.visitId)
        .subscribe((response) => {
          this.vitalColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  listAllergyTran() {
    if (this.visitId) {
      this.icareservices.listVisitAllergyTran(this.visitId)
        .subscribe((response) => {
          this.listAllergyItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  listVisitIcdTran() {
    if (this.visitId) {
      this.icareservices.listVisitIcdTran(this.visitId)
        .subscribe((response) => {
          this.listIcdItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  listVisitPrescriptions() {
    if (this.visitId) {
      this.icareservices.listVisitPrescriptions(this.visitId)
        .subscribe((response) => {
          this.listVisitPres = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  listServiceOrder() {
    if (this.visitId) {
      this.icareservices.listVisitServiceOrders(this.visitId)
        .subscribe((response) => {
          this.listServiceColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

    /* Examination */
    getVisitExamTran() {  
      if (this.visitId) { 
        this.getExamtemplate();

        this.icareservices.listVisitExamTran(this.visitId)
          .subscribe((response) => {
            this.listColl=response;
           
            this.listColl.forEach(element => {
              if (this.userColl.persCode == element.createdBy) {
                this.entryBy =  this.userColl.printName;
                }
            }, err => {
              this.toastr.warning(err.split(":")[3], 'Alert !');
          });
  
            if (this.listColl.length>0) {
              for(let rec of response){
                this.clcExamTranCollView=rec;
                //merging the exam template array and the transaction array into one
                const result = this.examTemplate.map(val => {
                  return Object.assign({}, val, this.clcExamTranCollView.clcExamTranDetl.filter(v => v.templateId === val.templateId)[0]);
                });
                for (let combined of result){
                  this.listTemp.push(combined);
                }
              }    
              this.examTemplate=[];    
            }
            
           /*  else{
              this.getExamtemplate();
            } */
          });
      } /* else{
        this.getExamtemplate();
      }  */
    }
  
    getExamtemplate(){
      this.icareservices.listGenExamTemplate()
      .subscribe((response) => {
        if (response) {
          for (let template of response) {
            this.examTranDetail=template;
            this.examTranDetail.examValue="";
           // this.listTemp.push(this.examTranDetail);
            this.examTemplate.push(this.examTranDetail);
          }
        }
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
    }); 
    }

    getVisitTriageDetails(){
      if (this.visitId) {
         this.icareservices.getVisitTriageDetails(this.visitId)
            .subscribe((response) => {
               if(response){
                this.triCollItem = response;
               }
            }, err => {
              this.toastr.warning(err.split(":")[3], 'Alert !');
          }); 
      }
    }


  formatDate() {
    let dt = new Date();
    let day;
    if (dt.getDate() < 10) {
      day = '0' + dt.getDate();
    } else {
      day = dt.getDate();
    }
    let month = dt.getMonth() + 1;
    let year = (dt.getFullYear() + '').substr(2, 2);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  printOpDetail(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printOpdetails').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
           <html>
               <head>
                   <title></title>
                   <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
               <style>
                  img {
                    position: initial;
                    top: 0%;
                    left: 0%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(3%, 0%);
                    padding-top: 0px;
                    margin-bottom: 20px;
                    height: 100px;
                    margin-left: 20%;
                }
                body{
                  font-family: monospace; 
               }
               .panel {
                margin-bottom: 5px!important;
                }
                .panel-body {
                  padding: 5px;
              }
               </style>
                   </head>
               <body onload="window.print();window.close()">
      
          
               ${printContents}
              
               </body>
           </html>`
    );
    popupWin.document.close();
  };

}
