import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler } from '@angular/common/http';
import * as AppUtils from 'src/app/common/app.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';

import { LoginCredential } from '../resources/LoginCredential.resource';

@Injectable()
 
 export class CVTServices  {
	
	private AFFINITY_URL_BASE= AppUtils.BACKEND_API_URL ;

	public headerData;
	constructor(private http: HttpClient) { }

	private handleError(error: any): Promise<any> {
	 console.error('Could not complete requested service operation', error);
	 return Promise.reject(error.message || error);
	}
	
//integer : Integer
public resetPwd( persCode : number) :Observable<any> {

    return this.http.get<any>(`${this.AFFINITY_URL_BASE}resetPwd?persCode=${persCode} `  )  
    .pipe(map(res => {
      return res;
    }));
  }

//integer : Integer
public activateUser( persCode : number) :Observable<any> {

    return this.http.get<any>(`${this.AFFINITY_URL_BASE}activateUser?persCode=${persCode} `  )  
    .pipe(map(res => {
      return res;
    }));
  }

//integer : Integer
public inactivateUser( persCode : number) :Observable<any> {

    return this.http.get<any>(`${this.AFFINITY_URL_BASE}inactivateUser?persCode=${persCode} `  )  
    .pipe(map(res => {
      return res;
    }));
  }

//string : String
public createUser( userName : string) :Observable<any> {

    return this.http.get<any>(`${this.AFFINITY_URL_BASE}createUser?userName=${userName} `  )  
    .pipe(map(res => {
      return res;
    }));
  }
 

//logincredential : LoginCredential
public changePwd( logincredential : LoginCredential) :Observable<string> {

    return this.http.post<string>(AppUtils.CHANGE_PASSWORD  ,logincredential)  
    .pipe(map(res => {
            return res;
    }));
  }
  
 
}

  
