import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMASTServices } from '../services';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import * as AppUtils from 'src/app/common/app.utils'; 
import { ReqSlotMast } from '../resources/app-resource/ReqSlotMast.resource';
import { ReqManageSlotModel } from '../resources/app-resource/ReqManageSlotModel.resource';

import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $;

@Component({
  selector: 'app-req-slot-mast',
  templateUrl: './app-req-slot-mast.component.html',
  styleUrls: ['./app-req-slot-mast.component.scss']
})
export class AppReqSlotMastComponent implements OnInit {

 
  public isEnable: boolean = false;
  error: any;
  listReqSlotMst: any;
  listReqSlotDtl:any;
  slotDtlDesc:any;
  listSlicedSlots:any;
  totalSlots: number;
  //variables for the pagination
  currentPage = 1;
  currentPageSlot=1;
  page: number;
  pageSlot :number;

  reqSlotMast:ReqSlotMast=new ReqSlotMast();
  reqManageSlotModel:ReqManageSlotModel=new ReqManageSlotModel();

  @ViewChild('addSlotMastDescModal', { static: false }) addSlotMastDescModal: ElementRef;
  @ViewChild('addSlotModal', { static: false }) addSlotModal: ElementRef;

  constructor(private imastservices: IMASTServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.getListReqSlotMast();
  }

  /* Creating of Slot Module */
  getListReqSlotMast() {
    this.imastservices.listReqSlotMast()
      .subscribe((response) => {
        if (response) {
          this.listReqSlotMst = response;
        }
      }, err => {
        this.isEnable = false; 
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  // addSlotMastDescModal 
  addEntitySlotDescModal(){
    this.error=null;
    $(this.addSlotMastDescModal.nativeElement).modal('show');
  }

  createReqSlotDes(){
    if (this.reqSlotMast.slotMastDesc == undefined || this.reqSlotMast.slotMastDesc == null) {
      return this.error = "Please Enter Slot Desc";
    }
    this.imastservices.createReqSlotMast(this.reqSlotMast)
    .subscribe((response) => {
      if (response) {
        this.getListReqSlotMast();
        this.error = "Slot Create Successfully";
        this.closeSlotDescModal();
      }
    }, err => {
      this.isEnable = false; 
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  closeSlotDescModal(){
    this.reqSlotMast=new ReqSlotMast();
    this.error=null;
    $(this.addSlotMastDescModal.nativeElement).modal('hide');
  }

  // Slot Detail Module start 

  getListReqSlotDetails(slotMastId) {
    this.imastservices.listReqSlotDtl(slotMastId)
      .subscribe((response) => {
        if (response) {
          this.listReqSlotDtl = response;
          this.listSlicedSlots = this.listReqSlotDtl.slice(0,5);
          //pagination
          this.totalSlots = this.listReqSlotDtl.length;
        }
      }, err => {
        this.isEnable = false; 
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  reqAddSlotDtlModal(slotItem) {
    if (slotItem) {
      this.slotDtlDesc=slotItem;
      this.getListReqSlotDetails(slotItem.slotMastId);
      $(this.addSlotModal.nativeElement).modal('show');
      this.error=null;
    }
  }

  createReqEntitySlot(){
    if (this.reqManageSlotModel.duration == undefined || this.reqManageSlotModel.duration == null) {
      return this.error = "Please Enter Slot Duration";
    }
    if (this.reqManageSlotModel.startTime == undefined || this.reqManageSlotModel.startTime == null) {
      return this.error = "Please Enter Slot StartTime";
    }
    if (this.reqManageSlotModel.endTime == undefined || this.reqManageSlotModel.endTime == null) {
      return this.error = "Please  Enter Slot End Time";
    }
    this.reqManageSlotModel.slotType=AppUtils.SLOT_TYPE;
    this.reqManageSlotModel.slotMastId=this.slotDtlDesc.slotMastId;

    this.imastservices.createScheduleSlots(this.reqManageSlotModel)
      .subscribe((response) => {
        if (response) {
          this.error = "Entity Slot Create Successfully";
          this.isEnable = false;
          this.getListReqSlotDetails(this.reqManageSlotModel.slotMastId);
          this.reqManageSlotModel = new ReqManageSlotModel();
        }
      }, err => {
        this.isEnable = false; 
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  }

  closeSlotModal(){
    $(this.addSlotModal.nativeElement).modal('hide');
    this.reqManageSlotModel = new ReqManageSlotModel();
    this.error = null;
    
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.listSlicedSlots = this.listReqSlotDtl.slice(startItem, endItem);
  }

  deleteReqSlotDtl(slotItem){
    if (slotItem) {
      Swal.fire({
        title: 'Do you want to Delete Slot ?',
        //text: entityTag.tagDesc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'No..',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.imastservices.deleteReqSlotDtl(slotItem.slotDtlId)
            .subscribe((response) => {
              if (response == 0) {
                this.getListReqSlotDetails(slotItem.slotMastId);
                this.error = "Slot Deleted Successfully !";
              }
              else {
                this.error = "Slot Not Deleted !";
              }
            }, err => {
              this.isEnable = false; 
              this.toastr.warning(err.split(":")[3], 'Alert !');
            });
        }
      })
    }
  }


}
