import {ClcVisitView} from './ClcVisitView.resource';
import {ClcPatientPolicyCollView} from './ClcPatientPolicyCollView.resource';
import {ClcPatientPackageView} from './ClcPatientPackageView.resource';
import {ClcPatientNextkinView} from './ClcPatientNextkinView.resource';
export class ClcPatientViewColl{
 birthDate: Date;
 identityNo: string;
 printName: string;
 sexCode: string;
 contactDetail: string;
 patientType: string;
 firstName: string;
 secondName: string;
 thirdName: string;
 fourthName: string;
 mobileNo: string;
 phoneHome: string;
 patientNo: string;
 patientPhoto: string;
 countryName: string;
 nationalityDesc: string;
 maritalstatusDesc: string;
 salutationDesc: string;
 genderDesc: string;
 patientId: number;
 nationalityCode: number;
 maritalstatusCode: number;
 registerStatus: number;
 confidentialityLevel: number;
 estCode: number;
 salutationCode: number;
 motherPatientId: number;
 lastFileTranId: number;
 mergedToPatientId: number;
 afiVersion: number;
 villageName:string;
 cityName:string;
 clcVisitView: ClcVisitView[];
 clcPatientPolicyCollView: ClcPatientPolicyCollView[];
 clcPatientPackageView: ClcPatientPackageView[];
 clcPatientNextkinView: ClcPatientNextkinView[];
 public constructor() {}
}
	
  
