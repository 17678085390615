export class MstCountryMast{
 countryName: string;
 isoCode: string;
 nationalityDesc: string;
 countryCode: number;
 currencyCode: number;
 public constructor() {}
}
	
  
