import {MstRelationshipMast} from './MstRelationshipMast.resource';
import {MstPolicyMast} from './MstPolicyMast.resource';
import {ClcPatPolicyCoPayView} from './ClcPatPolicyCoPayView.resource';
export class ClcPatientPolicyCollView{
 expiryDate: Date;
 startDate: Date;
 identityCode: string;
 insuredName: string;
 patPolicyId: number;
 patientId: number;
 policyCode: number;
 insuredPatRelation: number;
 estCode: number;
 afiVersion: number;
 mstRelationshipMast: MstRelationshipMast;
 mstPolicyMast: MstPolicyMast;
 clcPatPolicyCoPayView: ClcPatPolicyCoPayView[];
 public constructor() {}
}
	
  
