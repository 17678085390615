export class ClcVitalSigns{
 vitalDate: Date;
 createdDate: Date;
 vitalRunId: number;
 visitId: number;
 createdBy: number;
 temp: number;
 pulse: number;
 respRate: number;
 bpSys: number;
 bpDias: number;
 height: number;
 weight: number;
 headCircum: number;
 chestCircum: number;
 waistCircum: number;
 fhr: number;
 gcs: number;
 spo2: number;
 pupilsRt: number;
 pupilsLt: number;
 vitalStatus: number;
 sourceTypeCode: number;
 sourceRunId: number;
 estCode: number;
 afiVersion: number;
 grbs: number;
 public constructor() {}
}
	
  
