import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-his-download-prescription',
  templateUrl: './app-his-download-prescription.component.html',
  styleUrls: ['./app-his-download-prescription.component.scss']
})
export class AppHisDownloadPrescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
