import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcIcdTran } from '../resources/ClcIcdTran.resource';
import { ClcIcdMaster } from '../resources/ClcIcdMaster.resource';
 
import swal from 'sweetalert2';
import { isBuffer } from 'util';

@Component({
  selector: 'app-his-icd',
  templateUrl: './app-his-icd.component.html',
  styleUrls: ['./app-his-icd-component.css']

})

export class ICDComponent implements OnInit {
  @Input()
  public visitId: number;
  @Input()
  public editYN:string;
  
  error:any;
  icdDate = new Date();
  icdMstColl: any;
  medicineColl: any;
  courseColl: any;
  listIcdItem: any;
  listserviceItems: any;
  icdDesc: any;
  patientItem: any;
  icdName:any;

  private userColl = this._service.persInfo;
  private filterResult: any;
  public listTemp: any = [];
  radioSelected:string;
  isChecked:boolean;

  listIcdFlag = [{
    name: 'Primary',
    value: 'P'
  }, {
    name: 'Secondary',
    value: 'S'
  }, {
    name: 'Other',
    value: 'O'
  }]

  icdModel: ClcIcdTran = new ClcIcdTran();
  clcIcdMasterModel:ClcIcdMaster=new ClcIcdMaster();
  editIcdModel: ClcIcdTran = new ClcIcdTran();
  editDiagnosisDiv: boolean;
  addDiagnosisDiv: boolean;
  data:any;
  //variables for the pagination
  currentPage = 1;
  page: number;
  
  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices, private _sanitizer: DomSanitizer,private toastr: ToastrService) { }

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  ngOnChanges() {
    this.listVisitIcdTran();
    this.getPatientId();
    this.clearClcIcdTran();
  }

  private retrieve() {

    // this.icareservices.listIcdMaster().subscribe(response => {
    //   this.icdMstColl = response;
    // })
    
    this.icdMstColl = this._service.icdMast;
  }

  listVisitIcdTran() {
    if (this.visitId) {
      this.icareservices.listVisitIcdTran(this.visitId)
        .subscribe((response) => {
          this.listIcdItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  getPatientId() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.patientItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  selectIcd(presColl) {
    this.editIcdModel = presColl;
    if(presColl.confidential=='Y'){
      this.editIcdModel.checked =true;
      this.editIcdModel.confidential=presColl.confidential;
    }
    if(presColl.confidential=='N'){
      this.editIcdModel.checked =false;
      this.editIcdModel.confidential=presColl.confidential;
    }    
    if (presColl.icdFlag) {
      this.radioSelected = presColl.icdFlag;
    }
    if (presColl.icd) {
      this.onChangeICD(presColl.icd);
    }
  }

  onChangeICD(icdCode){   
    if(this.icdMstColl){
      this.icdName=this.icdMstColl.find(item => item.icd === icdCode).disease;
    }    
  }

   /** onchange event **/
   valueChangedOnICDItem(icdCode) {
    if (icdCode.icd) {     
      this.icdModel.icd=icdCode.icd;     
    }
    if(this.editIcdModel){
     this.editIcdModel.icd=icdCode.icd; 
    }
  }

  onFlagChange(flagType) {
    this.filterResult = this.listIcdItem.filter(item => item.icdFlag === flagType.value);
    if (this.filterResult.length > 0) {
      return this.toastr.warning('ICD flag Primary Already exists', 'Alert !');      
      //swal("Alert", 'ICD flag Primary Already exists !', 'warning');
    }
  }

  getICD(icdCode) {
    if (icdCode) {   
      let item = this.icdMstColl.find(item => item.icd === icdCode);      
      if (item) {     
        return this.icdMstColl.find(item => item.icd === icdCode).disease;
      }
    }
  }

  
  onChangeConfidential(event) {
    if (event.target.checked) {
      this.isChecked = event.target.checked;
      this.editIcdModel.confidential='Y';
    }else{
      this.editIcdModel.confidential='N';
    }
  }


  createClcIcdTran() {
    if (this.visitId) {     
      //if (this.icdModel.diagText) {
        this.icdModel.visitId = this.visitId;
        this.icdModel.createdTime = this.icdDate;
        this.icdModel.createdBy = this.userColl.persCode;
        this.icdModel.patientId = this.patientItem.patientId;
        this.icdModel.icdFlag=this.radioSelected;
        if(this.icdModel.checked){
          this.icdModel.confidential='Y';
        }else{
          this.icdModel.confidential='N';
        }
        this.icareservices.createClcIcdTran(this.icdModel)
          .subscribe((response) => {
            this.listVisitIcdTran();
            this.addDiagnosisDiv = false;
            this.clearClcIcdTran ();
            this.toastr.success('Diagnosis Added successfully ', 'Save !');                  
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      // }
      // else {
      //   this.toastr.success('Please Enter Diagnosis ', 'Alert !');
      // }
    }
    else {
      this.toastr.success('Please enter Visit Id ', 'Alert !');
    }

  }

  clearClcIcdTran() {    
    this.icdName=null;
    this.radioSelected=null;
    this.icdModel = new ClcIcdTran(); 
    this.addDiagnosisDiv=false;
    this.isChecked=false;
  }

  clearEditClcIcdTran() {
    this.icdName=null;
    this.editDiagnosisDiv = false;
    this.editIcdModel = new ClcIcdTran();
    this.isChecked=false;
  }


  addNewDiagnosis() {
    this.icdName=null;
    this.radioSelected=null;
    this.icdModel = new ClcIcdTran(); 
    this.editDiagnosisDiv = false;
    this.addDiagnosisDiv = true;

  }
  editDiagnisis() {
    this.icdName=null;
    this.editIcdModel = new ClcIcdTran();
    this.addDiagnosisDiv = false;
    this.editDiagnosisDiv = true;
  }


  editClcIcdTran() {

    if (this.visitId) {
      //if (this.editIcdModel.diagText) {
        this.editIcdModel.visitId = this.visitId;
        this.editIcdModel.createdTime = this.icdDate;
        this.editIcdModel.createdBy = this.userColl.persCode;
        this.editIcdModel.patientId = this.patientItem.patientId;
        this.editIcdModel.afiVersion = this.editIcdModel.afiVersion;
        this.editIcdModel.icdFlag=this.radioSelected;
        if(this.editIcdModel.checked){
          this.editIcdModel.confidential='Y';
        }else{
          this.editIcdModel.confidential='N';
        }
        

        this.icareservices.editClcIcdTran(this.editIcdModel).subscribe((res) => {
          this.listVisitIcdTran();
          this.editDiagnosisDiv = false;
          this.clearClcIcdTran ();
          this.toastr.success('Diagnosis Edited successfully ', 'Edited !');        
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
      // }
      // else {
      //   this.toastr.warning('Please Enter Diagnosis !', 'Alert !');       
      // }
    }
  }

  deleteOrders() {

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"

    }).then((result) => {
      if (result.value) {
        this.icareservices.deleteClcIcdTran(this.editIcdModel.icdTranId)
          .subscribe((response) => {
            this.listVisitIcdTran();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
      }
    });

  }

  /* bind autocomplete list on search of list icd items */
  autocompleteListIcdMst = (data: any): SafeHtml => {
    let html = `<span>${data.disease}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}
