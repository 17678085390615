import { Component, OnInit } from '@angular/core';
import {DomSanitizer } from '@angular/platform-browser';

import { LoginComponent } from '../login/login.component';
import { ErpServices } from '../services/index';


@Component({
	selector: 'app-menus',
	templateUrl: './app-menus.component.html',
	styleUrls: ['./app-menus.component.css'],
	providers: []
})

export class MenuComponent implements OnInit {

    public menus: any;
    public menuURL:any;
    public homeTabSelect: boolean = true;

    public tabs = [
		{ title: "Desktop", url: 'DeskTop', active: true, id: 0, prevId: "", removable: false, customClass: "customClass" }
    ];
    
	constructor(private _service: ErpServices,private sanitizer:DomSanitizer) {
	}

	ngOnInit() {
		this._service.getMenu().subscribe(res => {
            this.menus = res;

            for (let menu of this.menus) {
                this.menuURL=`<`+menu.moduleName  +` `+`*ngIf="tab.url == '`+menu.moduleName+`'"></`+menu.moduleName+`>`; 
            }
            //menu.moduleName
           //<app-desktop *ngIf="tab.url == 'DeskTop'"></app-desktop> 
            //document.getElementById("menuURL").innerHTML = response.htmldata;​
		})
	}

	removeTabHandler(data1) {

		for (let k in this.tabs) {
			if (this.tabs[k].id == data1.id) {
				this.tabs.splice(+k, 1);
			}
		}

	};
    tabSelect(tabData) {
		for (let j in this.tabs) {
			if (tabData.url == "home") {
				this.homeTabSelect = true;
			} else {
				this.homeTabSelect = false;
			}
			if (this.tabs[j].id == tabData.id) {
				this.tabs[j].active = true;
			} else {
				this.tabs[j].active = false;
			}
		}
	}
}

