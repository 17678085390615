import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { ICAREServices } from '../services/Icare.Services';
import { ErpServices } from 'src/app/services/index';
import { IMASTServices } from '../services';

import { BillTranDtl } from '../resources/BillTranDtl.resource';
import { BillRecptPaymtTran } from '../resources/BillRecptPaymtTran.resource';

declare var $;

@Component({
    selector: 'app-his-billing',
    templateUrl: './app-his-billing.component.html',
    styleUrls: ['./app-his-billing.component.css']

})

export class BillingComponent implements OnInit {

    @Input()
    patVisitId: any;
    decimalValue=3;
    //variables for the pagination
    currentPage = 1;
    page: number;
    orderPage:number;
    /* print component */
    public persItem: any = [];

    patColl: any = [];
    visitItem: any[];
    dateNow: Date = new Date();
    public visitId: number;
    public billItemName: any;
    private submitted: boolean = false;

    public btnAddBillingItem: boolean = false;
    public btnEditBillingItem: boolean = false;
    public btnDeleteBillingItem: boolean = false;
    public btnAddRecptItem: boolean = false;
    public btnEditBillingRecptItem: boolean = false;
    public btnDeleteBillingRecptItem: boolean = false;
    public btnAddPaymentItem: boolean = false;
    public btnEditPaymentTranItem: boolean = false;
    public btnDeletePaymentTranItem: boolean = false;
    public btnApproveBill:boolean=false;
    public btnReceiptAmnt: boolean = false;
    
    private userColl = this._service.persInfo;
    public estInfo = this._service.estInfo;
    printDate: any;
    selectedRow: Number;
    billTran: number;
    listBillTran: any=[];
    listBillItemTypes: any;
    listBillItems: any;
    listBillPayMode: any;
    listBillPaymntType:any;
    listBillTranDetail: any = [];
    listBillRecptTranDetail: any[];
    listBillPaymntTranDetail: any[];
    selectedBillRec: any;
    selectedBillTranDtl:any=[];
    paymentType:any;
    tempBillRecptAmt:any;
    listConsultant: any;

    billTranModel: BillTranDtl = new BillTranDtl();
    editBillTranModel: BillTranDtl = new BillTranDtl();
    recpBillTranModel: BillRecptPaymtTran = new BillRecptPaymtTran();

    @ViewChild('visitSearchModal', { static: true }) visitSearchModal: ElementRef;
    @ViewChild('addBillingModal', { static: true }) addBillingModal: ElementRef;
    @ViewChild('editBillingModal', { static: true }) editBillingModal: ElementRef;
    @ViewChild('addBillingRecepitModal', { static: true }) addBillingRecepitModal: ElementRef;
    @ViewChild('editBillingRecepitModal', { static: true }) editBillingRecepitModal: ElementRef;
    @ViewChild('addBillingPaymentModal', { static: true }) addBillingPaymentModal: ElementRef;
    @ViewChild('editBillingPaymentModal', { static: true }) editBillingPaymentModal: ElementRef;
    @ViewChild('ModalPrintBill', { static: true }) ModalPrintBill: ElementRef;
    showAddItem: boolean;
    showEditItem: boolean;
    showAddReceipt: boolean;
    showEditReceipt: boolean;
    showAddPayment: boolean;
    showEditPayment: boolean;

    constructor(private icareservices: ICAREServices,private imastservices: IMASTServices, private _service: ErpServices, public datepipe: DatePipe, private _sanitizer: DomSanitizer,private toastr: ToastrService) {

    }

    ngOnInit() {
        this.printDate = this.formatDate();
        this.listItemTypes();
        if (this.visitId) {
            this.listVisitBillHdrs(this.visitId);
        }
    }

    ngOnChanges() {
        if (this.patVisitId) {
            this.listItemTypes();
            this.visitId=this.patVisitId.visitId;            
            this.persItem=this.patVisitId;
            this.listVisitBillHdrs(this.patVisitId.visitId);
            if(this.patVisitId.billId){
                this.getPatientBillColl(this.patVisitId.billId) //billRunId
            }
            if (this.listBillTranDetail) {
                if(this.listBillTranDetail.balanceAmt>0){
                    this.btnAddRecptItem = true;
                }
                else{
                    this.btnAddRecptItem = false;
                }
            }
        }
    }

    /* List of BillItemTypes */
    listItemTypes() {
        this.itemReset();
        this.icareservices.listBillItemTypeMast().subscribe(response => {
            this.listBillItemTypes = response;
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
        this.icareservices.listBillPayModes().subscribe(response => {
            this.listBillPayMode = response;
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
        this.icareservices.listBillPaymentType().subscribe(response => {
            this.listBillPaymntType = response.filter(item => item.active === "Y"); ;
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
        this.imastservices.listPersClinicView().subscribe(response => {
            this.listConsultant = response;
          }, err => {
            this.toastr.warning(err, 'Alert !');
          });
    }
    
    getbillItemType(itemType){    
        if(itemType){
            if(this.listBillItemTypes.find(item => item.typeId === +itemType)!=undefined){
                return this.listBillItemTypes.find(item => item.typeId === +itemType).typeName;
            }
            return null;
        }        
    }

    /* on Item type selection, List of items will display */
    onChangeselectedItemType(selectedItemType) {        
        this.listBillItems = this._service.billableItems.filter(item => item.itemType === +selectedItemType);             
        // this.billTranModel.itemDesc=null;
        // this.billItemName = null;
        // this.itemInitialize();
    }

   
    itemInitialize() {
        this.billTranModel.itemQty = 1;
        this.billTranModel.deductionAmt = 0;
        this.billTranModel.discountAmt = 0;
    }

    itemReset() {
        this.persItem = [];
        this.listBillTran = [];
        this.listBillTranDetail = [];
        this.listBillRecptTranDetail = [];
        this.listBillPaymntTranDetail = [];
        this.selectedBillRec = [];
        
        this.tempBillRecptAmt=[];
        this.billTranModel = new BillTranDtl();
        this.editBillTranModel = new BillTranDtl();
        this.recpBillTranModel = new BillRecptPaymtTran();
        this.showAddItem=false;
        this.showEditItem=false;
        this.showAddReceipt=false;
        this.showEditReceipt=false;
        this.showAddPayment=false;
        this.showEditPayment=false;
        this.btnReceiptAmnt=false;
    }

    eventFromChild(data) {
        this.itemReset();
        this.persItem=data;
        this.visitId = data.visitId;
        this.listVisitBillHdrs(this.visitId);
        this.submitted = false;
        $(this.visitSearchModal.nativeElement).modal('hide');
    }

    /* on button click getPatientVisitModal open visit search modal */
    getPatientVisitModal() {
        this.submitted = true;
        $(this.visitSearchModal.nativeElement).modal('show');
    }

    /* get patient on button click */
    getPatient(patNo) {
        if (patNo.value) {
            this.itemReset();
            this.icareservices.getPatient(patNo.value).flatMap(response => {
                this.patColl = response;
                return this.icareservices.getLatestVisit(this.patColl.patientId)
            }).subscribe(response => {
                this.persItem = response;
                this.visitId = this.persItem.visitId;
                this.listVisitBillHdrs(this.visitId)
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
        }
        else {
            this.submitted = true;
            $(this.visitSearchModal.nativeElement).modal('show');
        }
    }

    setClickedRow(itemCol) {
        this.selectedRow = itemCol;
        this.selectedBillRec = itemCol.billId;
    }

    onKeydown(event) {
        this.getPatient(event);
    }

    listVisitBillHdrs(visitId) {
        let filterBillItems=[];
        this.icareservices.listVisitBillHdrs(visitId).subscribe((response) => {  
             if(response){
                 filterBillItems=response.find(item => item.status === 0); 
             }                     
            if(response.length==0){ //response.length==0 commented by siraj                
                this.createOPBillHdr();               
            }
            if(filterBillItems){
                this.selectPatientBill(filterBillItems);
                this.listBillTran = response;               
            }
            else{                
                this.listBillTran = response;
                this.listBillTranDetail = [];
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');
            //swal("Alert", err.split(":")[3], 'warning');            
          });
    }

    /* create OP bill tran header on button click */
    // createOPBillHdr() {
    //     this.icareservices.createOPBillHdr(this.visitId).subscribe(response => {
    //         if (response) {               
    //             this.btnEnable();
    //             this.selectedBillTranDtl=response;
    //             this.listVisitBillHdrs(this.visitId);
    //             this.getPatientBillColl(this.selectedBillTranDtl.billId);
    //         }
    //     })
    // }

    getPatientBillColl(billId) {
        this.icareservices.getPatientBill(billId).subscribe(response => {
            this.listBillTranDetail = response;

            if (this.listBillTranDetail.billRecptPaymtTranView) {
                this.listBillRecptTranDetail = this.listBillTranDetail.billRecptPaymtTranView.filter(item => item.tranType == 'R');
            }
            if (this.listBillTranDetail.billRecptPaymtTranView) {
                this.listBillPaymntTranDetail = this.listBillTranDetail.billRecptPaymtTranView.filter(item => item.tranType == 'P');
            }
            
        }, err => {           
            this.toastr.warning( err.split(":")[3],'Alert !');         
          });
    }

    // new API
    createOPBillHdr() {
        this.icareservices.generateOPBill(this.visitId).subscribe(response => {
            if (response) {
                this.btnEnable();
                this.selectedBillTranDtl = response;
                this.listVisitBillHdrs(this.visitId);
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
            }
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }

    /* getPatientBill with respect to billId on selected bill item*/
    selectPatientBill(billTranRec) {
        if (billTranRec.status == 5) {
            this.selectedBillTranDtl = billTranRec;
            this.getPatientBillColl(this.selectedBillTranDtl.billId);
           this.btnDisable();         
           this.toastr.warning('Approved Bill,Unable to Modifed!','Alert !');    
        }
        if (billTranRec.status == -1) {
            this.selectedBillTranDtl = billTranRec;
            this.getPatientBillColl(this.selectedBillTranDtl.billId);
            this.btnDisable();        
            this.toastr.warning('Cancel Bill,Unable to Modifed!','Alert !');    
        }
        if (billTranRec.status == 0 || billTranRec.status == undefined) {
            this.btnEnable();
            this.selectedBillTranDtl = billTranRec;
            this.getPatientBillColl(this.selectedBillTranDtl.billId);
        }

    }

    /* open modal for add billing items */
    addBillingItems() {
        this.showEditItem = false;
        this.showAddItem = true;
        this.itemInitialize();
        this.submitted = true;
       // $(this.addBillingModal.nativeElement).modal('show');
    }

    editBillingItems(itemCol) {

        this.showAddItem = false;
        this.showEditItem = true;       
        this.submitted = true;
      //  $(this.editBillingModal.nativeElement).modal('show');
        this.billTranModel.itemType=itemCol.itemType;
        this.billItemName = itemCol.itemName;
        this.billTranModel.afiVersion = itemCol.afiVersion;
        this.billTranModel.billId = itemCol.billId;
        this.billTranModel.itemId = itemCol.itemId;
        this.billTranModel.runId = itemCol.runId;
        this.billTranModel.itemDesc = itemCol.itemDesc;
        this.billTranModel.netAmt=itemCol.netAmnt;
        this.billTranModel.unitPrice =  itemCol.unitPrice;
        this.billTranModel.itemQty = itemCol.itemQty;
        this.billTranModel.deductionAmt = itemCol.deductionAmt;
        this.billTranModel.taxAmt=itemCol.taxAmt;
        this.billTranModel.taxPerc=itemCol.taxPerc;
        if(itemCol.discountAmt==null){
            this.billTranModel.discountAmt=0;            
        }
        else{
            this.billTranModel.discountAmt = itemCol.discountAmt;
        }       
        this.billTranModel.netAmt = this.currencyFormatter(itemCol.netAmt);
    }

    onChangeDeductionAmt(){
        if(this.billTranModel.deductionAmt>this.billTranModel.unitPrice){
            this.toastr.warning('Deduction Amount Should Not Exceed Rate','Alert !');             
        }
    }

    onChangeDiscountAmt(){
        if(this.billTranModel.discountAmt>this.total){
            this.toastr.warning('Discount Amount Should Not Exceed Net Amount','Alert !');           
        }
    }

    /*onbutton click createBillTranDtl save with respect to bill Id createBillTranDtl*/
    createBillTranDtl() {
        if(this.selectedBillTranDtl.billId){
            this.billTranModel.billId = this.selectedBillTranDtl.billId;//this.selectedBillRec;
            this.billTranModel.netAmt = this.total;
            this.billTranModel.taxAmt=this.taxAmount;
                this.icareservices.createBillTranDtl(this.billTranModel).subscribe((response) => {
                    if (response) {
                        this.showAddItem = false;
                        this.getPatientBillColl(this.selectedBillTranDtl.billId);
                        this.toastr.success('Bill Added successfully ','Save !');                       
                        //swal("Save", 'Bill Added successfully ', 'success');
                        this.billTranModel = new BillTranDtl();
                        this.billItemName = null;
                        this.itemInitialize();
                    }
                }, err => {
                    this.toastr.warning(err.split(":")[3],'Alert !');
                    //swal("Alert", err.split(":")[3], 'warning');            
                  });
        }else{
            
            this.toastr.warning('Please Select Bill And Add Item','Alert !');
            //swal("Alert !", 'Please Select Bill And Add Item', 'warning');
        }
       
    }

    /*onbutton click createBillTranDtl save with respect to bill Id createBillTranDtl*/
    editBillTranDtl() {
        this.billTranModel.netAmt = this.total;
        this.billTranModel.taxAmt=this.taxAmount;
        this.icareservices.editBillTranDtl(this.billTranModel).subscribe((response) => {
            if (response) {

                this.showEditItem = false; 
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
                this.toastr.success('Bill Updated Successfully','Edited !');
                //swal("Edit", 'Bill Updated successfully ', 'success');
                this.billTranModel = new BillTranDtl();
                this.billItemName = null;
                //this.itemInitialize();
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');
            //swal("Alert", err.split(":")[3], 'warning');            
          });
    }

    clearBillTranModal() {
        this.showAddItem = false;
        this.showEditItem = false;
        this.billTranModel = new BillTranDtl();
        this.billItemName = null;
    }

    /* get itemId on value change on selected itemName in autocomplete */
    valueChangedOnItemChange(itemVal) {
        if (itemVal.itemId) {
            this.billTranModel.unitPrice = this.currencyFormatter(itemVal.unitPrice);
            this.billTranModel.itemId=itemVal.itemId;
            this.billTranModel.taxPerc=itemVal.taxPerc;
        }
    }

    /*delete bill tran item detail */
    deleteBillTranDtl(billTranItem) {
        if (this.listBillTranDetail.billTranDtlView.length > 1) {
            swal({
                title: "Are you sure?",
                text: "You will not be able to recover this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!"

            }).then((result) => {
                if (result.value) {
                    this.icareservices.deleteBillTranDtl(billTranItem.runId)
                        .subscribe((response) => {
                            this.getPatientBillColl(this.selectedBillTranDtl.billId);
                        }, err => {
                            this.toastr.warning(err.split(":")[3], 'Alert !');
                        });
                }
            });
        }
        else{
            this.toastr.warning('Unable To Delete Item', 'Alert !');
        }
    }

    /* onChangePaymentType, List of Payment Type will display */
    onChangePaymentType(paymentType) {
       
        if(this.listBillTranDetail.insuranceAmt>0){
            return swal("Alert", "Unable to change Payment Type - Insurance Bill !", 'warning');
        }
        if(this.listBillRecptTranDetail.length>0){
            swal({
                title: "Existing Receipts will be cancelled !",
                text: "Are you sure ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, Cancel it!"
            }).then((result) => {
                if (result.value) {
                    this.paymentType = +paymentType; 
                    this.icareservices.cancelBillPayment(this.selectedBillTranDtl.billId)
                        .subscribe((response) => {
                            this.getPatientBillColl(this.selectedBillTranDtl.billId);
                        }, err => {
                            this.listBillTranDetail.paymentType=this.selectedBillTranDtl.paymentType;
                            this.toastr.info(err.split(":")[3], 'Alert !');
                        });
                }
                else{
                   this.listBillTranDetail.paymentType=this.selectedBillTranDtl.paymentType;
                }
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
        }else{
            this.paymentType= +paymentType;//this.selectedBillTranDtl.paymentType;
        }
    }

    /*getBillDownPayment call function if paymentType=2 , creditId=null , creditAmount=null and creditBalanceAmount=null*/
    getBillDownPayment(billId) {
        this.icareservices.getBillDownPayment(billId).subscribe(response => {
            if (response) {
                let downPayAmt=response;
                this.recpBillTranModel.amount =this.currencyFormatter(downPayAmt);
                this.btnReceiptAmnt=true;
            }
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }

    /* on button click open addBillingRecepitModal this.patientPayable =="0.000"*/
    addReceiptItems() {
       //balanceAmt 
        if(this.listBillTranDetail.balanceAmt == 0){
          return this.toastr.info('Zero Payable Amount,Unable to Create Receipt','Info !')
        }
        this.showEditReceipt = false;
        this.showAddReceipt = true;
        
        if(this.paymentType=='2' && this.selectedBillTranDtl.creditId==null && this.selectedBillTranDtl.creditAmt==null && this.selectedBillTranDtl.balanceAmt==null && this.selectedBillTranDtl.billId){
            
            this.getBillDownPayment(this.selectedBillTranDtl.billId);
        }
        else{
            //this.recpBillTranModel.amount=this.selectedBillTranDtl.payableAmt;//this.patientPayable;
            this.recpBillTranModel.amount=this.listBillTranDetail.payableAmt;
            this.btnReceiptAmnt=false;
        }  
        this.submitted = true;
        //$(this.addBillingRecepitModal.nativeElement).modal('show');
    }

   

    onKeyUpEvent(event: any) {               
        let actualAmt;
        // if(this.recpBillTranModel.amount==null || this.recpBillTranModel.amount==0.000 || this.recpBillTranModel.amount==0){
        //     return this.toastr.warning('Receipt Amount should not be Equal to Zero !','Info !')
        // }
        // if(this.recpBillTranModel.amount>this.patientPayable){
        //     return this.toastr.warning('Receipt Amount should not be Greater than Payable Amount!','Info !')
        // }
        if (this.tempBillRecptAmt.amount) {
            actualAmt == +this.tempBillRecptAmt.amount;
        }
        else{
            actualAmt=+this.patientPayable;
        }
        let currentAmt=+event.target.value;
        if(currentAmt>actualAmt){
            this.toastr.info(this.currencyFormatter(currentAmt) + " OMR is greater than "+ this.currencyFormatter(actualAmt) +"OMR",'Alert !'); 
        }
    }

   
    

    /*onbutton click createBillReceiptTranDtl save with respect to bill Id */
    createBillReceiptTranDtl() {
        if(this.recpBillTranModel.amount==null || this.recpBillTranModel.amount==0.000 || this.recpBillTranModel.amount==0){
            return this.toastr.warning('Receipt Amount should not be Null or Equal to Zero !','Info !')
        }
        if(this.recpBillTranModel.amount>this.selectedBillTranDtl.payableAmt){
            return this.toastr.warning('Receipt Amount should not be Greater than Payable Amount!','Info !')
        }
       if(this.recpBillTranModel.paymentMode){
        this.recpBillTranModel.billId = this.selectedBillTranDtl.billId;
        this.recpBillTranModel.tranDate = new Date();
        this.recpBillTranModel.enteredBy = this.userColl.persCode;
        this.recpBillTranModel.tranType = 'R';
           if (this.paymentType) {
               this.recpBillTranModel.billPaymentType = +this.paymentType;
           }
           else {
               this.recpBillTranModel.billPaymentType = this.selectedBillTranDtl.paymentType;
           }
        this.recpBillTranModel.creditId=this.selectedBillTranDtl.creditId;

        this.icareservices.createBillRecptPaymtTran(this.recpBillTranModel).subscribe(response => {
            if (response) {
                this.showAddReceipt = false;
                this.listVisitBillHdrs(this.visitId);
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
                this.toastr.success('Receipt Added successfully','Save !');               
                this.recpBillTranModel = new BillRecptPaymtTran();
            }
        }, err => {
            this.toastr.warning( err.split(":")[2],'Alert !');           
          });
       }else{
        this.toastr.warning('Please Select Payment Mode','Alert !');        
       }
    }

     /* on button click open addBillingRecepitModal*/
     editBillingRecptPaymntTran(billRecpDetail) {
        this.showAddReceipt = false;
        this.showEditReceipt = true;       
        this.submitted = true;
        this.tempBillRecptAmt=billRecpDetail;
       // $(this.editBillingRecepitModal.nativeElement).modal('show');
        this.recpBillTranModel.runId = billRecpDetail.runId;
        this.recpBillTranModel.afiVersion = billRecpDetail.afiVersion;
        this.recpBillTranModel.tranType = billRecpDetail.tranType;
        this.recpBillTranModel.amount = this.currencyFormatter(billRecpDetail.amount);
        this.recpBillTranModel.remarks = billRecpDetail.remarks;
        this.recpBillTranModel.paymentMode = billRecpDetail.paymentMode;
        
    }

    editBillReceiptTranDtl() {
        if(this.recpBillTranModel.paymentMode){
        this.recpBillTranModel.billId = this.selectedBillTranDtl.billId;
        this.recpBillTranModel.tranType = 'R';
        this.recpBillTranModel.tranDate = new Date();
        this.recpBillTranModel.enteredBy = this.userColl.persCode;
        this.recpBillTranModel.billPaymentType=+this.paymentType;
        this.recpBillTranModel.creditId=this.selectedBillTranDtl.creditId;
        this.icareservices.editBillRecptPaymtTran(this.recpBillTranModel).subscribe(response => {
            if (response) {
                this.showEditReceipt = false;  
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
                this.toastr.success('Receipt Updated successfully','Edited !');
                //swal("Edit", 'Receipt Updated successfully ', 'success');
                this.recpBillTranModel = new BillRecptPaymtTran();
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
      else{
        this.toastr.warning('Please Select Payment Mode','Alert !');
        //swal("Opps", 'Please Select Payment Mode', 'warning');
      }
    }

    /*delete bill tran item detail  deleteBillRecptPaymtTran*/
    deleteBillRecptPaymtTran(billRecpDetail) {
        swal({
            title: "Are you sure?",
            text: "You want to Delete Recepit ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.value) {
                this.icareservices.deleteBillRecpt(billRecpDetail.runId)
                    .subscribe((response) => {
                        this.getPatientBillColl(this.selectedBillTranDtl.billId);
                    });
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });

    }

    clearBillRecptTranModal() {
        this.showEditReceipt = false;
        this.showAddReceipt = false;
        this.recpBillTranModel = new BillRecptPaymtTran();
    }

    clearBillReceiptTranModal(){
        this.showEditReceipt = false;
        this.showAddReceipt = false;
        this.recpBillTranModel = new BillRecptPaymtTran();
    }
    clearBillPaymentTranModal(){
        this.showEditPayment = false;
        this.showAddPayment = false;
        this.recpBillTranModel = new BillRecptPaymtTran();
    }

    /*------------------------PAYMENT SECTION START --------------------------------------*/
   

    /* on button click open addPaymenytItemModal*/
    addPaymenytItemModal() {
        this.showEditPayment = false;
        this.showAddPayment = true;     
        this.recpBillTranModel.amount=this.patientPayable; 
        this.submitted = true;
       // $(this.addBillingPaymentModal.nativeElement).modal('show');
    }

    /* on button click open addBillingRecepitModal*/
    editBillingPaymntTran(billRecpDetail) {
        this.submitted = true;
        this.showEditPayment = true;
      //  $(this.editBillingPaymentModal.nativeElement).modal('show');
        this.recpBillTranModel.runId = billRecpDetail.runId;
        this.recpBillTranModel.afiVersion = billRecpDetail.afiVersion;
        this.recpBillTranModel.tranType = billRecpDetail.tranType;
        this.recpBillTranModel.amount = this.currencyFormatter(billRecpDetail.amount);
        this.recpBillTranModel.remarks = billRecpDetail.remarks;
        this.recpBillTranModel.paymentMode = billRecpDetail.paymentMode;
        this.recpBillTranModel.billPaymentType=billRecpDetail.paymentType;
        this.recpBillTranModel.creditId=billRecpDetail.creditId;
    }

    /*onbutton click createBillReceiptTranDtl save with respect to bill Id */
    createBillPaymentTranDtl() {
        this.recpBillTranModel.billId = this.selectedBillTranDtl.billId;
        this.recpBillTranModel.tranDate = new Date();
        this.recpBillTranModel.enteredBy = this.userColl.persCode;
        this.recpBillTranModel.tranType = 'P';
        this.recpBillTranModel.billPaymentType=+this.paymentType;
        this.recpBillTranModel.creditId=this.selectedBillTranDtl.creditId;
        this.icareservices.createBillRecptPaymtTran(this.recpBillTranModel).subscribe(response => {
            if (response) {
                this.showAddPayment = false;
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
                this.toastr.success('Payment Added successfully','Save !');
                //swal("Save", 'Payment Added successfully ', 'success');
                this.recpBillTranModel = new BillRecptPaymtTran();

            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }

    editBillPaymentTranDtl() {
        this.recpBillTranModel.billId = this.selectedBillTranDtl.billId;
        this.recpBillTranModel.tranType = 'P';
        this.recpBillTranModel.tranDate = new Date();
        this.recpBillTranModel.enteredBy = this.userColl.persCode;
        this.icareservices.editBillRecptPaymtTran(this.recpBillTranModel).subscribe(response => {
            if (response) {
                this.showEditPayment = false;
                this.getPatientBillColl(this.selectedBillTranDtl.billId);
                this.toastr.success('Payment Updated successfully','Edited !');
                //swal("Edit", 'Payment Updated successfully ', 'success');
                this.recpBillTranModel = new BillRecptPaymtTran();

            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }

    /*delete Payment bill tran item detail */
    deleteBillPaymtTran(billRecpDetail) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!"

        }).then((result) => {
            if (result.value) {
                this.icareservices.deleteBillRecptPaymtTran(billRecpDetail.runId)
                    .subscribe((response) => {
                        this.getPatientBillColl(this.selectedBillTranDtl.billId);
                    });
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });

    }

    /* approvedBillTranDtl this.patientPayable*/
    approvedBillTranDtl() {
        if (this.listBillTranDetail.billTranDtlView.length > 0 && this.listBillTranDetail.balanceAmt == 0) {
            this.icareservices.approvePatientBill(this.selectedBillTranDtl.billId).subscribe(response => {
                if (response) {
                    this.listVisitBillHdrs(this.selectedBillTranDtl.visitId);
                    this.getPatientBillColl(this.selectedBillTranDtl.billId);
                    this.btnDisable();
                    this.toastr.success('Bill Aprrove successfully ','Approve !');
                    //swal("Approve", 'Bill Aprrove successfully ', 'success');
                }
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
        }
        else {
            this.toastr.warning('Please check Patient Bill Item and Payable Amount','Alert !');
           // swal("Oops", 'Please check Patient Bill Item and Payable Amount', 'warning');
        }
    }

    printBillTranDtl() {       
        this.submitted = true;
        $(this.ModalPrintBill.nativeElement).modal('show');
    }

    /* get Total */
    get total() {
        let Total = 0;
        Total += ((this.billTranModel.unitPrice - this.billTranModel.deductionAmt) * this.billTranModel.itemQty) - this.billTranModel.discountAmt;
        // return Math.round(Total * 100) / 100;
        return this.currencyFormatter(Total + this.taxAmount);
    }

    get totalUnitPrice() {
        let total = 0;
        if (this.listBillTranDetail.billTranDtlView) {
            for (var i = 0; i < this.listBillTranDetail.billTranDtlView.length; i++) {
                if (this.listBillTranDetail.billTranDtlView[i].unitPrice) {
                    total += this.listBillTranDetail.billTranDtlView[i].unitPrice;
                }
            }
        }
        // return total;
        return this.currencyFormatter(total);
    }

    get totalDeducationPrice() {
        let total = 0;

        if (this.listBillTranDetail.billTranDtlView) {
            for (var i = 0; i < this.listBillTranDetail.billTranDtlView.length; i++) {
                if (this.listBillTranDetail.billTranDtlView[i].deductionAmt) {
                    total += this.listBillTranDetail.billTranDtlView[i].deductionAmt;
                }
            }
        }
        // return total;
        return this.currencyFormatter(total);
    }

    get totalQty() {
        let total = 0;
        if (this.listBillTranDetail.billTranDtlView) {
            for (var i = 0; i < this.listBillTranDetail.billTranDtlView.length; i++) {
                if (this.listBillTranDetail.billTranDtlView[i].itemQty) {
                    total += this.listBillTranDetail.billTranDtlView[i].itemQty;
                }
            }
        }
        return total;
    }

    get totalDiscountPrice() {
        let total = 0;
        if (this.listBillTranDetail.billTranDtlView) {
            for (var i = 0; i < this.listBillTranDetail.billTranDtlView.length; i++) {
                if (this.listBillTranDetail.billTranDtlView[i].discountAmt) {
                    total += this.listBillTranDetail.billTranDtlView[i].discountAmt;
                }
            }
        }
        return this.currencyFormatter(total);
    }

    

    // 1.
    get totalNetAmnt() {
        let total = 0;
        if (this.listBillTranDetail.billTranDtlView) {
            for (var i = 0; i < this.listBillTranDetail.billTranDtlView.length; i++) {
                if (this.listBillTranDetail.billTranDtlView[i].netAmt) {
                    total += this.listBillTranDetail.billTranDtlView[i].netAmt;
                }
            }
        }
         return total.toFixed(3);       
    }

    // 2.
    get totalReceiptAmount() {
        let total = 0;
        if (this.listBillRecptTranDetail) {
            for (var i = 0; i < this.listBillRecptTranDetail.length; i++) {
                if (this.listBillRecptTranDetail[i].amount) {
                    total += this.listBillRecptTranDetail[i].amount;
                }
            }
        }
        return total.toFixed(3);
         //return this.currencyFormatter(total);
    }

    get totalPaymentAmount() {
        let total = 0;
        if (this.listBillPaymntTranDetail) {
            for (var i = 0; i < this.listBillPaymntTranDetail.length; i++) {
                if (this.listBillPaymntTranDetail[i].amount) {
                    total += this.listBillPaymntTranDetail[i].amount;
                }
            }
        }
        return total.toFixed(3);
         //return this.currencyFormatter(total);
    }

    get patientPayable() {
        let Total ;
        let netAmnt:any =+this.totalNetAmnt;
        let recptAmnt:any=+this.totalReceiptAmount;
        let totalPayAmt:any=+this.totalPaymentAmount;

        Total = (netAmnt - recptAmnt) + totalPayAmt;  
        //return (Math.round(Total * 100) / 100);
        return Total.toFixed(3);
    }

    get creditAmount() {
        let Total = 0;
        if (this.selectedBillTranDtl.creditAmt) {
            Total += this.selectedBillTranDtl.creditAmt;
        }
        return Total.toFixed(3);
    }

    get creditBalanceAmount() {
        let Total = 0;
        if (this.selectedBillTranDtl.balanceAmt) {
            Total += this.selectedBillTranDtl.balanceAmt;
        }
        return Total.toFixed(3);
    }

    get taxAmount() {
        let Total = 0;
        Total += ((this.billTranModel.unitPrice  * this.billTranModel.itemQty) - this.billTranModel.discountAmt )* this.billTranModel.taxPerc;
         return Math.round(Total) / 100;
        //return this.currencyFormatter(Total);
    }

    get vatPercentage() {
        let Total = 0;
        Total += (this.billTranModel.unitPrice*this.billTranModel.itemQty);
        return Math.round(Total) / 100;
        //return this.currencyFormatter(Total);
    }

    get getConsultantName() {
        let consltant: any;
        if (this.persItem.consultantCode) {
            consltant = this.listConsultant.find(item => item.persCode === +this.persItem.consultantCode);           
            return consltant.mstPersonalMast.printName;
        }
    }

    currencyFormatter(omr){
        let n=3;    
        if(omr){
            return omr.toFixed(n).replace('/\d(?=(\d{'+ n +'})+\.)/g', '$&,'); 
        }        
    }

    /* bind autocomplete list on search of list billable items */
    autocompleteListBillItems = (data: any): SafeHtml => {
        let html = `<span>${data.itemName}</span>`;
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    formatDate() {
        let dt = new Date();
        let day;
        if (dt.getDate() < 10) {
            day = '0' + dt.getDate();
        } else {
            day = dt.getDate();
        }
        let month = dt.getMonth() + 1;
        let year = (dt.getFullYear() + '').substr(2, 2);
        let hours = dt.getHours();
        let minutes = dt.getMinutes();
        return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
    }

    getAge(dateString) {

        var birthdate = new Date(dateString).getTime();
        var now = new Date().getTime();
        // now find the difference between now and the birthdate
        var n = (now - birthdate)/1000;
      
        if (n < 604800) { // less than a week
          var day_n = Math.floor(n/86400);
          return day_n + ' D' + (day_n > 1 ? 's' : '');
        } else if (n < 2629743) {  // less than a month
          var week_n = Math.floor(n/604800);
          return week_n + ' W' + (week_n > 1 ? 's' : '');
        } else if (n < 63113852) { // less than 24 months
          var month_n = Math.floor(n/2629743);
          return month_n + ' M' + (month_n > 1 ? 's' : '');
        } else { 
          var year_n = Math.floor(n/31556926);
          return year_n + ' Y' + (year_n > 1 ? 's' : '');
        }
      }
      
    printBillTran(): void {
        let printContents, popupWin;
        printContents = document.getElementById('printBillDetails').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
              <html>
                  <head>
                      <title></title>
                      <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
                  <style>
                 
                    html,body{
                    -webkit-print-color-adjust: exact !important;
                      background: transparent; 
                      font-size: 12px;
                    }
                    table.billItem td.price {
                        text-align: right!important;
                      }
                     
                    table {
                        font-size: 11px;
                        border: 2px solid darksalmon !important;
                    }
                    
                  .panel {
                    margin-bottom: 5px!important;
                    }
                    .panel-body {
                      padding: 5px;
                  }
                  .text_space{
                    white-space: pre;
                   }
                    ._td{
                        white-space: pre;
                    }
                   .tbl{
                    white-space: nowrap;
                   }
          
                   
                  </style>
                      </head>
                  <body onload="window.print();window.close()">
          
              
                  ${printContents}
                  
                  </body>
              </html>`
        );
        popupWin.document.close();
    };

    btnEnable(){
        this.btnAddBillingItem = false;
        this.btnEditBillingItem = false;
        this.btnDeleteBillingItem = false;
        this.btnAddRecptItem = false;
        this.btnEditBillingRecptItem = false;
        this.btnDeleteBillingRecptItem = false;
        this.btnAddPaymentItem = false;
        this.btnEditPaymentTranItem = false;
        this.btnDeletePaymentTranItem = false;
        this.btnApproveBill=false;
    }

    btnDisable(){
        this.btnAddBillingItem = true;
        this.btnEditBillingItem = true;
        this.btnDeleteBillingItem = true;
        this.btnAddRecptItem = true;
        this.btnEditBillingRecptItem = true;
        this.btnDeleteBillingRecptItem = true;
        this.btnAddPaymentItem = true;
        this.btnEditPaymentTranItem = true;
        this.btnDeletePaymentTranItem = true;
        this.btnApproveBill=true;
    }
}
