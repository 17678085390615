export class ClcPatientHistory {
    enteredBy: string;
    historyTypeCode: number;
    historyTypeName: string;
    id: number;
    invalidatedOn: string;
    invalidatedVisitId: number;
    modifiedBy: number;
    paraCode: number;
    paraName: string;
    paramGroupId: number;
    paramGroupName: string;
    patientId: number;
    remarks: string;
    status: string;
    uniqueId: number;
    visitId: number;
    yearFrom: string;
    public constructor() { }
}
