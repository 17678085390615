export class ClcTriageDetails{
 arrivalTime: Date;
 triageTime: Date;
 createdDate: Date;
 disposalTime: Date;
 expiredTime: Date;
 broughtByName: string;
 broughtByContact: string;
 neuroAssessment: string;
 actionTaken: string;
 nurseAssessment: string;
 disposalRemarks: string;
 triageId: number;
 visitId: number;
 modeOfArrival: number;
 triageType: number;
 painScore: number;
 createdBy: number;
 disposalType: number;
 disposedBy: number;
 expiryType: number;
 estCode: number;
 afiVersion: number;
 public constructor() {}
}
	
  
