import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler } from '@angular/common/http';
import * as AppUtils from 'src/app/common/app.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';
import 'rxjs/add/operator/mergeMap';
import { ToastrService } from 'ngx-toastr';
import { MstEstClinics } from '../resources/MstEstClinics.resource';
import { ClcTriageDetails } from '../resources/ClcTriageDetails.resource';
import { MedGenericMast } from '../resources/MedGenericMast.resource';
import { MedPharmacyItemMast } from '../resources/MedPharmacyItemMast.resource';
import { MedCourseMast } from '../resources/MedCourseMast.resource';
import { ClcVServiceItems } from '../resources/ClcVServiceItems.resource';
import { ClcVlabItems } from '../resources/ClcVlabItems.resource';

import { MstPersClinicView } from '../resources/MstPersClinicView.resource';
import { ClcPatientViewColl } from '../resources/ClcPatientViewColl.resource';
import { BillTranCollView } from '../resources/BillTranCollView.resource';
import { ClcPatientPolicyColl } from '../resources/ClcPatientPolicyColl.resource';
import { ClcPatientPackageView } from '../resources/ClcPatientPackageView.resource';
import { ClcBillQueryVO } from '../resources/ClcBillQueryVO.resource';
import { MstPackageMast } from '../resources/MstPackageMast.resource';
import { BillRecptPaymtTran } from '../resources/BillRecptPaymtTran.resource';
import { BillTranColl } from '../resources/BillTranColl.resource';
import { ClcPatientColl } from '../resources/ClcPatientColl.resource';
import { ClcPatientPolicies } from '../resources/ClcPatientPolicies.resource';
import { ClcPatientNextkinView } from '../resources/ClcPatientNextkinView.resource';
import { MstPolicyMast } from '../resources/MstPolicyMast.resource';
import { BillTranDtlView } from '../resources/BillTranDtlView.resource';
import { MstSchemeMast } from '../resources/MstSchemeMast.resource';
import { ClcVisits } from '../resources/ClcVisits.resource';
import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';
import { ClcPatientPackages } from '../resources/ClcPatientPackages.resource';
import { BillTranHdr } from '../resources/BillTranHdr.resource';
import { BillTranDtl } from '../resources/BillTranDtl.resource';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';
import { ClcPatientNextkin } from '../resources/ClcPatientNextkin.resource';
import { ClcPatients } from '../resources/ClcPatients.resource';
import { ClcVisitView } from '../resources/ClcVisitView.resource';
import { ClcPatientView } from '../resources/ClcPatientView.resource';
import { ClcVitalSigns } from '../resources/ClcVitalSigns.resource';
import { ClcExamTranColl } from '../resources/ClcExamTranColl.resource';
import { MedPrescriptionTran } from '../resources/MedPrescriptionTran.resource';
import { ClcServiceOrders } from '../resources/ClcServiceOrders.resource';
import { ClcIcdMaster } from '../resources/ClcIcdMaster.resource';
import { ClcIcdTran } from '../resources/ClcIcdTran.resource';
import { ClcAllergyNonMedMast } from '../resources/ClcAllergyNonMedMast.resource';
import { ClcAllergyTran } from '../resources/ClcAllergyTran.resource';
import { ClcAllergyTypes } from '../resources/ClcAllergyTypes.resource';
import { ClcPatientNotes } from '../resources/ClcPatientNotes.resource';
import { AppClinicMast } from '../resources/AppClinicMast.resource';
import { ClcGenAppointment } from '../resources/ClcGenAppointment.resource';
import { ClcAppointmentQueryVO } from '../resources/ClcAppointmentQueryVO.resource';
import { ClcCertTypeMast } from '../resources/ClcCertTypeMast.resource';
import { ClcCertificates } from '../resources/ClcCertificates.resource';
import { ClcPainAssesment } from '../resources/ClcPainAssesment.resource';
import { ClcPainInterventions } from '../resources/ClcPainInterventions.resource';
import { ClcBillPayRcpQueryVO } from '../resources/ClcBillPayRcpQueryVO.resource';
import { ReqAppointmentModel } from '../resources/app-resource/ReqAppointmentModel.resource';
import { ClcPatientHistory } from '../resources/ClcPatientHistory.resource';
import { ClcBillRcpQueryVO } from '../resources/ClcBillRcpQueryVO .resource';
import { MedicineMasterQueryVO } from '../resources/MedicineMasterQueryVO.resource';


@Injectable()

export class ICAREServices {

  private AFFINITY_URL_BASE = AppUtils.BACKEND_API_URL;

  public headerData;
  converter: any;
  constructor(private http: HttpClient,private toastr: ToastrService) { }

  private handleError(error: any): Promise<any> {
    console.error('Could not complete requested service operation', error);
    return Promise.reject(error.message || error);
  }

  /* master services */

  public listAppClinicMast(): Observable<AppClinicMast> {
    return this.http.get<any>(AppUtils.LIST_APP_CLINIC_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listMstSchemeMast(mstschememast: MstSchemeMast): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_SCHEME_MAST, mstschememast).pipe(map(res => {
      return res;
    }));

  }

  public getMstPolicyMast(policyCode: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_POLICY_MAST + `${policyCode}`).pipe(map(res => {
      return res;
    }));
  }
  public createMstPolicyMast(mstPolicyMast: MstPolicyMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_POLICY, mstPolicyMast).pipe(map(res => {
      return res;
    }));
  }
  public createMstSchemeMast(mstschememast: MstSchemeMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_SCHEME, mstschememast).pipe(map(res => {
      return res;
    }));
  }
  public editMstSchemeMast(mstschememast: MstSchemeMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_SCHEME, mstschememast).pipe(map(res => {
      return res;
    }));
  }
  public editMstPolicyMast(mstPolicyMast: MstPolicyMast): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_POLICY, mstPolicyMast).pipe(map(res => {
      return res;
    }));
  }

  public deleteMstSchemeMast(schemeCode: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_SCHEME_MAST + `${schemeCode}`).pipe(map(res => {
      return res;
    }));
  }

  public deleteMstPolicyMast(policyCode: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_POLICY_MAST + `${policyCode}`).pipe(map(res => {
      return res;
    }));
  }

  public listMstPolicyMast(): Observable<MstPolicyMast> {
    return this.http.get<MstPolicyMast>(AppUtils.LIST_POLICY_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listMstPackageMast(): Observable<MstPackageMast> {
    return this.http.get<MstPackageMast>(AppUtils.LIST_PACKAGE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClinics(): Observable<MstEstClinics> {
    return this.http.get<MstEstClinics>(AppUtils.LIST_CLINICS).pipe(map(res => {
      return res;
    }));
  }

  public listClcTriageTypeMast(): Observable<ClcTriageDetails> {
    return this.http.get<ClcTriageDetails>(AppUtils.LIST_TRIAGE_TYPE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcArrivalModeMast(): Observable<ClcTriageDetails> {
    return this.http.get<ClcTriageDetails>(AppUtils.LIST_ARRIVAL_MODE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listMedGenericMast(): Observable<MedGenericMast> {
    return this.http.get<MedGenericMast>(AppUtils.LIST_GENERIC_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listMedicineMast(): Observable<MedPharmacyItemMast> {
    return this.http.get<MedPharmacyItemMast>(AppUtils.LIST_MEDICINE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listMedCourseMast(): Observable<MedCourseMast> {
    return this.http.get<MedCourseMast>(AppUtils.LIST_COURSE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcServiceItems(): Observable<ClcVServiceItems> {
    return this.http.get<ClcVServiceItems>(AppUtils.LIST_SERVICE_ITEMS).pipe(map(res => {
      return res;
    }));
  }

  public listBillItemTypeMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_BILL_ITEM_TYPE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcLabItems(): Observable<ClcVlabItems> {
    return this.http.get<ClcVServiceItems>(AppUtils.LIST_LAB_ITEMS).pipe(map(res => {
      return res;
    }));
  }

  public listIcdMaster(): Observable<ClcIcdMaster> {
    return this.http.get<ClcIcdMaster>(AppUtils.LIST_ICD_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcAllergyNonMedMast(): Observable<ClcAllergyNonMedMast> {
    return this.http.get<ClcAllergyNonMedMast>(AppUtils.LIST_ALLERGY_NON_MED_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcAllergyTypes(): Observable<ClcAllergyTypes> {
    return this.http.get<ClcAllergyTypes>(AppUtils.LIST_ALLERGY_TYPE).pipe(map(res => {
      return res;
    }));
  }

  public listPersClinicView(): Observable<MstPersClinicView> {
    return this.http.get<MstPersClinicView>(AppUtils.LIST_CLINIC_VIEW).pipe(map(res => {
      return res;
    }));
  }


  /* other services  */
  public getPatientViewColl(patientNo: string): Observable<any> {
    return this.http.get<any>(AppUtils.GET_PATIENT_VIEW_COLL + `${patientNo}`).pipe(map(res => {
      return res;
    }));
  }

  public listPatientColl(clcpatientQueryVO: ClcPatientQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_PATIENT, clcpatientQueryVO).pipe(map(res => {
      return res;
    }));
  }

  public createPatient(clcpatientcoll: ClcPatientColl): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT, clcpatientcoll).pipe(map(res => {
      return res;
    }));
  }

  public editPatient(clcpatients: ClcPatients): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT, clcpatients).pipe(map(res => {
      return res;
    }));
  }

  public createClcPatientPolicyColl(clcpatientpolicycoll: ClcPatientPolicyColl): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT_POLICY_COLL, clcpatientpolicycoll).pipe(map(res => {
      return res;
    }));
  }

  public editClcPatientPolicyColl(clcpatientpolicycoll: ClcPatientPolicyColl): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT_POLICY_COLL, clcpatientpolicycoll).pipe(map(res => {
      return res;
    }));
  }


  public deleteClcPatientPolicyColl(patPolicyId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_PATIENT_POLICY_COLL + `${patPolicyId}`).pipe(map(res => {
      return res;
    }));
  }

  public createClcPatientNextkin(clcpatientnextkin: ClcPatientNextkin): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT_NEXTKIN, clcpatientnextkin).pipe(map(res => {
      return res;
    }));
  }

  public editClcPatientNextkin(clcpatientnextkin: ClcPatientNextkin): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT_NEXTKIN, clcpatientnextkin).pipe(map(res => {
      return res;
    }));
  }

  public createClcPatientPackage(clcpatientpackages: ClcPatientPackages): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT_PACKAGE, clcpatientpackages).pipe(map(res => {
      return res;
    }));
  }

  public editClcPatientPackage(clcpatientpackages: ClcPatientPackages): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT_PACKAGE, clcpatientpackages).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcPatientPackage(patPackageId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_PATIENT_PACKAGE + `${patPackageId}`).pipe(map(res => {
      return res;
    }));
  }

  public createVisit(clcvisits: ClcVisits): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_VISIT, clcvisits).pipe(map(res => {
      return res;
    }));
  }

  public listClcPatientPackages(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_PACKAGES_COLL + `${patientId}`).pipe(map(res => {
      return res;
    }));
  }

  public listClcPatientPolicyColl(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_POLICY_COLL + `${patientId}`).pipe(map(res => {
      return res;
    }));
  }

  public editVisit(clcvisits: ClcVisits): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_VISIT, clcvisits).pipe(map(res => {
      return res;
    }));
  }

  public listPatientVisits(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_VISITS + `${patientId}`).pipe(map(res => {
      return res;
    }));
  }

  public listClcVisitView(clcvisitqueryvo: ClcVisitQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_VISIT_VIEW, clcvisitqueryvo).pipe(map(res => {
      return res;
    }));
  }

  public getClcVisitView(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_VISIT_VIEW + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public getVisitTriageDetails(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_VISIT_TRIAGE_DETAILS + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public createTriageDetails(clcTriageDetails: ClcTriageDetails): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_TRIAGE_DETAILS, clcTriageDetails).pipe(map(res => {
      return res;
    }));
  }

  public editTriageDetails(clcTriageDetails: ClcTriageDetails): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_TRIAGE_DETAILS, clcTriageDetails).pipe(map(res => {
      return res;
    }));
  }

  public listVisitVitalSigns(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_VITALS_SIGNS + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public createClcVitals(clcVitalSigns: ClcVitalSigns): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_VITALS_SIGNS, clcVitalSigns).pipe(map(res => {
      return res;
    }));
  }

  public editClcVitals(clcVitalSigns: ClcVitalSigns): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_VITALS_SIGNS, clcVitalSigns).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcVitals(vitalRunId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_VITALS_SIGNS + `${vitalRunId}`).pipe(map(res => {
      return res;
    }));
  }

  public listVisitExamTran(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_EXAM_TRAN + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public listGenExamTemplate(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_GEN_EXAM_TEMPLATE).pipe(map(res => {
      return res;
    }));
  }

  public createClcGenExam(clcExamTranColl: ClcExamTranColl): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_EXAM_TRAN_COLL, clcExamTranColl).pipe(map(res => {
      return res;
    }));
  }

  public editClcGenExam(clcExamTranColl: ClcExamTranColl): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_EXAM_TRAN_COLL, clcExamTranColl).pipe(map(res => {
      return res;
    }));
  }

  public listAllClcExamJsonTemplate(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_ALL_CLC_EXAM_JSON_TEMPLATE).pipe(map(res => {
      return res;
    }));
  }

  public createPrescription(medPrescriptionTran: MedPrescriptionTran): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PRESCRIPTION, medPrescriptionTran).pipe(map(res => {
      return res;
    }));
  }

  public editPrescription(medPrescriptionTran: MedPrescriptionTran): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PRESCRIPTION, medPrescriptionTran).pipe(map(res => {
      return res;
    }));
  }

  public deletePrescription(runId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_PRESCRIPTION + `${runId}`).pipe(map(res => {
      return res;
    }));
  }

  public listVisitPrescriptions(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_PRESCRIPTION + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public createClcServiceOrder(clcServiceOrders: ClcServiceOrders): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_SERVICE_ORDER, clcServiceOrders).pipe(map(res => {
      return res;
    }));
  }

  public editClcServiceOrder(clcServiceOrders: ClcServiceOrders): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_SERVICE_ORDER, clcServiceOrders).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcServiceOrder(orderId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_SERVICE_ORDERS + `${orderId}`).pipe(map(res => {
      return res;
    }));
  }

  public listVisitServiceOrders(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_SERVICE_ORDERS + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public createClcIcdTran(clcIcdTran: ClcIcdTran): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_ICD_TRAN, clcIcdTran).pipe(map(res => {
      return res;
    }));
  }

  public editClcIcdTran(clcIcdTran: ClcIcdTran): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_ICD_TRAN, clcIcdTran).pipe(map(res => {
      return res;
    }));
  }

  public listVisitIcdTran(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_ICD_TRAN + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcIcdTran(icdTranId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_ICD_TRAN + `${icdTranId}`).pipe(map(res => {
      return res;
    }));
  }

  public createClcAllergyTran(clcAllergyTran: ClcAllergyTran): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_ALLERGY_TRAN, clcAllergyTran).pipe(map(res => {
      return res;
    }));
  }

  public editClcAllergyTran(clcAllergyTran: ClcAllergyTran): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_ALLERGY_TRAN, clcAllergyTran).pipe(map(res => {
      return res;
    }));
  }

  public listVisitAllergyTran(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_ALLERGY_TRAN + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcAllergyTran(patAllergyId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_ALLERGY_TRAN + `${patAllergyId}`).pipe(map(res => {
      return res;
    }));
  }

  /*PATIENT NOTES*/

  public listNoteTypesMast(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_NOTE_TYPE_MAST).pipe(map(res => {
      return res;
    }));
  }

  public listClcVisitNotes(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_NOTES + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcPatientNotes(notesId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_PATIENT_NOTES + `${notesId}`).pipe(map(res => {
      return res;
    }));
  }
  public getClcPatientNotes(notesId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_PATIENT_NOTES + `${notesId}`).pipe(map(res => {
      return res;
    }));
  }
  public createClcPatientNotes(clcpatientnotes: ClcPatientNotes): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT_NOTES, clcpatientnotes).pipe(map(res => {
      return res;
    }));
  }
  public editClcPatientNotes(clcpatientnotes: ClcPatientNotes): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT_NOTES, clcpatientnotes)
      .pipe(map(res => {
        return res;
      }));
  }
  /*END PATIENT NOTES*/

  /* PATIENT APPOINTMENT */
  public listClcGenAppointment(clcAppointmentQueryVO: ClcAppointmentQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_GEN_APPOINTMENT, clcAppointmentQueryVO).pipe(map(res => {
      return res;
    }));
  }

  public createClcGenAppointment(clcGenAppointment: ClcGenAppointment): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PATIENT_APPOINTMENT, clcGenAppointment).pipe(map(res => {
      return res;
    }));
  }

  public editClcGenAppointment(clcGenAppointment: ClcGenAppointment): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PATIENT_APPOINTMENT, clcGenAppointment).pipe(map(res => {
      return res;
    }));
  }

  public deleteClcGenAppointment(appId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_PATIENT_APPOINTMENT + `${appId}`).pipe(map(res => {
      return res;
    }));
  }
  /* END PATIENT APPOINTMENT */

  /* PATIENT BILLING */
  public getPatient(patientNo: string): Observable<any> {
    return this.http.get<any>(AppUtils.GET_PATIENT + `${patientNo}`).pipe(map(res => {
      return res;
    }));
  }

  public getLatestVisit(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_LATEST_VISIT + `${patientId}`).pipe(map(res => {
      return res;
    }));
  }

  public listVisitBillHdrs(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_BILL_HDRS + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }
  public createOPBillHdr(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.CREATE_OP_BILL_HDRS + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public generateOPBill(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GENERATE_OP_BILL + `${visitId}`).pipe(map(res => {
      return res;
    }));
  }

  public listBillableItems(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_BILLABLE_ITEMS).pipe(map(res => {
      return res;
    }));
  }

  public listBillPayModes(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_BILL_PAY_MODES).pipe(map(res => {
      return res;
    }));
  }

  public createBillTranDtl(billTranDtl: BillTranDtl): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_BILL_TRAN_DETAIL, billTranDtl).pipe(map(res => {
      return res;
    }));
  }

  public editBillTranDtl(billTranDtl: BillTranDtl): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_BILL_TRAN_DETAIL, billTranDtl).pipe(map(res => {
      return res;
    }));
  }

  public getPatientBill(billId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_PATIENT_BILL + `${billId}`).pipe(map(res => {
      return res;
    }));
  }

  public deleteBillTranDtl(runId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_BILL_TRAN_DETAIL + `${runId}`).pipe(map(res => {
      return res;
    }));
  }

  public createBillRecptPaymtTran(billRecptPaymtTran: BillRecptPaymtTran): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_BILL_RECPT_PAYMENT_TRAN, billRecptPaymtTran).pipe(map(res => {
      return res;
    }));
  }

  public editBillRecptPaymtTran(billRecptPaymtTran: BillRecptPaymtTran): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_BILL_RECPT_PAYMENT_TRAN, billRecptPaymtTran).pipe(map(res => {
      return res;
    }));
  }

  public deleteBillRecptPaymtTran(runId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_BILL_RECPT_PAYMENT_TRAN + `${runId}`).pipe(map(res => {
      return res;
    }));
  }

  // new API call given by @abdullah bhai on 16052022.
  public deleteBillRecpt(runId: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_BILL_RECPT + `${runId}`).pipe(map(res => {
      return res;
    }));
  }
 // new API call given by @abdullah bhai on 17052022.
  public cancelBillPayment(billId: number): Observable<any> {
    return this.http.get<any>(AppUtils.CANCEL_BILL_PAYMENT + `${billId}`).pipe(map(res => {
      return res;
    }));
  }

  public approvePatientBill(billId: number): Observable<any> {
    return this.http.get<any>(AppUtils.APPROVED_PATIENT_BILL + `${billId}`).pipe(map(res => {
      return res;
    }));
  }

  /* END PATIENT BILLING */

  /* clinical cerificate  */
  public listClcCertTypeMast(): Observable<ClcCertTypeMast> {
    return this.http.get<ClcCertTypeMast>(AppUtils.LIST_CERTIFICATE_TYPE_MAST).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listPatientCertificates(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_CERTIFICATE + `${patientId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public createClcCertificates(clcCertificates: ClcCertificates): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_CERTIFICATE, clcCertificates).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public editClcCertificates(clcCertificates: ClcCertificates): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_CERTIFICATE, clcCertificates).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public getClcCertContent(certId: number): Observable<any> {
    return this.http.get<any>(AppUtils.GET_CERTIFICATE_CONTENT + `${certId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public approveClcCertificate(certId: number): Observable<any> {
    return this.http.get<any>(AppUtils.APPROVE_CERTIFICATE + `${certId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public cancelClcCertificate(clcCertificates: ClcCertificates): Observable<any> {
    return this.http.post<any>(AppUtils.CANCEL_CERTIFICATE, clcCertificates).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  /* Bill Search */
  public listBillHdrs(clcBillQueryVO: ClcBillQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_BILL_HDRS, clcBillQueryVO).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  clcBillRcpQueryVO
  public unApprovePatientBill(billId: number): Observable<any> {
    return this.http.get<any>(AppUtils.UNAPPROVED_PATIENT_BILL + `${billId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public cancelPatientBill(billId: number): Observable<any> {
    return this.http.get<any>(AppUtils.CANCEL_PATIENT_BILL + `${billId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listBillVTran(clcBillQueryVO: ClcBillQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_BILL_VIEW_TRAN, clcBillQueryVO).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  //clcbillpayrcpqueryvo : ClcBillPayRcpQueryVO

  public listBillRecptPaymtView(clcbillpayrcpqueryvo: ClcBillPayRcpQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_BILL_PAYMENT_VIEW, clcbillpayrcpqueryvo).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  /* Pain Assesment */
  public listVisitPainAssesment(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_PAIN_ASSESMENT + `${visitId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public createClcPainAssesment(ClcPainAssesment: ClcPainAssesment): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PAIN_ASSESMENT, ClcPainAssesment).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public editClcPainAssesment(ClcPainAssesment: ClcPainAssesment): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PAIN_ASSESMENT, ClcPainAssesment).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listPainCharCodes(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PAIN_CHAR_CODES).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  /* Pain Intervention */
  public listVisitPainInterventions(visitId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_VISIT_PAIN_INTERVENTION + `${visitId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listPainInterventionCodes(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PAIN_INTERVENTION_CODES).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public createClcPainInterventions(ClcPainInterventions: ClcPainInterventions): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_PAIN_INTERVENTIONS, ClcPainInterventions).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public editClcPainInterventions(ClcPainInterventions: ClcPainInterventions): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_PAIN_INTERVENTIONS, ClcPainInterventions).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listReqEstEntitySchedules(entityCode: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_EST_ENTITY_SCHEDULES + `${entityCode}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listReqVScheduleSlots(scheduleId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_REQ_SCHEDULE_SLOTS + `${scheduleId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public createReqAppointment(reqAppointmentModel: ReqAppointmentModel): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_REQ_APPOINTMENT, reqAppointmentModel).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public rescheduleReqAppointment(reqAppointmentModel: ReqAppointmentModel): Observable<any> {
    return this.http.post<any>(AppUtils.RESCHEDULE_REQ_APPOINTMENT, reqAppointmentModel).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public cancelReqAppointment(appId: number) {
    return this.http.post(AppUtils.CANCEL_REQ_APPOINTMENT + appId, null).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listClcIcdMaster(clcIcdMaster: ClcIcdMaster): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_CLC_ICD_MASTER, clcIcdMaster).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public listBillPaymentType(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_BILL_PAYMENT_TYPE).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public getBillDownPayment(billId: number) {
    return this.http.get(AppUtils.GET_BILL_DOWN_PAYMENT + `${billId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  
  public getPolicyCoPayList() {
    return this.http.get(AppUtils.GET_POLICY_COPAY_LIST).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public getPatientHistory(patientId: number) {
    return this.http.get(AppUtils.GET_PATIENT_HISTORY+ `${patientId}`).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }

  public createPatientHistory(clcPatientHistory: ClcPatientHistory): Observable<any> {
    return this.http.post(AppUtils.CREATE_PATIENT_HISTORY,clcPatientHistory).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  
  public createMstPackageMast(mstPackageMast: MstPackageMast): Observable<any> {
    return this.http.post<any>(AppUtils.CREATE_MST_PACKAGE_MAST, mstPackageMast).pipe(map(res => {
      return res;
    }));
  }

  public editMstPackageMast(mstPackageMast: MstPackageMast): Observable<any> {
    return this.http.post<any>(AppUtils.EDIT_MST_PACKAGE_MAST, mstPackageMast).pipe(map(res => {
      return res;
    }));
  }

  public deleteMstPackageMast(packageCode: number): Observable<any> {
    return this.http.delete<any>(AppUtils.DELETE_MST_PACKAGE_MAST + `${packageCode}`).pipe(map(res => {
      return res;
    }));
  }

  public listPatientAllPrescriptions(patientId: number): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_PATIENT_ALL_PRESCRIPTIONS + `${patientId}`).pipe(map(res => {
      return res;
    }));
  }

  /* Bill Search */
  public listDailyBillRecptPayment(clcBillRcpQueryVO: ClcBillRcpQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_DAILY_BILL_PAYMENT, clcBillRcpQueryVO).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  
  public listMedicineGroupMaster(): Observable<any> {
    return this.http.get<any>(AppUtils.LIST_MEDICINE_GROUP_MAST).pipe(map(res => {
      return res;
    }));
  }
  

  public listAllMedicineMast(medicineMstQuery: MedicineMasterQueryVO): Observable<any> {
    return this.http.post<any>(AppUtils.LIST_ALL_MEDICINE_MAST, medicineMstQuery).pipe(map(res => {
      return res;
    }, err => {
      this.toastr.warning(err, 'Alert !');
    }));
  }
  

}
