import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpEvent, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import * as AppUtils from '../common/app.utils';

@Injectable()

export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(private injector: Injector, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with auth credentials if available
        let currentUser = localStorage.getItem(AppUtils.ACCESS_TOKEN);
        if (currentUser ) {
            request = request.clone({
                setHeaders: { 
                    Authorization: ` ${currentUser}`
                }
            });
        }

        return next.handle(request);
    }


}