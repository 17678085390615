import { Component, OnInit, OnChanges, Input,ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';

import { ClcPainAssesment } from '../resources/ClcPainAssesment.resource';

import swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-his-pain-assesment',
  templateUrl: './app-his-pain-assesment.component.html',
  styleUrls: ['./app-his-pain-assesment.component.css']

})

export class PainAssesmentComponent implements OnInit {
  @Input()
  visitId: number;
  addPainAssesmentDiv:boolean;
  editPainAssesmentDiv:boolean;
  dateNow : Date = new Date();

  listCharCodes:any;
  painAssesmentColl: any;
  selectedRow: any;

   painModel: ClcPainAssesment = new ClcPainAssesment();
   EditPainModel: ClcPainAssesment = new ClcPainAssesment();

  public submitted: boolean = false;
  private userColl=this._service.persInfo;
  
  @ViewChild('ModalAddPainAssesment', { static: true }) ModalAddPainAssesment: ElementRef;
  @ViewChild('ModalEditPainAssesment', { static: true }) ModalEditPainAssesment: ElementRef;
  constructor(private icareservices: ICAREServices,private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
      this.retrive();
  }

  ngOnChanges() {
    this.listVisitPainAssesment();
  }

  public retrive(){

    this.icareservices.listPainCharCodes()
    .subscribe((response) => {
      this.listCharCodes = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
  });
    this.listVisitPainAssesment();
  }
  
  listVisitPainAssesment() {
    if (this.visitId) {
      this.icareservices.listVisitPainAssesment(this.visitId)
        .subscribe((response) => {
          this.painAssesmentColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }

  selectedVitalSign(painColl) {
    //this.selectedVitalRow = vitalColl;
    //this.editVitalItem = vitalColl;
  }

  onChangePainIntensity(){
       if(this.painModel.painIntensity>10){
        swal("Alert!", 'Pain Intensity Between 1-10 !', 'warning');    
       }
  }

  addPainAssesmentModal(){
    this.editPainAssesmentDiv = false;
    this.addPainAssesmentDiv = true;
    this.submitted = true;
    //$(this.ModalAddPainAssesment.nativeElement).modal('show');
  }

  editPainAssesmentModal(painColl){
    this.painModel.paId=painColl.paId;
    this.painModel.visitId = painColl.visitId;
    this.painModel.afiVersion = painColl.afiVersion;
    this.painModel.painIntensity=painColl.painIntensity;
    if(painColl.clcPainCharCodes){
      this.painModel.painChCode=painColl.clcPainCharCodes.chCode;
    }
    this.painModel.painLocation=painColl.painLocation;
    this.painModel.painFrequencyDesc=painColl.painFrequencyDesc;
    this.painModel.painDurationDesc=painColl.painDurationDesc;
    this.editPainAssesmentDiv = true; 
    this.submitted = true;
    //$(this.ModalEditPainAssesment.nativeElement).modal('show');
  }

  createClcPainAssesment() {

    if (this.visitId) {
        this.painModel.visitId = this.visitId;
        this.painModel.entryTime = new Date();
        this.painModel.entryBy = this.userColl.persCode;
        this.icareservices.createClcPainAssesment(this.painModel)//createClcPainAssesment
          .subscribe((response) => {
            this.listVisitPainAssesment();
            this.addPainAssesmentDiv = false;
            this.toastr.success('Pain Assesment Added successfully ', 'Save !');
            //swal("Save", 'Pain Assesment Added successfully ', 'success');
            this.painModel = new ClcPainAssesment();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.toastr.warning('Please select patient first !', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editClcPainAssesment() {
    if (this.visitId) {
      this.painModel.entryTime = new Date();
      this.painModel.entryBy = this.userColl.persCode;
      this.icareservices.editClcPainAssesment(this.painModel)
        .subscribe((response) => {
          this.listVisitPainAssesment();
          this.editPainAssesmentDiv=false;
          this.toastr.success(' Pain Assesment Updated successfully ', 'Updated !');
          //swal("Updated", ' Pain Assesment Updated successfully ' , 'success');
          this.painModel = new ClcPainAssesment();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
    else {
      this.toastr.warning('Please select patient first !', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }


  clearEditPainModal(){
    this.editPainAssesmentDiv = false; 
    this.painModel = new ClcPainAssesment();
  }

  clearCreatePainModal(){
    this.addPainAssesmentDiv = false; 
    this.painModel = new ClcPainAssesment();
  }
}
