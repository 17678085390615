export class MstPackageMast{
 packageDesc: string;
 shortCode: string;
 activeYn: string;
 packageCode: number;
 packageRate: number;
 estCode: number;
 packageDuration: number;
 public constructor() {}
}
	
  
