export class ClcPainInterventions{
 entryTime: Date;
 piRemarks: string;
 piId: number;
 visitId: number;
 piCode: number;
 entryBy: number;
 afiVersion: number;
 estCode: number;
 public constructor() {}
}
	
  
