import { HttpHeaders } from '@angular/common/http';
//Headers HTTP
export const HEADER_AUTHENTICATION: string = 'Authorization';
export const contentHeaders = new HttpHeaders();
contentHeaders.append('Content-Type', 'application/json');
export const menuHeaders = new HttpHeaders();

//Local storage key
export const ACCESS_TOKEN: string = 'access_token';
export const REFRESH_TOKEN: string = 'refresh_token';

//System Id of NEHR Application
export const SYSTEM_ID: number = 1;

//User messages
export const USERNAME_EMPTY: string = 'Username cannot be empty';
export const PASSWORD_EMPTY: string = 'Password cannot be empty';
export const INVALID_CREDENTIALS: string = 'Invalid Credentials!';
export const SESSION_EXPIRED: string = 'Session timed out';
export const INVALID_TOKEN: string = 'Invalid Token';

export const SLOT_TYPE = 3;

// currency Type:
export const IND ="<i class='fa fa-inr'></i>";
export const OMR ="OMR";

export const CONSULTATION_CHARGE: number = 7;
// Backend API URL 
//export const BACKEND_API_URL:string = 'http://209.97.136.199:8091/'; 
//export const BACKEND_API_URL:string = 'http://172.16.60.100:8091/';
//export const BACKEND_API_URL:string = 'http://139.59.170.156:8091/'; //192.168.100.4

export const BACKEND_API_URL:string = 'http://139.59.170.156:9000/AffinityMiniApi/';

// AuthenticationService
export const AUTHENTICATION_URL: string = BACKEND_API_URL + 'auth';

//change Password
export const CHANGE_PASSWORD = BACKEND_API_URL + 'changePwd';

//ErpServices
export const MENU_URL = BACKEND_API_URL + 'listUserMenus';
export const SESSION_PERSON = BACKEND_API_URL + 'getSessionPerson';
export const LIST_SESSION_PROCESSES = BACKEND_API_URL + 'listSessionProcesses';
export const LIST_USERS = BACKEND_API_URL + 'listUsers';

//IMASTServices
export const LIST_MST_APPLICATION_PARAMS  = BACKEND_API_URL + 'listMstApplicationParams';
export const LIST_GET_ESTABLISMENT = BACKEND_API_URL + 'getMyEstablishment';
export const LIST_COUNTRY_MAST = BACKEND_API_URL + 'listMstCountryMast';
export const LIST_MARITAL_STATUS_MAST = BACKEND_API_URL + 'listMstMaritalStatusMast';
export const LIST_SALUTATION_MAST = BACKEND_API_URL + 'listMstSalutationMast';
export const LIST_SEX_MAST = BACKEND_API_URL + 'listMstSexMast';
export const LIST_RELATIONSHIP_MAST = BACKEND_API_URL + 'listMstRelationshipMast';

export const CREATE_CLINIC = BACKEND_API_URL + 'createMstEstClinic';
export const LIST_CLINIC_MAST = BACKEND_API_URL + 'listClinics';
export const EDIT_CLINIC_MAST = BACKEND_API_URL + 'editMstEstClinic';
export const DELETE_CLINIC = BACKEND_API_URL + 'deleteMstEstClinic?clinicCode=';
export const GET_CLINIC = BACKEND_API_URL + 'getMstEstClinic?clinicCode=';
export const LIST_CLINICAL_DEPT_MAST = BACKEND_API_URL + 'listClinicalDepartments';
export const LIST_PERS_CLINIC_VIEW = BACKEND_API_URL + 'listPersClinicView';

export const LIST_DEPT_MAST = BACKEND_API_URL + 'listMstDepartmentMast';
export const CREATE_DEPT_MAST = BACKEND_API_URL + 'createMstDepartmentMast';
export const EDIT_DEPT_MAST = BACKEND_API_URL + 'editMstDepartmentMast';
export const DELETE_DEPT= BACKEND_API_URL + 'deleteMstDepartmentMast?deptCode=';
export const Is_SINGLE_CLINIC_ESTABLISHMENT= BACKEND_API_URL + 'isSingleClinicEstablishment';


//LOCATION MAST
export const LIST_LOCATION = BACKEND_API_URL + 'listMstLocations';
export const EDIT_LOCATION = BACKEND_API_URL + 'editMstLocation';
export const DELETE_LOCATION= BACKEND_API_URL + 'deleteMstLocation?locationCode=';


//ICAREServices
export const LIST_TRIAGE_TYPE_MAST = BACKEND_API_URL + 'listClcTriageTypeMast';
export const LIST_ARRIVAL_MODE_MAST = BACKEND_API_URL + 'listClcArrivalModeMast';
export const LIST_GENERIC_MAST = BACKEND_API_URL + 'listMedGenericMast';
export const LIST_MEDICINE_MAST = BACKEND_API_URL + 'listMedicineMast';
export const LIST_MEDICINE_GROUP_MAST = BACKEND_API_URL + 'listMedicineGroupMaster';

export const LIST_COURSE_MAST = BACKEND_API_URL + 'listMedCourseMast';
export const LIST_SERVICE_ITEMS = BACKEND_API_URL + 'listClcServiceItems';
export const LIST_BILL_ITEM_TYPE_MAST = BACKEND_API_URL + 'listBillItemTypeMast';

export const LIST_LAB_ITEMS = BACKEND_API_URL + 'listClcLabItems';
export const LIST_ICD_MAST = BACKEND_API_URL + 'listIcdMaster';
export const LIST_ALLERGY_TYPE = BACKEND_API_URL + 'listClcAllergyTypes';
export const LIST_ALLERGY_NON_MED_MAST = BACKEND_API_URL + 'listClcAllergyNonMedMast';
export const LIST_POLICY_MAST = BACKEND_API_URL + 'listMstPolicyMast';
export const LIST_PACKAGE_MAST = BACKEND_API_URL + 'listMstPackageMast';
export const LIST_APP_CLINIC_MAST = BACKEND_API_URL + 'listAppClinicMast';

export const LIST_SCHEME_MAST = BACKEND_API_URL + 'listMstSchemeMast';
export const GET_POLICY_MAST = BACKEND_API_URL + 'getMstPolicyMast?policyCode=';
export const CREATE_POLICY= BACKEND_API_URL + 'createMstPolicyMast';
export const CREATE_SCHEME= BACKEND_API_URL + 'createMstSchemeMast';
export const EDIT_SCHEME= BACKEND_API_URL + 'editMstSchemeMast';
export const EDIT_POLICY = BACKEND_API_URL + 'editMstPolicyMast';
export const DELETE_POLICY_MAST = BACKEND_API_URL + 'deleteMstPolicyMast?policyCode=';
export const DELETE_SCHEME_MAST = BACKEND_API_URL + 'deleteMstSchemeMast?schemeCode=';
export const CREATE_MST_PACKAGE_MAST = BACKEND_API_URL + 'createMstPackageMast';
export const EDIT_MST_PACKAGE_MAST = BACKEND_API_URL + 'editMstPackageMast';
export const DELETE_MST_PACKAGE_MAST = BACKEND_API_URL + 'deleteMstPackageMast?policyCode=';


export const GET_PATIENT_VIEW_COLL:string = BACKEND_API_URL + 'getPatientViewColl?patientNo=';
export const CREATE_PATIENT = BACKEND_API_URL + 'createPatient';
export const EDIT_PATIENT = BACKEND_API_URL + 'editPatient';
export const CREATE_PATIENT_POLICY_COLL = BACKEND_API_URL + 'createClcPatientPolicyColl';
export const EDIT_PATIENT_POLICY_COLL = BACKEND_API_URL + 'editClcPatientPolicyColl';
export const DELETE_PATIENT_POLICY_COLL = BACKEND_API_URL + 'deleteClcPatientPolicyColl?patPolicyId=';
export const CREATE_PATIENT_NEXTKIN = BACKEND_API_URL + 'createClcPatientNextkin';
export const EDIT_PATIENT_NEXTKIN = BACKEND_API_URL + 'editClcPatientNextkin';
export const CREATE_PATIENT_PACKAGE = BACKEND_API_URL + 'createClcPatientPackage';
export const EDIT_PATIENT_PACKAGE = BACKEND_API_URL + 'editClcPatientPackage';
export const DELETE_PATIENT_PACKAGE = BACKEND_API_URL + 'deleteClcPatientPackage?patPackageId=';
export const LIST_PATIENT = BACKEND_API_URL + 'listPatients';
export const CREATE_VISIT = BACKEND_API_URL + 'createOPDVisit';
export const LIST_PATIENT_PACKAGES_COLL  = BACKEND_API_URL + 'listClcPatientPackageView?patientId=';
export const LIST_PATIENT_POLICY_COLL  = BACKEND_API_URL + 'listClcPatientPolicyCollView?patientId=';
export const EDIT_VISIT = BACKEND_API_URL + 'editVisit';
export const LIST_PATIENT_VISITS  = BACKEND_API_URL + 'listPatientVisits?patientId=';
export const LIST_VISIT_VIEW = BACKEND_API_URL + 'listClcVisitView';
export const LIST_CLINICS = BACKEND_API_URL + 'listClinics';
export const LIST_CLINIC_VIEW = BACKEND_API_URL + 'listPersClinicView';
export const GET_VISIT_VIEW  = BACKEND_API_URL + 'getVisitView?visitId=';
export const CREATE_TRIAGE_DETAILS = BACKEND_API_URL + 'createClcTriageDetail';
export const EDIT_TRIAGE_DETAILS = BACKEND_API_URL + 'editClcTriageDetail';
export const GET_VISIT_TRIAGE_DETAILS  = BACKEND_API_URL + 'getVisitTriageDetail?visitId=';
export const LIST_VISIT_VITALS_SIGNS = BACKEND_API_URL + 'listVisitVitalSigns?visitId=';
export const CREATE_VITALS_SIGNS = BACKEND_API_URL + 'createClcVitalSigns';
export const EDIT_VITALS_SIGNS = BACKEND_API_URL + 'editClcVitalSigns';
export const DELETE_VITALS_SIGNS = BACKEND_API_URL + 'deleteClcVitalSigns?vitalRunId=';
export const LIST_VISIT_EXAM_TRAN  = BACKEND_API_URL + 'listVisitExamTran?visitId=';
export const LIST_GEN_EXAM_TEMPLATE  = BACKEND_API_URL + 'listGenExamTemplate';
export const LIST_ALL_CLC_EXAM_JSON_TEMPLATE  = BACKEND_API_URL + 'listAllClcExamJsonTemplate';

export const CREATE_EXAM_TRAN_COLL  = BACKEND_API_URL + 'createClcGenExam';
export const EDIT_EXAM_TRAN_COLL  = BACKEND_API_URL + 'editClcGenExam';
export const CREATE_PRESCRIPTION  = BACKEND_API_URL + 'createPrescription';
export const EDIT_PRESCRIPTION  = BACKEND_API_URL + 'editPrescription';
export const DELETE_PRESCRIPTION = BACKEND_API_URL + 'deletePrescription?runId=';
export const LIST_VISIT_PRESCRIPTION  = BACKEND_API_URL + 'listVisitPrescriptions?visitId=';
export const CREATE_SERVICE_ORDER  = BACKEND_API_URL + 'createClcServiceOrder';
export const EDIT_SERVICE_ORDER  = BACKEND_API_URL + 'editClcServiceOrder';
export const DELETE_SERVICE_ORDERS = BACKEND_API_URL + 'deleteClcServiceOrder?orderId=';
export const LIST_VISIT_SERVICE_ORDERS  = BACKEND_API_URL + 'listVisitServiceOrders?visitId=';
export const CREATE_ICD_TRAN  = BACKEND_API_URL + 'createClcIcdTran';
export const EDIT_ICD_TRAN  = BACKEND_API_URL + 'editClcIcdTran';
export const LIST_VISIT_ICD_TRAN  = BACKEND_API_URL + 'listVisitIcdTran?visitId=';
export const DELETE_ICD_TRAN = BACKEND_API_URL + 'deleteClcIcdTran?icdTranId=';
export const CREATE_ALLERGY_TRAN  = BACKEND_API_URL + 'createClcAllergyTran';
export const EDIT_ALLERGY_TRAN  = BACKEND_API_URL + 'editClcAllergyTran';
export const LIST_VISIT_ALLERGY_TRAN  = BACKEND_API_URL + 'listVisitAllergyTran?visitId=';
export const DELETE_ALLERGY_TRAN = BACKEND_API_URL + 'deleteClcAllergyTran?patAllergyId=';

export const LIST_NOTE_TYPE_MAST = BACKEND_API_URL + 'listClcNotesTypeMast';
export const LIST_PATIENT_NOTES = BACKEND_API_URL + 'listClcVisitNotes?visitId=';
export const DELETE_PATIENT_NOTES = BACKEND_API_URL + 'deleteClcPatientNotes?notesId=';
export const EDIT_PATIENT_NOTES  = BACKEND_API_URL + 'editClcPatientNotes';
export const CREATE_PATIENT_NOTES  = BACKEND_API_URL + 'createClcPatientNotes';
export const GET_PATIENT_NOTES  = BACKEND_API_URL + 'getClcPatientNotes?notesId=';
export const LIST_GEN_APPOINTMENT = BACKEND_API_URL + 'listClcGenAppointment';
export const CREATE_PATIENT_APPOINTMENT  = BACKEND_API_URL + 'createClcGenAppointment';
export const EDIT_PATIENT_APPOINTMENT  = BACKEND_API_URL + 'editClcGenAppointment';
export const DELETE_PATIENT_APPOINTMENT = BACKEND_API_URL + 'deleteClcGenAppointment?appId=';

export const GET_PATIENT:string = BACKEND_API_URL + 'getPatient?patientNo=';
export const GET_LATEST_VISIT = BACKEND_API_URL + 'getLatestVisit?patientId=';
export const LIST_VISIT_BILL_HDRS  = BACKEND_API_URL + 'listVisitBillHdrs?visitId=';
export const CREATE_OP_BILL_HDRS  = BACKEND_API_URL + 'createOPBillHdr?visitId=';
export const GENERATE_OP_BILL  = BACKEND_API_URL + 'generateOPBill?visitId=';

export const LIST_BILLABLE_ITEMS = BACKEND_API_URL + 'listBillableItems';
export const LIST_BILL_PAY_MODES = BACKEND_API_URL + 'listBillPayModes';
export const CREATE_BILL_TRAN_DETAIL  = BACKEND_API_URL + 'createBillTranDtl';
export const EDIT_BILL_TRAN_DETAIL  = BACKEND_API_URL + 'editBillTranDtl';
export const GET_PATIENT_BILL = BACKEND_API_URL + 'getPatientBill?billId=';
export const DELETE_BILL_TRAN_DETAIL = BACKEND_API_URL + 'deleteBillTranDtl?runId=';
export const CREATE_BILL_RECPT_PAYMENT_TRAN  = BACKEND_API_URL + 'createBillRecptPaymtTran';
export const EDIT_BILL_RECPT_PAYMENT_TRAN  = BACKEND_API_URL + 'editBillRecptPaymtTran';
export const DELETE_BILL_RECPT_PAYMENT_TRAN = BACKEND_API_URL + 'deleteBillRecptPaymtTran?runId=';
export const DELETE_BILL_RECPT= BACKEND_API_URL + 'deleteBillRecpt?runId=';
export const CANCEL_BILL_PAYMENT= BACKEND_API_URL + 'cancelBillPayment?billId=';

export const APPROVED_PATIENT_BILL = BACKEND_API_URL + 'approvePatientBill?billId=';

export const LIST_CERTIFICATE_TYPE_MAST = BACKEND_API_URL + 'listClcCertTypeMast';
export const CREATE_CERTIFICATE  = BACKEND_API_URL + 'createClcCertificate';
export const EDIT_CERTIFICATE  = BACKEND_API_URL + 'editClcCertificate';
export const LIST_PATIENT_CERTIFICATE = BACKEND_API_URL + 'listPatientCertificates?patientId=';
export const GET_CERTIFICATE_CONTENT  = BACKEND_API_URL + 'getClcCertContent?certId=';
export const APPROVE_CERTIFICATE  = BACKEND_API_URL + 'approveClcCertificate?certId=';
export const CANCEL_CERTIFICATE  = BACKEND_API_URL + 'cancelClcCertificate';

/* Bill Search */
export const LIST_BILL_HDRS  = BACKEND_API_URL + 'listBillHdrs';
export const UNAPPROVED_PATIENT_BILL = BACKEND_API_URL + 'unApprovePatientBill?billId=';
export const CANCEL_PATIENT_BILL = BACKEND_API_URL + 'cancelPatientBill?billId=';
export const LIST_BILL_VIEW_TRAN  = BACKEND_API_URL + 'listBillVTran';
export const LIST_BILL_PAYMENT_VIEW  = BACKEND_API_URL + 'listBillRecptPaymtView';
export const LIST_BILL_PAYMENT_TYPE  = BACKEND_API_URL + 'listBillPaymentType';
export const GET_BILL_DOWN_PAYMENT = BACKEND_API_URL + 'getBillDownPayment?billId=';

/* Pain Assesment */
export const LIST_PAIN_CHAR_CODES = BACKEND_API_URL + 'listPainCharCodes';
export const LIST_VISIT_PAIN_ASSESMENT = BACKEND_API_URL + 'listVisitPainAssesment?visitId=';
export const CREATE_PAIN_ASSESMENT = BACKEND_API_URL + 'createClcPainAssesment';
export const EDIT_PAIN_ASSESMENT = BACKEND_API_URL + 'editClcPainAssesment';

/* Pain Assesment */
export const LIST_VISIT_PAIN_INTERVENTION = BACKEND_API_URL + 'listVisitPainInterventions?visitId=';
export const LIST_PAIN_INTERVENTION_CODES = BACKEND_API_URL + 'listPainInterventionCodes';
export const CREATE_PAIN_INTERVENTIONS = BACKEND_API_URL + 'createClcPainInterventions';
export const EDIT_PAIN_INTERVENTIONS = BACKEND_API_URL + 'editClcPainInterventions';

// entity mast
export const LIST_REQ_ENTITY_MAST = BACKEND_API_URL + 'listReqEntityMast';

// Entity Mast
export const CREATE_REQ_ENTITY_MAST = BACKEND_API_URL + 'createReqEntityMast';
export const EDIT_REQ_ENTITY_MAST = BACKEND_API_URL + 'editReqEntityMast';

// Entity Tags
export const LIST_REQ_ENTITY_TAGS = BACKEND_API_URL + 'listReqEntityTags';
export const LIST_REQ_TAGS = BACKEND_API_URL + 'listReqTags';
export const GET_REQ_ENTITY_TAGS = BACKEND_API_URL + 'getReqEntityTags?tagId=';
export const GET_REQ_ENTITY_MAST = BACKEND_API_URL + 'getReqEntityMast?entityCode=';
export const CREATE_REQ_ENTITY_TAGS = BACKEND_API_URL + 'createReqEntityTags';
export const EDIT_REQ_ENTITY_TAGS = BACKEND_API_URL + 'editReqEntityTags';
export const DELETE_REQ_ENTITY_TAGS = BACKEND_API_URL + 'deleteReqEntityTags?tagId=';
export const LIST_REQ_ITEM_MAST = BACKEND_API_URL + 'listReqItemMast';
export const LIST_REQ_SLOT_MAST = BACKEND_API_URL + 'listReqSlotMast';

export const GET_LIST_ENTITY_SCHEDULE = BACKEND_API_URL + 'listEntitySchedule?entityCode=';
export const CREATE_ENTITY_SCHEDULE = BACKEND_API_URL + 'createEntitySchedule';
export const REMOVE_ENTITY_SCHEDULE = BACKEND_API_URL + 'removeEntitySchedule';
export const CREATE_SCHEDULE_SLOTS = BACKEND_API_URL + 'createScheduleSlots';


export const LIST_REQ_SLOT_DTL = BACKEND_API_URL + 'listReqSlotDtl?slotMastId=';
export const CREATE_REQ_SLOT_MAST = BACKEND_API_URL + 'createReqSlotMast';
export const DELETE_REQ_SLOT_DTL = BACKEND_API_URL + 'deleteReqSlotDtl?slotDtlId=';

export const LIST_REQ_EST_ENTITY_SCHEDULES = BACKEND_API_URL + 'listReqEstEntitySchedules?entityCode=';
export const LIST_REQ_SCHEDULE_SLOTS = BACKEND_API_URL + 'listReqVScheduleSlots?scheduleId=';

export const CREATE_REQ_APPOINTMENT  = BACKEND_API_URL + 'createReqAppointment';
export const RESCHEDULE_REQ_APPOINTMENT  = BACKEND_API_URL + 'rescheduleReqAppointment';
export const CANCEL_REQ_APPOINTMENT = BACKEND_API_URL + 'cancelReqAppointment?appId=';
export const LIST_MST_CITY_MAST  = BACKEND_API_URL + 'listMstCityMast';
export const LIST_MST_VILLAGE_MAST  = BACKEND_API_URL + 'listMstVillageMast';
export const LIST_CLC_ICD_MASTER  = BACKEND_API_URL + 'listClcIcdMaster';
export const GET_POLICY_COPAY_LIST  = BACKEND_API_URL + 'getPolicyCoPayList';
export const GET_PATIENT_HISTORY  = BACKEND_API_URL + 'getPatientHistory?patientId=';
export const CREATE_PATIENT_HISTORY  = BACKEND_API_URL + 'createPatientHistory';

export const LIST_PATIENT_ALL_PRESCRIPTIONS = BACKEND_API_URL + 'listPatientAllPrescriptions?patientId=';
export const LIST_DAILY_BILL_PAYMENT = BACKEND_API_URL + 'listDailyBillRecptPayment';
export const LIST_ALL_MEDICINE_MAST = BACKEND_API_URL + 'listAllMedicineMast';
export const SAVE_MEDICINE = BACKEND_API_URL + 'saveMedicine';


export const AVATAR_LOCATION: string = 'assets/images/avatar.jpg';
export const PAGESIZE_MINIMIZED: number = 4;              //No.of items in table when in minimized state
export const PAGESIZE_MAXIMIZED: number = 15;             //No.of items in table when in minimized state
export const PAGE_INDEX_SIZE_MINIMIZED: number = 5;       //No.of the pages displaying before ... button in pagination bar
export const PAGE_INDEX_SIZE_MAXIMIZED: number = 8;       //No.of the pages displaying before ... button in pagination bar

