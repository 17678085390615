import { Component, OnInit, OnChanges, Input,ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';

import { ClcPainInterventions } from '../resources/ClcPainInterventions.resource';

import swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-his-pain-intervention',
  templateUrl: './app-his-pain-intervention.component.html',
  styleUrls: ['./app-his-pain-intervention.component.css']

})

export class PainInterventionComponent implements OnInit {
  @Input()
  visitId: number;
  addPainInterventionDiv: boolean;
  editPainInterventionDiv: boolean;
  dateNow : Date = new Date();

  listInterventionCodes:any;
  painInterventionColl: any;
  selectedRow: any;

   painInterventionModel: ClcPainInterventions = new ClcPainInterventions();
   EditpainInterventionModel: ClcPainInterventions = new ClcPainInterventions();

  public submitted: boolean = false;
  private userColl=this._service.persInfo;
  
  @ViewChild('ModalAddPainIntervention', { static: true }) ModalAddPainIntervention: ElementRef;
  @ViewChild('ModalEditPainIntervention', { static: true }) ModalEditPainIntervention: ElementRef;
  constructor(private icareservices: ICAREServices,private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
      this.retrive();
  }

  ngOnChanges() {
    this.listVisitPainInterventions();
  }

  public retrive(){

    this.icareservices.listPainInterventionCodes()
    .subscribe((response) => {
      this.listInterventionCodes = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
  });

  }
  
  listVisitPainInterventions() {
    if (this.visitId) {
      this.icareservices.listVisitPainInterventions(this.visitId)
        .subscribe((response) => {
          this.painInterventionColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
  }



  addPainInterventionModal(){
    this.editPainInterventionDiv = false;
    this.addPainInterventionDiv = true;
    this.submitted = true;
    //$(this.ModalAddPainIntervention.nativeElement).modal('show');
  }

  editPainInterventionModal(piColl){
   this.painInterventionModel.piId=piColl.piId;
    this.painInterventionModel.visitId = piColl.visitId;
    this.painInterventionModel.afiVersion = piColl.afiVersion;
    this.painInterventionModel.piCode=piColl.clcPainInterventionCodes.piCode;
    this.painInterventionModel.piRemarks=piColl.piRemarks;
    this.editPainInterventionDiv = true; 
    this.submitted = true;
    
    //$(this.ModalEditPainIntervention.nativeElement).modal('show');
  }

  createClcPainAssesment() {

    if (this.visitId) {
        this.painInterventionModel.visitId = this.visitId;
        this.painInterventionModel.entryTime = new Date();
        this.painInterventionModel.entryBy = this.userColl.persCode;
        this.icareservices.createClcPainInterventions(this.painInterventionModel)//createClcPainAssesment
          .subscribe((response) => {
            this.listVisitPainInterventions();
            this.addPainInterventionDiv = false;
            this.toastr.success('Pain Intervention Added successfully ', 'Save !');
            //swal("Save", 'Pain Intervention Added successfully ', 'success');
            this.painInterventionModel = new ClcPainInterventions();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.toastr.warning('Please select patient first ', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editClcPainIntervention() {
    if (this.visitId) {
      this.painInterventionModel.entryTime = new Date();
      this.painInterventionModel.entryBy = this.userColl.persCode;
      this.icareservices.editClcPainInterventions(this.painInterventionModel)
        .subscribe((response) => {
          this.listVisitPainInterventions();
          this.editPainInterventionDiv=false;
          this.toastr.success('Pain Intervention Updated successfully ', 'Updated !');
          //swal("Updated", ' Pain Intervention Updated successfully ' , 'success');
          this.painInterventionModel = new ClcPainInterventions();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }
    else {
      this.toastr.warning('Please select patient first ', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }



  clearEditPainModal(){
    this.editPainInterventionDiv = false; 
    this.painInterventionModel = new ClcPainInterventions();
  }

  clearCreatePainModal(){
    this.addPainInterventionDiv = false; 
    this.painInterventionModel = new ClcPainInterventions();
  }
}
