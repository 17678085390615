export class ClcPainAssesment{
 entryTime: Date;
 painLocation: string;
 painRadiatingLocation: string;
 painFrequencyDesc: string;
 painDurationDesc: string;
 painAggrAllevFactor: string;
 painRemarks: string;
 paId: number;
 visitId: number;
 entryBy: number;
 painIntensity: number;
 painChCode: number;
 sourceTypeCode: number;
 sourceRunId: number;
 afiVersion: number;
 estCode: number;
 public constructor() {}
}
	
  
