export class BillRecptPaymtTran{
 tranDate: Date;
 docDate: Date;
 entryTime: Date;
 tranType: string;
 docNumber: string;
 docBank: string;
 remarks: string;
 runId: number;
 billId: number;
 amount: number;
 paymentMode: number;
 advanceVisitId: number;
 estCode: number;
 voucherId: number;
 adjustId: number;
 enteredBy: number;
 invIssueRecptTranId: number;
 counterCode: number;
 cashCloseId: number;
 afiVersion: number;
/* added newly on 09/05/2022 for getDownPayment */
 billPaymentType: number;
 creditId: number;
 public constructor() {}
}
	
  
