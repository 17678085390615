export class MstEstClinicsExt  {
    apptClinicYn: string;
    activeYn: string;
    clinicShName: string;
    clinicName: string;
    afiVersion: number;
    categoryCode: number;
    consultationCharge: number;
    visitTypeCode: number;
    deptCode: number;
    estCode: number;
    clinicCode: number;
    deptName:String;
    public constructor() {}
}