import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ClcVitalSigns } from '../resources/ClcVitalSigns.resource';
import { ICAREServices } from '../services/Icare.Services';

import swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-his-vitals',
  templateUrl: './app-his-vitals.component.html',
  styleUrls: ['./app-his-vitals.component.css']

})

export class VitalComponent implements OnInit {
  @Input()
  visitId: number;

  @Input()
  public editYN: string;


  vitalColl: any;
  selectedVitalRow: any;
  vitalItem: ClcVitalSigns = new ClcVitalSigns();
  editVitalItem: ClcVitalSigns = new ClcVitalSigns();
  public submitted: boolean = false;
  private userColl = this._service.persInfo;

  @ViewChild('ModalcreateVitals', { static: true }) ModalcreateVitals: ElementRef;
  @ViewChild('ModalEditVitals', { static: true }) ModalEditVitals: ElementRef;
  addVitalsDiv: boolean;
  editVitalsDiv: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;
  constructor(private icareservices: ICAREServices, private _service: ErpServices, private toastr: ToastrService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getVisitVitalSigns();
  }

  getVisitVitalSigns() {
    if (this.visitId) {
      this.icareservices.listVisitVitalSigns(this.visitId)
        .subscribe((response) => {
          this.vitalColl = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  selectedVitalSign(vitalColl) {
    this.selectedVitalRow = vitalColl;
    this.editVitalItem = vitalColl;
  }

  addVitalsModal() {
    this.editVitalsDiv = false;
    this.addVitalsDiv = true;
    this.submitted = true;
    // $(this.ModalcreateVitals.nativeElement).modal('show');
  }

  editVitalsModal() {
    this.submitted = true;
    this.editVitalsDiv = true;
    //$(this.ModalEditVitals.nativeElement).modal('show');
  }

  createVitals() {

    if (this.visitId) {
      this.vitalItem.visitId = this.visitId;
      this.vitalItem.vitalDate = new Date();
      this.vitalItem.createdDate = new Date();
      this.vitalItem.createdBy = this.userColl.persCode;
      this.vitalItem.vitalStatus = 1;
      this.icareservices.createClcVitals(this.vitalItem)
        .subscribe((response) => {
          this.getVisitVitalSigns();
          this.addVitalsDiv = false;
          this.toastr.success('Vitals Added successfully ', 'Save !');
          //swal("Save", 'Vitals Added successfully ', 'success');
          this.vitalItem = new ClcVitalSigns();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });


    }
    else {
      this.toastr.warning('Please select patient first !', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  editVitals() {
    if (this.visitId) {
      this.editVitalItem.visitId = this.visitId;
      this.editVitalItem.vitalDate = new Date();
      this.editVitalItem.createdDate = new Date();
      this.editVitalItem.createdBy = this.userColl.persCode;
      this.editVitalItem.vitalStatus = 1;
      this.icareservices.editClcVitals(this.editVitalItem)
        .subscribe((response) => {
          this.getVisitVitalSigns();
          this.editVitalsDiv = false;
          //swal("Updated", ' Vitals Updated successfully ' , 'success');
          this.toastr.success(' Vitals Updated successfully ', 'Updated !');
          this.editVitalItem = new ClcVitalSigns();
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      this.toastr.warning('Please select patient first !', 'Alert !');
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }



  deleteVitalSign() {

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"

    }).then((result) => {
      if (result.value) {
        this.icareservices.deleteClcVitals(this.selectedVitalRow.vitalRunId)
          .subscribe((response) => {
            this.getVisitVitalSigns();

          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }
    });

  }


  get onChangeCelsiusToFehr() {
    let cTemp;

    if (this.vitalItem.temp) {
      cTemp = (this.vitalItem.temp * 1.8) + 32;
    }
    if (this.editVitalItem.temp) {
      cTemp = (this.editVitalItem.temp * 1.8) + 32;
    }
    
    return cTemp;
  }

  get celsiusToFhr(){
    let tempFhr;
    if(this.vitalColl){
       tempFhr= (this.vitalColl[0].temp * 1.8) + 32;
    }
    return tempFhr;
  } 

  get calculateBMI() {
    let bmi;
     //[weight (kg) / height (cm) / height (cm)] x 10,000
    if (this.vitalItem.weight && this.vitalItem.height) {
      bmi = (this.vitalItem.weight / this.vitalItem.height / this.vitalItem.height) * 10000;
    } 
    if (this.editVitalItem.weight && this.editVitalItem.height) {
      bmi = (this.editVitalItem.weight / this.editVitalItem.height / this.editVitalItem.height) * 10000;
    }   
    return bmi;
  }

  calBMI(itemCOll) {
    let bmi;
     //[weight (kg) / height (cm) / height (cm)] x 10,000
    if (itemCOll.weight && itemCOll.height) {
      bmi = (itemCOll.weight / itemCOll.height / itemCOll.height) * 10000;
    }      
    return bmi;
  }

  clearEditVital() {
    this.editVitalsDiv = false;
    this.editVitalItem = new ClcVitalSigns();
  }

  clearAddVital() {
    this.addVitalsDiv = false;
    this.vitalItem = new ClcVitalSigns();
  }
}
