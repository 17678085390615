
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MstPackageMast } from '../resources/MstPackageMast.resource';
import { ICAREServices } from '../services';
import swal from 'sweetalert2';


@Component({
  selector: 'app-mast-packages',
  templateUrl: './app-mast-packages.component.html',
  styleUrls: ['./app-mast-packages.component.scss']
})
export class AppMastPackagesComponent implements OnInit {

  addNewPackage: boolean;
  editPackage: boolean;

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.listMsgPackage();
  }

  constructor(private icareservices: ICAREServices, private toastr: ToastrService) { }

  modelColl: MstPackageMast[] = [];
  modelItem: MstPackageMast = new MstPackageMast();

  packageColl: any;
  retModelColl: any;
  listPackageItem:any;

  private retrieve() {

  }

  private listMsgPackage() {
    this.icareservices.listMstPackageMast().subscribe(response => {
      this.packageColl = response;

    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

  };

  editItem(data) {
    this.addNewPackage = true;
    this.modelItem.packageCode = data.packageCode;
    this.modelItem.packageDesc = data.packageDesc;
    this.modelItem.shortCode = data.shortCode;
    this.modelItem.packageRate = data.packageRate;
    this.modelItem.activeYn = data.activeYn;
    this.modelItem.packageDuration = data.packageDuration;

  };

    saveInfo() {
      if (isNaN(this.modelItem.packageCode)) {
        this.icareservices.createMstPackageMast(this.modelItem)
          .subscribe((response) => {
            this.listMsgPackage();
            this.toastr.success('Package created successfully !', 'Save !');
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }
      else {
        this.icareservices.editMstPackageMast(this.modelItem)
          .subscribe((response) => {
            this.listMsgPackage();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
        this.toastr.success('Package Updated successfully !', 'Save !');
      }
      this.clearModel();
  }

  removeItem(data) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"

    }).then((result) => {
      if (result.value) {
        this.icareservices.deleteMstPackageMast(data.packageCode)
          .subscribe((response) => {          
          }, err => {
            this.toastr.warning(err, 'Alert !');
          });
      }
    });
  }

  clearModel() {
    this.modelItem = new MstPackageMast();
    this.addNewPackage = false;
    this.listPackageItem=[];
  };

  newPackageMast() {
    this.addNewPackage = true;
  }

  bindPackageItem(item){
    if (this.listPackageItem == item) {
      this.listPackageItem = null;
    }
    else {
      this.listPackageItem = item;      
    }
  }

  // setCounterGroup() {
  //   this.packageColl.forEach(element => {
  //     this.listPackageItem.forEach(k => {
  //       if (k.packageCode == element.packageCode) {
  //         k.clinicName = element.clinicNameEn;
  //       }
  //     })
  //   })
  // }

}
