export class ClcBillQueryVO{
 toBillDate: Date;
 fromBillDate: Date;
 patientNo: number;
 billType: number;
 itemId: number;
 createdBy: number;
 billStatus: number;
 visitId: number;
 patientId: number;
 policyCode: number;
 billNo: number;
 public constructor() {}
}
	
  
