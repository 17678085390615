import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { ErpServices } from 'src/app/services/index';
import { ClcPatientHistory } from '../resources/ClcPatientHistory.resource';

@Component({
  selector: 'app-his-order-report',
  templateUrl: './app-his-order-report.component.html',
  styleUrls: ['./app-his-order-report.component.css']

})

export class OrderReportComponent implements OnInit, OnDestroy {
  @Input()
  public visitId: number;
  @Input()
  public patientId: number;

  public persItem: any;
  public estInfo = this._service.estInfo;
  vitalColl: any[];
  listIcdItem: any[];
  listVisitPres: any[];
  listServiceColl: any[];
  listAllergyItem: any[];
  printDate: any;
  icdMstColl: any;
  patHistoryColl: any;
  clcPatientHistoryColl: ClcPatientHistory[] = [];

  constructor(private icareservices: ICAREServices, public datepipe: DatePipe, private _service: ErpServices, private toastr: ToastrService) {
    // this.retrieve();
  }

  ngOnInit() {
    this.retrieve();
    this.printDate = this.formatDate();
  }

  ngOnChanges() {
    this.retrieve();
  }

  ngOnDestroy() {

  }
  private retrieve() {
    this.persItem = [];
    this.searchVisit();
    this.getVisitVitalSigns();
    this.listVisitIcdTran();
    this.listVisitPrescriptions();
    this.listServiceOrder();
    this.listAllergyTran();
    this.icdMstColl = this._service.icdMast;
    if (this.patientId) {
      this.getPatientHistory(this.patientId)
    }
  }

  /* search patient list */
  searchVisit() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.persItem = response;
        });
    }

  }

  getVisitVitalSigns() {
    if (this.visitId) {
      this.icareservices.listVisitVitalSigns(this.visitId)
        .subscribe((response) => {
          this.vitalColl = response;
        });
    }
  }

  listAllergyTran() {
    if (this.visitId) {
      this.icareservices.listVisitAllergyTran(this.visitId)
        .subscribe((response) => {
          this.listAllergyItem = response;

        });
    }
  }

  listVisitIcdTran() {
    if (this.visitId) {
      this.icareservices.listVisitIcdTran(this.visitId)
        .subscribe((response) => {
          this.listIcdItem = response;

        });
    }
  }

  listVisitPrescriptions() {
    if (this.visitId) {
      this.icareservices.listVisitPrescriptions(this.visitId)
        .subscribe((response) => {
          this.listVisitPres = response;

        });
    }
  }

  listServiceOrder() {
    if (this.visitId) {
      this.icareservices.listVisitServiceOrders(this.visitId)
        .subscribe((response) => {
          this.listServiceColl = response;

        });
    }
  }

  getICD(icdCode) {
    if (icdCode != undefined) {
      let item = this.icdMstColl.find(item => item.icd === icdCode);
      if (item) {
        return this.icdMstColl.find(item => item.icd === icdCode).disease;
      }
    }
  }

  getPatientHistory(patId) {
    this.clcPatientHistoryColl = [];
    this.patHistoryColl = [];
    this.icareservices.getPatientHistory(patId)
      .subscribe((response: any) => {
        this.patHistoryColl = this.groupByType(response.filter(item => item.status !== null || item.remarks !== null));

      }, err => {
        this.toastr.warning(err, 'Alert !');
      });
  }

  groupByType(array) {
    let result1 = array.reduce((r, { historyTypeName }) => {
      if (!r.some(o => o.historyTypeName == historyTypeName)) {
        r.push({ historyTypeName, groupItem: array.filter(v => v.historyTypeName == historyTypeName) });
      }
      return r;
    }, []);
    return result1;
  }

  formatDate() {

    let dt = new Date();

    let day;
    if (dt.getDate() < 10) {
      day = '0' + dt.getDate();
    } else {
      day = dt.getDate();
    }

    let month = dt.getMonth() + 1;

    let year = (dt.getFullYear() + '').substr(2, 2);

    let hours = dt.getHours();

    let minutes = dt.getMinutes();

    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  getAge(dateString) {

    var birthdate = new Date(dateString).getTime();
    var now = new Date().getTime();
    // now find the difference between now and the birthdate
    var n = (now - birthdate)/1000;
  
    if (n < 604800) { // less than a week
      var day_n = Math.floor(n/86400);
      return day_n + ' D' + (day_n > 1 ? 's' : '');
    } else if (n < 2629743) {  // less than a month
      var week_n = Math.floor(n/604800);
      return week_n + ' W' + (week_n > 1 ? 's' : '');
    } else if (n < 63113852) { // less than 24 months
      var month_n = Math.floor(n/2629743);
      return month_n + ' M' + (month_n > 1 ? 's' : '');
    } else { 
      var year_n = Math.floor(n/31556926);
      return year_n + ' Y' + (year_n > 1 ? 's' : '');
    }
  }

  printDetail(): void {
    let printContents, popupWin;
    printContents = document.getElementById('printdetails').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
           <html>
               <head>
                   <title></title>
                   <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
               <style>
                  img {
                    position: initial;
                    top: 0%;
                    left: 0%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(3%, 0%);
                    padding-top: 0px;
                    margin-bottom: 20px;
                    height: 100px;
                    margin-left: 20%;
                }
                body{
                  
                  font-family: monospace; 
               }
               
               </style>
                   </head>
               <body onload="window.print();window.close()">
      
          
               ${printContents}
              
               </body>
           </html>`
    );
    popupWin.document.close();


  };
}
