export class ClcCertificates{
 validFrom: Date;
 validTo: Date;
 createdTime: Date;
 cancelledTime: Date;
 accompanyName: string;
 remarks: string;
 certStatus: string;
 jobDetails: string;
 cancelRemarks: string;
 certId: number;
 visitId: number;
 certTypeCode: number;
 createdBy: number;
 cancelledBy: number;
 estCode: number;
 afiVersion: number;
 noOfDays: number;
 public constructor() {}
}
	
  
