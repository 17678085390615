import { Component, OnInit } from '@angular/core';

import { ErpServices } from 'src/app/services/index';

@Component({
  selector: 'app-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.css']
})
export class DesktopComponent implements OnInit {

 public _establishmentInfo:any=[];

  constructor( private _service: ErpServices) { }

  ngOnInit() {

    this._service.getMyEstablishment().subscribe(res => {
			this._establishmentInfo=res;
    })
    
   
  }

}
