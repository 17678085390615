import {MstPackageMast} from './MstPackageMast.resource';
export class ClcPatientPackageView{
 packageStartDate: Date;
 registerDate: Date;
 packageEndDate: Date;
 patPackageId: number;
 patientId: number;
 packageCode: number;
 billRunId: number;
 patPolicyId: number;
 estCode: number;
 afiVersion: number;
 mstPackageMast: MstPackageMast;
 public constructor() {}
}
	
  
