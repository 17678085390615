import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { ClcTriageDetailView } from '../resources/ClcTriageDetailView.resource';
import { ClcArrivalModeMast } from '../resources/ClcArrivalModeMast.resource';
import { ClcTriageTypeMast } from '../resources/ClcTriageTypeMast.resource';

import { ErpServices } from 'src/app/services/index';
import { IMASTServices } from '../services';
import { ICAREServices } from '../services/Icare.Services';
import { ClcTriageDetails } from '../resources/ClcTriageDetails.resource';
import swal from 'sweetalert2';
import { repeat } from 'rxjs-compat/operator/repeat';

@Component({
  selector: 'app-his-triage-bar',
  templateUrl: './app-his-triage-bar.html',
  styleUrls: ['./app-his-triage-bar.css']

})

export class TriageComponent implements OnInit {
  @Input()
  visitId: number;

  bsValue = new Date();
  triageType: any;
  arrivalColl: any;
  config:any;
  nurseAssessment:any;
  actionTaken:any;
  triCollItem: ClcTriageDetailView = new ClcTriageDetailView();
  arrvModeMst:ClcArrivalModeMast=new ClcArrivalModeMast();
  triTypeMst :ClcTriageTypeMast=new ClcTriageTypeMast();
  triModelItem:ClcTriageDetails=new ClcTriageDetails();
  private userColl=this._service.persInfo;
  public btnDisablCreateTriage:boolean=false;

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices,private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.config=this.angularEditorConfig;
    this.doInit();    
  }

  doInit() {
    this.retrieve();
  }

  ngOnChanges() {
    this.clearModel();
    this.getVisitTriageDetails();
  }
 
  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: 'initial',
    sanitize: true,
    toolbarHiddenButtons: [
      ['underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent'],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
      ]
  };
  
  private retrieve() {

    this.icareservices.listClcTriageTypeMast().subscribe(response => {
      this.triageType = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

    this.icareservices.listClcArrivalModeMast().subscribe(response => {
      this.arrivalColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

  }

  getVisitTriageDetails(){
    if (this.visitId) {
       this.icareservices.getVisitTriageDetails(this.visitId)
          .subscribe((response) => {
             if(response){
              this.triCollItem = response;
              this.nurseAssessment=response.nurseAssessment;
              this.actionTaken=response.actionTaken;
              if(this.triCollItem.clcArrivalModeMast){
                this.arrvModeMst=this.triCollItem.clcArrivalModeMast;
              }
              if(this.triCollItem.clcTriageTypeMast){
                this.triTypeMst=this.triCollItem.clcTriageTypeMast;
              }
             }
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }
  }

  /* create Triage details */
  createTriage() {
  if(this.visitId){
    this.btnDisablCreateTriage=true;
    if (this.triCollItem.triageId ) {

      this.triModelItem.visitId=this.visitId;
      this.triModelItem.createdBy=this.userColl.persCode;
      this.triModelItem.modeOfArrival=this.arrvModeMst.arrModeCode;
      this.triModelItem.triageType=this.triTypeMst.typeCode;

      this.triModelItem.nurseAssessment=this.nurseAssessment; //this.triCollItem.nurseAssessment;
      this.triModelItem.actionTaken=this.actionTaken;//this.triCollItem.actionTaken;
      
      this.triModelItem.triageId=this.triCollItem.triageId;
      this.triModelItem.createdDate=new Date();
      this.triModelItem.triageTime=this.triCollItem.triageTime;
      this.triModelItem.afiVersion=this.triCollItem.afiVersion;

      this.icareservices.editTriageDetails(this.triModelItem)
      .subscribe((response) => {
        this.btnDisablCreateTriage=false;
        if(response){
          //swal("Edited", 'Edited successfully :' + response.visitId, 'success');
          this.toastr.success('Edited successfully :' + response.visitId,'Edited !');
          this.getVisitTriageDetails();
        }
      }, err => {
        this.toastr.warning( err.split(":")[3],'Alert !');           
      });
      
    }
    else {
      this.triModelItem.visitId=this.visitId;
      this.triModelItem.createdBy=this.userColl.persCode;
      this.triModelItem.modeOfArrival=this.arrvModeMst.arrModeCode;
      this.triModelItem.triageType=this.triTypeMst.typeCode;

      this.triModelItem.nurseAssessment=this.nurseAssessment;//this.triCollItem.nurseAssessment;
      this.triModelItem.actionTaken=this.actionTaken;//this.triCollItem.actionTaken;

      this.triModelItem.createdDate=new Date();
      this.triModelItem.triageTime=new Date();
       this.icareservices.createTriageDetails(this.triModelItem)
          .subscribe((response) => {
            this.btnDisablCreateTriage=false;
            if(response){
              //swal("Save", 'Added successfully :' + response.visitId, 'success');
              this.toastr.success('Added successfully :' + response.visitId,'Save !');
              this.getVisitTriageDetails();
            }
              
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          }); 
    }
  }
  else{
    this.btnDisablCreateTriage=false;
    //swal("Oops!", 'Please select patient first !', 'warning');
    this.toastr.warning( 'Please select patient first !','Alert !');
  }
  }

  clearModel(){
     //this.triCollItem=new ClcTriageDetailView();
    this.arrvModeMst=new ClcArrivalModeMast();
    this.triTypeMst=new ClcTriageTypeMast();
    this.triModelItem=new ClcTriageDetails();
    this.nurseAssessment='';
    this.actionTaken='';
  }
}
