import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { ClcPatientHistory } from '../resources/ClcPatientHistory.resource';


declare var $;

@Component({
  selector: 'app-his-past-history',
  templateUrl: './app-his-past-history.component.html',
  styleUrls: ['./app-his-past-history.component.css']
})
export class AppHisPastHistoryComponent implements OnInit {

  @Input()
  public visitId: number;
  @Input()
  public patientId: number;

  @Input()
  public editYN: string;

  activeTabIndex:0;
  addPastHstryDiv: boolean;
  editPastHstryDiv: boolean;
  public showStyle: boolean = false;
  public tabs: any;
  patHistoryColl: any ;
  clcPatientHistoryColl: ClcPatientHistory[] = [];
  clcPatientHistoryItem: ClcPatientHistory = new ClcPatientHistory();
  public patStatus = [{
    "options": "Yes",
    "value": "A"
  },
  {
    "options": "No",
    "value": "D"
  }
  ]
  //variables for the pagination
  currentPage = 1;
  page: number;
   arr: any=[];
  @ViewChild('pastHistoryModal', { static: true }) pastHistoryModal: ElementRef;

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices, private toastr: ToastrService) { }

  ngOnInit() {
   
  }

  ngOnChanges() {
    if (this.patientId) {
      this.getPatientHistory(this.patientId)
    }
  }

  //item --> item in array
  //i --> index of item
  //ar --> array reference, (in this case "list")
  openPastHistoryModal() {
    if (this.patientId) {
      this.getPatientHistory(this.patientId)
    }
    $(this.pastHistoryModal.nativeElement).modal('show');
  }

  getPatientHistory(patId){
    this.clcPatientHistoryColl = [];
    this.patHistoryColl=[]; 
    this.icareservices.getPatientHistory(patId)
      .subscribe((response: any) => {
        //this.patHistoryColl=response.filter(item => item.status !== null || item.remarks !==null);
        this.patHistoryColl=this.groupByType(response.filter(item => item.status !== null || item.remarks !==null));

        let tabs = response.filter((item, i, ar) => ar.indexOf(ar.find(t => t.historyGroup === item.historyGroup)) === i);
         
        for (let tab of tabs) {
          this.clcPatientHistoryColl.push(response.filter(item => item.historyGroup === tab.historyGroup));          
        }
       
      }, err => {
        this.toastr.warning(err, 'Alert !');
      });
  }

  groupByType(array) {
 
    let result1= array.reduce((r, { historyTypeName }) => {
      if (!r.some(o => o.historyTypeName == historyTypeName)) {
        r.push({ historyTypeName, groupItem: array.filter(v => v.historyTypeName == historyTypeName) });
      }
      return r;
    }, []);
    return result1;
  }

  savePatPastHistory(itemColl) {

    const arrMap = itemColl.flatMap(m => m);
  
    //  let pastHstryItemColl=arrMap.filter(item => item.status == null || item.remarks !== null);

    // this.clcPatientHistoryItem = arrMap.filter(item => {
    //   if (item.status == null) {
    //     arrMap.map((obj) => {
    //       obj.patientId = this.patientId;
    //       obj.visitId = this.visitId;
    //       obj.enteredBy = this._service.persInfo.persCode;
    //       obj.status = null;
    //     })
    //   } else if (item.status !== null && item.remarks !== null){
    //     arrMap.map((obj) => {
    //       obj.patientId = this.patientId;
    //       obj.visitId = this.visitId;
    //       obj.enteredBy = this._service.persInfo.persCode;
    //       obj.status = null;
    //     })
    //   }
    //   return true;
    // }) SomeArrayValues.filter(x=> x.id !== idNameDetailsColorDto.id).map(ids => (ids.id))

    let pastHstryItemColl =arrMap.filter(item => {
     
      if (item.remarks !== null && item.status === null) {
        return (
          item.patientId = this.patientId,
          item.visitId = this.visitId,
          item.enteredBy =this._service.persInfo.persCode,
          item.status = 'A'
        )
      }
      if (item.status !== null && item.remarks !== null || item.status !== null && item.remarks == null) {
        return (
          item.patientId = this.patientId,
          item.visitId = this.visitId,
          item.enteredBy =this._service.persInfo.persCode      
        )
      }   
      if (item.remarks === null && item.status === null && item.deleteYN !==null && item.visitId !==null) {
        return (
          item        
        )
      }  
      return false;
    });

    
    console.log (pastHstryItemColl)


      // let pastHstryItemColl = arrMap.filter(item => {
      //   if (item.remarks !== null && item.status === null) {    // &&   
      //     return  arrMap.map((obj) => {
      //       obj.patientId = this.patientId;
      //       obj.visitId = this.visitId;
      //       obj.enteredBy =this._service.persInfo.persCode;
      //       obj.status = 'A';
      //     })
          
      //   }if (item.status !== null && item.remarks !== null || item.status !== null && item.remarks == null) {       
      //     return  arrMap.map((obj) => {
      //       obj.patientId = this.patientId;
      //       obj.visitId = this.visitId;
      //       obj.enteredBy =this._service.persInfo.persCode;       
      //     })
      //   }
      // })

     this.clcPatientHistoryItem=pastHstryItemColl;

     this.icareservices.createPatientHistory(this.clcPatientHistoryItem).subscribe
      ((response) => {
        this.getPatientHistory(this.patientId);
        this.toastr.success('Patient History Added successfully!', 'Save !');
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
  
  }

  toggeleAddPastHstry() {
    this.addPastHstryDiv = true;
    this.editPastHstryDiv = false;
  }

  toggeleEditPastHstry() {
    this.addPastHstryDiv = false;
    this.editPastHstryDiv = true;
  }

  clearPastFieldItem(item){
    console.log(item)
    //this.clcPatientHistoryColl[this.clcPatientHistoryColl].item
  }

  getSidenavStyle() {
    if (this.showStyle) {
      return "250px";
    } else {
      return "0";
    }
  }

  getMainStyle() {
    if (this.showStyle) {
      return "250px";
    } else {
      return "0";
    }
  }

  getToogle() {
    if (this.showStyle) {
      return "toggled";
    } else {
      return "toggled-2";
    }
  }


  // this.clcPatientHistoryColl.forEach(e => {
  //   e.patientId = this.patientId;
  //   e.visitId = this.visitId;
  //  });
}
