export class ClcPatientQueryVO{
 birthDateTo: Date;
 birthDateFrom: Date;
 sexCode: string;
 patientName: string;
 identityNo: string;
 mobileNo: string;
 patientNo: string;
 patientId: number;
 nationalityCode: number;
 public constructor() {}
}
	
  
