import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { ICAREServices } from '../services/Icare.Services';
import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';
import { ClcVisitViewExt } from '../resources/ext/ClcVisitViewExt';
import { DatePipe } from '@angular/common';

declare var $;

@Component({
  selector: 'app-rep-visit-view',
  templateUrl: './app-rep-visit-view.component.html',
  styleUrls: ['./app-rep-visit-view.component.css']

})

export class VisitStatisticsComponent implements OnInit {
  @Input()
  visitId: number;
  visitReport: any;

  @Output() sendDataToParent = new EventEmitter<any>();

  bsValue = new Date();
  selectedRow: Number;
  private submitted: boolean = false;

  public patientItem: any = [];
  listClinics: any;
  listVisitItem: any;

  visitModel: ClcVisitQueryVO = new ClcVisitQueryVO();

  visitViewExtModel: ClcVisitViewExt;
  visitViewExtColl: ClcVisitViewExt[];

  @ViewChild('visitSearchModal', { static: false }) visitSearchModal: ElementRef;
  constructor(private icareservices: ICAREServices, public datepipe: DatePipe, private toastr: ToastrService) { }

  ngOnInit() {

    this.retrive();
  }

  private retrive() {
    this.searchPatientList();
    this.icareservices.listClinics().subscribe(response => {
      this.listClinics = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  ngOnChanges() {
    this.searchPatientList();
  }
  timeFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd HH:mm');
  }
  dateFormat(param) {
    return this.datepipe.transform(param, 'yyyy-MM-dd');
  }


  selectedVisit(visitColl) {
    this.selectedRow = visitColl;
  }

  selectedVisitLisitRow(visitColl) {

    /*  if (visitColl.visitId) {
       this.visitId = visitColl.visitId;
       this.submitted = false;
       $(this.visitSearchModal.nativeElement).modal('hide');
       console.log(this.visitId);
     }
     else{ */
    this.sendDataToParent.emit(visitColl);
    //}
  }


  /* search patient list */
  searchPatientList() {
    if (this.visitModel.visitFromDate) {
      this.icareservices.listClcVisitView(this.visitModel)
        .subscribe(response => {
          this.listVisitItem = response;

        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
    else {
      var today = new Date();
      var yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
      this.visitModel.visitFromDate = yesterday;
      this.icareservices.listClcVisitView(this.visitModel)
        .subscribe(response => {
          this.listVisitItem = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }
  exportVisit() {
    this.icareservices.listClcVisitView(this.visitModel)
      .subscribe((response) => {
        this.visitViewExtColl = [];
        for (let rec of response) {
          this.visitViewExtModel = new ClcVisitViewExt();

          this.visitViewExtModel.patientNo = rec.patientNo;
          this.visitViewExtModel.patientName = rec.patientName;
          this.visitViewExtModel.birthDate = this.dateFormat(rec.birthDate);
          this.visitViewExtModel.sexCode = rec.sexCode;
          this.visitViewExtModel.visitTime = this.timeFormat(rec.visitTime);
          this.visitViewExtModel.clinicName = rec.clinicName;
          this.visitViewExtModel.deptName = rec.deptName;
          this.visitViewExtModel.visitStatusDesc = rec.visitStatusDesc;
          this.visitViewExtModel.visitTypeDesc = rec.visitTypeDesc;
          this.visitViewExtModel.packageDesc = rec.packageDesc;
          this.visitViewExtModel.policyName = rec.policyName;
          this.visitViewExtModel.mobileNo = rec.mobileNo;

          this.visitViewExtColl.push(this.visitViewExtModel);
        }
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          useBom: true,
          headers: Object.keys(this.visitViewExtModel)
        };

        new Angular2Csv(this.visitViewExtColl, 'Visit Report', options);

      });


  }



  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('visitList').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
       <html>
           <head>
               <title></title>
               <style>

               #visit {
                font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }
            
            #visit td, #visit th {
                border: 1px solid #ddd;
                padding: 8px;
            }
             #visit th {
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                color: #7a4c41;
            }
          
            .my-header {
                background: red;
                top: 0;
                left: 0;
                position: fixed;
                right: 0;
            }
  
        
               </style>
           </head>
           <body onload="window.print()">
           <div class="my-header">
           <img src="assets/HOSP_LOGO.jpg"></div>
           
           <h1><center>Visit List</center></h1>
         
           ${printContents}
          
           </body>
       </html>`
    );
    popupWin.document.close();

    //window.print();
  };

  tableToExcel(table, filename) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById('visitList')
    var ctx = { worksheet: name || 'visit List', table: table.innerHTML }
    //window.location.href = uri + base64(format(template, ctx))

    let link = document.createElement('a');
    link.setAttribute('href', uri + base64(format(template, ctx)));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  clearVisitList() {
    this.visitModel = new ClcVisitQueryVO();
  }

}
