export class ClcIcdTran{
 createdTime: Date;
 icd: string;
 icdStatus: string;
 side: string;
 causeOfDeath: string;
 chronicYn: string;
 icdFlag: string;
 diagText: string;
 diagFlag: string;
 visitId: number;
 createdBy: number;
 cancelVisitId: number;
 patientId: number;
 estCode: number;
 icdTranId: number;
 afiVersion: number;
 confidential:string;
 checked?: boolean;
 public constructor() {}
}