export class ClcPatPolicyCoPays{
 patPolicyId: number;
 itemId: number;
 coPayment: number;
 coPayPercent: number;
 estCode: number;
 runId: number;
 afiVersion: number;
 public constructor() {}
}
	