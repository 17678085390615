import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild ,Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from '@angular/core';
import { ICAREServices } from '../services/Icare.Services';
import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';

declare var $;

@Component({
    selector: 'app-his-visit-search',
    templateUrl: './app-his-visit-search.component.html',
    styleUrls: ['./app-his-visit-search.component.css']

})

export class VisitSearchComponent implements OnInit {
    @Input()
    visitId: number;

    @Output() sendDataToParent = new EventEmitter<any>();

    bsValue = new Date();
    selectedRow: Number;
    private submitted: boolean = false;

    public patientItem: any=[];
    listClinics: any;
    listVisitItem: any;

    visitModel: ClcVisitQueryVO = new ClcVisitQueryVO();
  
    @ViewChild('visitSearchModal', { static: false }) visitSearchModal: ElementRef;
    constructor(private icareservices: ICAREServices,private toastr: ToastrService) { }

    ngOnInit() {
      
      this.retrive();
    }

    private retrive(){
      this.searchPatientList();
      this.icareservices.listClinics().subscribe(response => {
        this.listClinics = response;
      }, err => {
        this.toastr.warning(err.split(":")[3], 'Alert !');
      });
    }

    ngOnChanges() {
        this.searchPatientList();
    }


    selectedVisit(visitColl) {
        this.selectedRow = visitColl;
      }
    
      selectedVisitLisitRow(visitColl) {
  
       /*  if (visitColl.visitId) {
          this.visitId = visitColl.visitId;
          this.submitted = false;
          $(this.visitSearchModal.nativeElement).modal('hide');
          console.log(this.visitId);
        }
        else{ */
          this.sendDataToParent.emit(visitColl);
        //}
      }

      
    /* search patient list */
    searchPatientList() {
      if (this.visitModel.visitFromDate) {      
        this.icareservices.listClcVisitView(this.visitModel)
          .subscribe(response => {
            this.listVisitItem = response;
            
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }
      else {
        var today = new Date();
        var yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
        this.visitModel.visitFromDate = yesterday;
        this.icareservices.listClcVisitView(this.visitModel)
          .subscribe(response => {
            this.listVisitItem = response;
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }
       }

      clearVisitList(){
        this.visitModel=new ClcVisitQueryVO();
      }
}
