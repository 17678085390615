import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Angular2Csv } from 'angular2-csv/Angular2-csv' ;
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { ClcBillQueryVO } from '../resources/ClcBillQueryVO.resource';

import swal from 'sweetalert2';
import { BillVTran } from '../resources/BillVTran.resource';
import { ClcBillPayRcpQueryVO } from '../resources/ClcBillPayRcpQueryVO.resource';
import { BillVTranExt } from '../resources/ext/BillVTranExt';
import { BillPaymentTranExt } from '../resources/ext/BillPaymentTranExt';


declare var $;

@Component({
    selector: 'app-rep-bill-view',
    templateUrl: './app-rep-bill-view.component.html',
    styleUrls: ['./app-rep-bill-view.component.css']

})

export class BillViewComponent implements OnInit {

    billFrom:any;
    billTo:any;

    BillPayFrom:any;
    BillPayTo:any;
    
    
    policyColl: any;
    listBillHdrsColl: any;
    selectedBillItem: any;
    selectedRow: Number;
    listSelectBillColl: any = [];
    formattedOutputCSV:any=[];
    billReport: any;
    billPayment:any;
    billQueryModel: ClcBillQueryVO = new ClcBillQueryVO();
    billPaymentQry:ClcBillPayRcpQueryVO=new ClcBillPayRcpQueryVO();
    billVtranModal:BillVTran=new BillVTran();

    billVtranExtModel:BillVTranExt ;
    billVTranExtColl:BillVTranExt[];

   billPaymentExtModel:BillPaymentTranExt;
   billPaymentExtColl:BillPaymentTranExt[];

   billSendModel: ClcBillQueryVO =new ClcBillQueryVO(); 
   payrecSendModel:ClcBillPayRcpQueryVO=new ClcBillPayRcpQueryVO();
 

    private userColl = this._service.persInfo;
    public estInfo = this._service.estInfo;

    public persItem: any = [];

    @ViewChild('ModalPrintBillSearch', { static: true }) ModalPrintBillSearch: ElementRef;

    constructor(private icareservices: ICAREServices, private _service: ErpServices,public datepipe: DatePipe,private toastr: ToastrService) { }

    ngOnInit() {
        this.retrive();
    }

    private retrive() {
        
        this.icareservices.listMstPolicyMast().subscribe(response => {
            this.policyColl = response;
        }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });

    }

    ngOnChanges() {

    }

    dateFormat(param) {
        return this.datepipe.transform(param, 'yyyy-MM-dd');
      }
      
      timeFormat(param) {
        return this.datepipe.transform(param, 'yyyy-MM-dd HH:mm');
      }

    searchPatientBillList() {
      
       this.billSendModel = Object.assign({}, this.billQueryModel);
        if(this.billSendModel.fromBillDate){
            this.billFrom = this.dateFormat(this.billSendModel.fromBillDate);
            this.billSendModel.fromBillDate=this.billFrom;                   
        }
        if(this.billSendModel.toBillDate){
                this.billTo = this.dateFormat(this.billSendModel.toBillDate);
                this.billSendModel.toBillDate=this.billTo;                   
        }
  
        this.icareservices.listBillHdrs(this.billSendModel)
            .subscribe((response) => {
                this.listBillHdrsColl = response;

                this.listSelectBillColl=[];
            }, err => {
                this.toastr.warning(err.split(":")[3], 'Alert !');
              });
    }

    /* on table row select get billId */
    setClickedRow(billColl) {
        this.selectedRow = billColl;
    }

    getPatientBill(billItem) {
        //this.persItem=billItem;
        this.icareservices.getPatientBill(billItem.billId)
            .subscribe((response) => {
                this.listSelectBillColl = response;
                this.getVisitView(this.listSelectBillColl.visitId);
            }, err => {
                this.toastr.warning(err.split(":")[3], 'Alert !');
              });
    }
    getVisitView(visitId){
        this.icareservices.getClcVisitView(visitId)
            .subscribe((response) => {
                this.persItem = response;
                
            }, err => {
                this.toastr.warning(err.split(":")[3], 'Alert !');
              });
    }
    /* unApproveBill */
    unApproveBill() {
        if (this.listSelectBillColl.billTranDtlView.length > 0) {
            this.icareservices.unApprovePatientBill(this.listSelectBillColl.billId).subscribe(response => {
                if (response) {
                    this.searchPatientBillList();
                    this.getPatientBill(response);
                    this.toastr.success('Bill UnAprrove successfully ', 'UnApprove !');
                    //swal("UnApprove", 'Bill UnAprrove successfully ', 'success');
                }
            }, err => {
                this.toastr.warning(err.split(":")[3], 'Alert !');
              });
        }
        else {
            this.toastr.warning('Please check patient bill is selected', 'Alert !');
            //swal("Oops", 'Please check patient bill is selected', 'warning');
        }
    }

    cancelBill(){
        if (this.listSelectBillColl.billTranDtlView.length > 0) {
            swal({
                title: "Are you sure?",
                text: "You Want to cancel this Bill !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, Cancel it!"
    
            }).then((result) => {
                if (result.value) {
                    this.icareservices.cancelPatientBill(this.listSelectBillColl.billId)
                        .subscribe((response) => {
                            this.searchPatientBillList();
                            this.getPatientBill(response);
                            this.toastr.success('Bill Cancel successfully ', 'Alert !');
                            //swal("Success", 'Bill Cancel successfully ', 'success');
                        }, err => {
                            this.toastr.warning(err.split(":")[3], 'Alert !');
                          });
                }
            });
        }
        else {
            this.toastr.warning('Please check patient bill is selected', 'Alert !');
            //swal("Oops", 'Please check patient bill is selected', 'warning');
        }
       
    }

    downloadBillTranDtl(){ 
        this.billSendModel = Object.assign({}, this.billQueryModel);
        if(this.billSendModel.fromBillDate){
            this.billFrom = this.dateFormat(this.billSendModel.fromBillDate);
            this.billSendModel.fromBillDate=this.billFrom;                   
            }
            if(this.billSendModel.toBillDate){
                this.billTo = this.dateFormat(this.billSendModel.toBillDate);
                this.billSendModel.toBillDate=this.billTo;                   
                }
  
        this.icareservices.listBillVTran(this.billSendModel)
        .subscribe((response) => {           
            this.billVTranExtColl=[];
            for ( let rec of response)
          {
                this.billVtranExtModel = new BillVTranExt();
                //console.log(rec);
                if(rec)
                {
             
                //this.billVtranExtModel.billId = rec.billId ;
                this.billVtranExtModel.billNo = rec.billNo ;    
                this.billVtranExtModel.billType= rec.billType ;
                this.billVtranExtModel.billStatusDesc= rec.billStatusDesc ;
                this.billVtranExtModel.billDate = this.timeFormat(rec.billDate); 

                this.billVtranExtModel.patientNo= rec.patientNo ;
                this.billVtranExtModel.patientName= rec.patientName ;
                this.billVtranExtModel.referenceDesc = rec.referenceDesc ;                 
                this.billVtranExtModel.policyPaymentFlag= rec.policyPaymentFlag ;
                this.billVtranExtModel.itemName= rec.itemName ;
                this.billVtranExtModel.itemDesc= rec.itemDesc ;
                
                this.billVtranExtModel.lineNo = rec.lineNo ;                 
                this.billVtranExtModel.itemQty= rec.itemQty ;
                this.billVtranExtModel.packageItemQty= rec.packageItemQty ;
                this.billVtranExtModel.unitPrice= rec.unitPrice ;
                this.billVtranExtModel.deductionAmt= rec.deductionAmt ;
                this.billVtranExtModel.discountAmt= rec.discountAmt ;
                this.billVtranExtModel.coPayAmt= rec.coPayAmt ;
                this.billVtranExtModel.netAmt = rec.netAmt ; 

                this.billVtranExtModel.approvedByName= rec.approvedByName ;
                this.billVtranExtModel.cancelledByName= rec.cancelledByName ;
                this.billVtranExtModel.policyName = rec.policyName ;  

                this.billVTranExtColl.push( this.billVtranExtModel);
            }
        }
        
                var options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                datePattern: "yyyy-MM-dd HH:mm:ss",
                showLabels: true,
                useBom: true,
                headers: Object.keys(this.billVtranExtModel)
                };

             new Angular2Csv(this.billVTranExtColl, 'Bill Report',options);
                
        });

      
    }
//******/PAYMENT DETAIL EXCEL EXPORT***************//
        downloadBillPaymentTran(){
            this.payrecSendModel = new ClcBillPayRcpQueryVO();
            if(this.billQueryModel.fromBillDate){
                this.BillPayFrom = this.dateFormat(this.billQueryModel.fromBillDate);
                this.payrecSendModel.fromDate=this.BillPayFrom;  
                  
            }
            if(this.billQueryModel.toBillDate){
                    this.BillPayTo = this.dateFormat(this.billQueryModel.toBillDate);
                    this.payrecSendModel.toDate=this.BillPayTo; 
                                 
            }
         this.icareservices.listBillRecptPaymtView(this.payrecSendModel)
        .subscribe((response) => {
           // console.log(response);
            this.billPaymentExtColl=[];
            for ( let rec of response)
            {
                this.billPaymentExtModel=new BillPaymentTranExt();
                if(rec)
                {
                this.billPaymentExtModel.billId = rec.billId;
                this.billPaymentExtModel.billNo = rec.billNo;
                this.billPaymentExtModel.tranDate = this.timeFormat(rec.tranDate);                  
                this.billPaymentExtModel.paymentModeDesc= rec.paymentModeDesc ;
                this.billPaymentExtModel.tranType= rec.tranType ;
                this.billPaymentExtModel.amount= rec.amount ;

                this.billPaymentExtColl.push( this.billPaymentExtModel);
            }
        }
                var options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                datePattern: "yyyy-MM-dd HH:mm:ss",
                showLabels: true,
                useBom: true,
                headers: Object.keys(this.billPaymentExtModel)
                };
               new Angular2Csv(this.billPaymentExtColl, 'Payment Report',options);                
        });
 
    
    }

    get totalNetAmnt() {
        let total = 0;
        if (this.listSelectBillColl.billTranDtlView) {
            for (var i = 0; i < this.listSelectBillColl.billTranDtlView.length; i++) {
                if (this.listSelectBillColl.billTranDtlView[i].netAmt) {
                    total += this.listSelectBillColl.billTranDtlView[i].netAmt;
                }
            }
        }
        return total;
    }

    get totalReceiptAmount() {
        let total = 0;
        if (this.listSelectBillColl.billRecptPaymtTranView) {
            for (var i = 0; i < this.listSelectBillColl.billRecptPaymtTranView.length; i++) {
                if (this.listSelectBillColl.billRecptPaymtTranView[i].tranType=='R') {
                    total += this.listSelectBillColl.billRecptPaymtTranView[i].amount;
                }
            }
        }
        return total;
    }
    get totalPatientPayableAmount() {
        let total = 0;
        if (this.listSelectBillColl.billRecptPaymtTranView) {
            for (var i = 0; i < this.listSelectBillColl.billRecptPaymtTranView.length; i++) {
                if (this.listSelectBillColl.billRecptPaymtTranView[i].tranType=='P') {
                    total += this.listSelectBillColl.billRecptPaymtTranView[i].amount;
                }
            }
        }
        return total;
    }
        
        get totalUnitPrice() {
            let total = 0;
            if (this.listSelectBillColl.billTranDtlView) {
                for (var i = 0; i < this.listSelectBillColl.billTranDtlView.length; i++) {
                    if (this.listSelectBillColl.billTranDtlView[i].unitPrice) {
                        total += this.listSelectBillColl.billTranDtlView[i].unitPrice;
                    }
                }
            }
            return total;
        }
    
        get totalDeducationPrice() {
            let total = 0;
            if (this.listSelectBillColl.billTranDtlView) {
                for (var i = 0; i < this.listSelectBillColl.billTranDtlView.length; i++) {
                    if (this.listSelectBillColl.billTranDtlView[i].deductionAmt) {
                        total += this.listSelectBillColl.billTranDtlView[i].deductionAmt;
                    }
                }
            }
            return total;
        }
    
        get totalQty() {
            let total = 0;
            if (this.listSelectBillColl.billTranDtlView) {
                for (var i = 0; i < this.listSelectBillColl.billTranDtlView.length; i++) {
                    if (this.listSelectBillColl.billTranDtlView[i].itemQty) {
                        total += this.listSelectBillColl.billTranDtlView[i].itemQty;
                    }
                }
            }
            return total;
        }
    
        get totalDiscountPrice() {
            let total = 0;
            if (this.listSelectBillColl.billTranDtlView) {
                for (var i = 0; i < this.listSelectBillColl.billTranDtlView.length; i++) {
                    if (this.listSelectBillColl.billTranDtlView[i].discountAmt) {
                        total += this.listSelectBillColl.billTranDtlView[i].discountAmt;
                    }
                }
            }
            return total;
        }
    

        get totalPaymentAmount() {
            let total = 0;
            if (this.listSelectBillColl) {
                for (var i = 0; i < this.listSelectBillColl.length; i++) {
                    if (this.listSelectBillColl[i].amount) {
                        total += this.listSelectBillColl[i].amount;
                    }
                }
            }
            return total;
        }
    
        get patientPayable() {
            let Total = 0;
            Total += (this.totalNetAmnt - this.totalReceiptAmount) + this.totalPaymentAmount;
            return Math.round(Total * 100) / 100;
        }

        get netTranAmount() {
            let Total = 0;
            Total +=  this.totalReceiptAmount - this.totalPatientPayableAmount;
            return Math.round(Total * 100) / 100;
        }

    clearBillRecptTranModal() {
        //this.recpBillTranModel = new BillRecptPaymtTran();
    }
    clearPatientBillModel(){
        this.billQueryModel=new ClcBillQueryVO();
         this.searchPatientBillList();
       
    }

    
    _printBillTranDtlModal() {             
        $(this.ModalPrintBillSearch.nativeElement).modal('show');
    }

    printBillTranDtlModal(): void {
        let printContents, popupWin;
        printContents = document.getElementById('printBillTranDetails').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
              <html>
                  <head>
                      <title></title>
                      <link rel="stylesheet" href="./assets/css/bootstrap_3.3.7.css" crossorigin="anonymous">
                  <style>
                 
                    html,body{
                    -webkit-print-color-adjust: exact !important;
                      background: transparent; 
                      font-size: 12px;
                    }
                    table.billItem td.price {
                        text-align: right!important;
                      }
                     
                    table {
                        font-size: 11px;
                        border: 2px solid darksalmon !important;
                    }
                    
                  .panel {
                    margin-bottom: 5px!important;
                    }
                    .panel-body {
                      padding: 5px;
                  }
                  .text_space{
                    white-space: pre;
                   }
                    ._td{
                        white-space: pre;
                    }
                   .tbl{
                    white-space: nowrap;
                   }
          
                   
                  </style>
                      </head>
                  <body onload="window.print();window.close()">
          
              
                  ${printContents}
                  
                  </body>
              </html>`
        );
        popupWin.document.close();
    };
}
