import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor,HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as AppUtils from '../common/app.utils';
import 'rxjs/add/operator/do';
import swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 
  constructor(private authenticationService:AuthenticationService,private router: Router,private route: ActivatedRoute) {}
 
/*   if(err instanceof HttpErrorResponse){
    if(err.status === 401){
      // this is where you can do anything like navigating
      this.router.navigateByUrl('/login');
    }
  } */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;

          }
          if (error.status == 401) {
            if (error.error.message == "Unauthorized") {
              errorMessage = `Error Code: ${error.status}\n Message: ${error.error.message}`;
            } else {
              localStorage.removeItem(AppUtils.ACCESS_TOKEN);
              window.location.reload();
            }
          }
          if (error.status == 200) {
            errorMessage = `Message: ${error.error.text}\n  ${error.error.message}`;
          }
          else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\n Message: ${error.error.message}`;

            //swal.fire("Oops!", errorMessage, 'error');
          }
          //swal.fire("Oops!", errorMessage, 'error');
          return throwError(errorMessage);
        })
      )
  }
}