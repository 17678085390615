export class BillVTran{
    billDate: Date;
    createdTime: Date;
    patientNo: string;
    patientName: string;
    referenceDesc: string;
    policyPaymentFlag: string;
    itemName: string;
    itemDesc: string;
    approvedByName: string;
    cancelledByName: string;
    policyName: string;
    billStatusDesc: string;
    billId: number;
    billNo: number;
    billType: number;
    estCode: number;
    visitId: number;
    patientId: number;
    currencyCode: number;
    status: number;
    createdBy: number;
    approvedBy: number;
    cancelledBy: number;
    voucherId: number;
    runId: number;
    lineNo: number;
    itemType: number;
    itemId: number;
    itemQty: number;
    itemUom: number;
    unitPrice: number;
    deductionAmt: number;
    discountAmt: number;
    netAmt: number;
    coPayAmt: number;
    voucherRunId: number;
    tranSource: number;
    sourceRunId: number;
    prevBillRunId: number;
    packageItemQty: number;
    public constructor() {}
   }
  
