export class ReqSlotMast {
    slotMastId: number;
    slotMastDesc: string;
    estCode: number;
    entityCode: number;
    slotStatus: string;
    public constructor() { }
}


