import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../login/login.component';
import { ErpServices } from '../services/index';

import { LoginCredential } from '../HIMS/resources/LoginCredential.resource';
import { MenuComponent } from '../menus/app-menus.component';
import { ICAREServices } from '../HIMS/services/Icare.Services';
import { CVTServices } from '../HIMS/services/cvt.Services';
import { IMASTServices } from '../HIMS/services/Imast.Services';

import swal from 'sweetalert2';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	providers: []
})

export class HomeComponent implements OnInit {
	@ViewChild(MenuComponent, { static: false })
	private _menuComponent: MenuComponent;
	
	@ViewChild('autoShownModal', { static: false }) public autoShownModal: ModalDirective;
	public isModalShown: boolean = false;
	public authenticated: boolean;
	public menus: any;
	public menuItem;

	public tabs = [
		{ title: "Desktop", url: 'DeskTop', active: true, id: 0, prevId: "", removable: false, customClass: "customClass" }
	];

	public moduleItem: any;
	public UserName = null;
	public persCode = null;
	public processCode = [];
	public userProcess: any;
	public counter: number = 0;
	public showStyle: boolean = false;
	public isCollapsed: boolean = false;
	public homeTabSelect: boolean = true;
	public applicationLoading: boolean;
	public modelData = null;
	order = "createdDate";
	ascending = true;

	//FOR PASSWORD CHANGE
	public errorMessage:string;
	public retModelColl:any;
	public pwdConfirm:string;
	changePwdModel:LoginCredential=new LoginCredential();

	constructor(private _login: LoginComponent, private _service: ErpServices,private cvtServices:CVTServices,private icareservices: ICAREServices,private imastServices: IMASTServices) {
		this.showStyle = false;
	}

	ngOnInit() {

		this._service.getMenu().subscribe(res => {
			this.menus = res;
		})

		this._service.getSessionPerson().subscribe(res => {
			let _sessionPersion: any = res;
			this.UserName = _sessionPersion.printName;
			this.persCode = _sessionPersion.persCode;
			this._service.persInfo = _sessionPersion;
			
		})

		this._service.getSessionProcesses().subscribe(res => {
			this.userProcess = res;
			this._service._checkProcessExist(res);
		})

		this._service.getMyEstablishment().subscribe(res => {
			let _establishmentInfo:any= res;
			this._service.estInfo=_establishmentInfo;
     	})
	
		 this.icareservices.listBillableItems().subscribe(response => {
            this._service.billableItems = response;
        })

		this.icareservices.listIcdMaster().subscribe(response => {
			this._service.icdMast = response;
		  })
		  
		this.imastServices.listMstApplicationParams().subscribe(response => {
			this._service.listMstParams = response;
		})
	}


	applicationLoadingCheck() {
		if (this.menus.length == 0 || this.UserName == null) {
			return true;
		} else {
			return false;
		}

	}
	addTab(data) {

		this.homeTabSelect = false;
		let id: number = this.counter + 1;
		let recentCheck: number = 0;
		let tabNumber: number = 0;
		for (let tab of this.tabs) {
			if (tab.prevId == data.menuId) {
				tabNumber = tab.id;

				recentCheck++;
			}
		}
		if (recentCheck == 0) {

			this.tabs.push({
				title: data.menuName, active: true, id: id,
				prevId: data.menuId, removable: true, customClass: "customClass", url: data.moduleName
			});

			this.counter++;

			for (let i in this.tabs) {
				if (this.tabs[i].id == id) {
					this.tabs[i].active = true;
					this.menuItem = this.tabs[i];

				} else {
					this.tabs[i].active = false;
				}
			}
		}
		else {
			for (let j in this.tabs) {
				if (this.tabs[j].id == tabNumber) {
					this.tabs[j].active = true;
				} else {
					this.tabs[j].active = false;
				}
			}
		}

	};
 removeTabHandler(data1) {

		for (let k in this.tabs) {
			if (this.tabs[k].id == data1.id) {
				this.tabs.splice(+k, 1);
			}
		}

	}; 

	openNav() {
		this.showStyle = !this.showStyle;
	}
	closeNav() {
		this.showStyle = false;
	}
	getSidenavStyle() {
		if (this.showStyle) {
			return "250px";
		} else {
			return "0";
		}
	}
	getMainStyle() {
		if (this.showStyle) {
			return "250px";
		} else {
			return "0";
		}
	}
	getToogle() {
		if (this.showStyle) {
			return "toggled";
		} else {
			return "toggled-2";
		}
	}
	tabSelect(tabData) {
		for (let j in this.tabs) {
			if (tabData.url == "home") {
				this.homeTabSelect = true;
			} else {
				this.homeTabSelect = false;
			}
			if (this.tabs[j].id == tabData.id) {
				this.tabs[j].active = true;
			} else {
				this.tabs[j].active = false;
			}
		}
	} 
	logout() {
		this.authenticated = false;
		this._login.logout();
	}

	public changePassword() {
		if (this.persCode) {
			if (this.changePwdModel.newPassword === this.pwdConfirm) {
				this.cvtServices.changePwd(this.changePwdModel)
					.subscribe((response) => {
						this.retModelColl = response;
						console.log(this.retModelColl)
						if (this.retModelColl.resultflag == 1) {
							swal("Save", 'Password changed successfully,Please Relogin !', 'success');
							this.logout();
						}
						else
							swal("Error", this.retModelColl.resultmessage, 'warning');
					},

					);
			}
			else {
				swal("Oops!", 'Mismatch in the password confirmation !', 'warning');
			}
		}
		else {
			swal("Oops!", 'Please Login !', 'warning');
		}
	}

	public showModal(itemData): void {
		this.modelData = null;
		this.modelData = itemData;
		this.isModalShown = true;
	}

	public hideModal(): void {
		this.autoShownModal.hide();
	}

	public onHidden(): void {
		this.isModalShown = false;
	}
}

