import {ClcPatPolicyCoPays} from './ClcPatPolicyCoPays.resource';
export class ClcPatientPolicyColl{
 expiryDate: Date;
 startDate: Date;
 identityCode: string;
 insuredName: string;
 patPolicyId: number;
 patientId: number;
 policyCode: number;
 insuredPatRelation: number;
 estCode: number;
 afiVersion: number;
 focDays:number;
 clcPatPolicyCoPays: ClcPatPolicyCoPays[];
 public constructor() {}
}
