export class ClcPatientNotes{
 notesDate: Date;
 createdTime: Date;
 notes: string;
 printYn: string;
 notesId: number;
 visitId: number;
 notesTypeCode: number;
 notesStatus: number;
 createdBy: number;
 estCode: number;
 afiVersion: number;
 public constructor() {}
}
	
  
