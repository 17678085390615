export class ClcVisitQueryVO{
 visitToDate: Date;
 visitFromDate: Date;
 patientNo: string;
 patientId: number;
 consultantCode: number;
 clinicCode: number;
 deptCode: number;
 visitType: number;
 public constructor() {}
}
	
  
