import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild } from '@angular/core';

import { PrescriptionReportComponent } from 'src/app/HIMS/app-his-prescription-report/app-his-prescription-report.component';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { ClcExamTranColl } from '../resources/ClcExamTranColl.resource';
import { MedPrescriptionTran } from '../resources/MedPrescriptionTran.resource';
import { MedPrescriptionTranView } from '../resources/MedPrescriptionTranView.resource';

declare var $;

import swal from 'sweetalert2';

@Component({
  selector: 'app-his-prescription',
  templateUrl: './app-his-prescription.component.html',
  styleUrls: ['./app-his-prescription.component.css']

})

export class PrescriptionComponent implements OnInit {
  @Input()
  public visitId: number;

  @Input()
  public editYN: string;

  @Input()
  public patientId: number;

  orderDate = new Date();
  genericColl: any;
  medicineColl: any;
  courseColl: any;
  //listVisitPres: any;
  listserviceItems: any;

  public presGenericName:any;
  public presTradeName:any;
  public presCourseName:any;
  private filterResult: any;
  listVisitPres: any = [];
  public listTemp: any = [];
  selectedAll: any;
  //patPrescriptionColl:any=[];
  patPrescriptionColl:MedPrescriptionTran[] = [];

  presItem: MedPrescriptionTran = new MedPrescriptionTran();
  editPresItem: MedPrescriptionTran = new MedPrescriptionTran();
  medPresColl:MedPrescriptionTranView=new MedPrescriptionTranView();
  addPrescriptionDiv: boolean;
  editPrescriptionDiv: boolean;
  //variables for the pagination
  currentPage = 1;
  page: number;
  
  @ViewChild('ModalPrintPrescription', { static: true }) ModalPrintPrescription: ElementRef;
  @ViewChild('ModalDownloadPrescription', { static: true }) ModalDownloadPrescription: ElementRef;
  
  @ViewChild(PrescriptionReportComponent, { static: true })
  private child: PrescriptionReportComponent

  dayType = [{
    value : 'D',
    valueType : 'Days'
  },{
    value : 'W',
    valueType : 'Weeks'
  },{
    value : 'M',
    valueType : 'Months'
  },{
    value : 'Y',
    valueType : 'Years'
  }];

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices,private _sanitizer: DomSanitizer,private toastr: ToastrService) { }

  ngOnInit() {
    this.presItem.prescribedDayType=this.dayType[0].value;
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  ngOnChanges() {
    this.listVisitPrescriptions();
  }

  private retrieve() {

    this.icareservices.listMedGenericMast().subscribe(response => {
      this.genericColl = response;

    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
   
    this.icareservices.listMedCourseMast().subscribe(response => {
      this.courseColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
    
    this.listMedMst();
  }

  listMedMst(){
    this.icareservices.listMedicineMast().subscribe(response => {
      this.medicineColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }
  /** onchange event **/
  onChangeCourse(id) {
    
  return  this.filterResult = this.courseColl.find(item => item.courseCode === id);
   
  }

  bindAddCourse(id){
    this.presItem.medCourseInstructions=this.onChangeCourse(id).printDesc;
  }

  bindEditCourse(id){
    this.editPresItem.medCourseInstructions=this.onChangeCourse(id).printDesc;
  }

  addPrescription(){
    this.listMedMst();
    this.editPrescriptionDiv = false;
    this.addPrescriptionDiv = true;
  }
  showEditPresctiption(){  
    this.listMedMst(); 
    this.addPrescriptionDiv = false;
    this.editPrescriptionDiv = true;
  }


  listVisitPrescriptions() {
    if (this.visitId) {
      this.icareservices.listVisitPrescriptions(this.visitId)
        .subscribe((response) => {
          this.listVisitPres = response;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  // valueChangedOnAddPres(persVal) {
  //   if(persVal.genericId){
  //     let tempTradeColl=this.medicineColl;
  //      this.presItem.genericId=persVal.genericId;
  //      this.filterResult = tempTradeColl.filter(item => item.genericId === +persVal.genericId);
  //   }
  // }

  // valueChangedOnEditPres(persVal) {
  //   if(persVal.genericId){
  //      this.editPresItem.genericId=persVal.genericId;
  //   }
  // }

  onChangeAddGenericPres(persVal) {   
    if(persVal.genericId ){
      let tempTradeColl=this.medicineColl;
       this.presItem.genericId=persVal.genericId;
       this.medicineColl = tempTradeColl.filter(item => item.genericId === +persVal.genericId);
    }
    else{
      this.presTradeName='';
      this.listMedMst();      
    }
  }

  onChangeEditGenericPres(persVal) {   
    if(persVal.genericId ){
      let tempTradeColl=this.medicineColl;
       this.editPresItem.genericId=persVal.genericId;
       this.medicineColl = tempTradeColl.filter(item => item.genericId === +persVal.genericId);
    }
    else{
      this.presTradeName='';
      this.listMedMst();      
    }
  }

  valueChangedOnAddTradeName(persVal) {
    if(persVal.itemId){
       this.presItem.medicineId=persVal.itemId;
    }
  }

  valueChangedOnEditTradeName(persVal) {
    if(persVal.itemId){
       this.editPresItem.medicineId=persVal.itemId;
    }
  }

  valueChangedOnAddCourseName(persVal) {
    if(persVal.courseCode){
       this.presItem.medCourseCode=persVal.courseCode;
       this.bindAddCourse(persVal.courseCode);
    }
  }

  valueChangedOnEditCourseName(persVal) {
    if(persVal.courseCode){
       this.editPresItem.medCourseCode=persVal.courseCode;
       this.bindEditCourse(persVal.courseCode);
    }
  }
  createPrescription() {
    if (this.visitId) {
      this.presItem.visitId = this.visitId;
      this.createPatPrescription(this.presItem)
    }
    else{
      this.toastr.warning('Please select patient first !', 'Alert !');      
      //swal("Oops!", 'Please select patient first !', 'warning');
    }
  }

  createPatPrescription(patPrescription){
    this.icareservices.createPrescription(patPrescription)
    .subscribe((response) => {
      this.listVisitPrescriptions();
      this.addPrescriptionDiv = false;
      this.toastr.success('Prescription Added successfully ', 'Save !');
      this.presItem=new MedPrescriptionTran();
      this.presGenericName=null;
      this.presTradeName=null;
      this.presCourseName=null;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });
  }

  selectPrescription(presColl) {
    this.editPresItem = presColl;
    if(presColl.medGenericMast){
      this.presGenericName = presColl.medGenericMast.genericName;
    }
    if(presColl.medCourseMast){
      this.editPresItem.medCourseCode = presColl.medCourseMast.courseCode;
    }
    if(presColl.medCourseMast){
      this.editPresItem.medCourseInstructions = presColl.medCourseMast.printDesc;
    }
    if(presColl.medPharmacyItemMast){
      this.presTradeName=presColl.medPharmacyItemMast.itemName;
    }
    if (presColl.medPharmacyItemMast == null) {
      let tempTradeColl = this.medicineColl;
      this.medicineColl = tempTradeColl.filter(item => item.genericId === presColl.genericId);
    }
  }

  editPrescription() {
    if (this.visitId) {
      if(this.presGenericName ==undefined||this.presGenericName ==""){
       this.editPresItem.genericId=null;
      }
      this.editPresItem.visitId = this.visitId;
      this.icareservices.editPrescription(this.editPresItem)
        .subscribe((response) => {
          this.listVisitPrescriptions();
          this.editPrescriptionDiv = false;
          this.toastr.success('Prescription Edited successfully ', 'Edited !');
          //swal("Edited", 'Prescription Edited successfully ', 'success');
          this.editPresItem=new MedPrescriptionTran();
          this.presGenericName=null;        
          this.presTradeName=null;
          this.presCourseName=null;
        }, err => {
          this.toastr.warning(err.split(":")[3], 'Alert !');
        });
    }
  }

  clearAddPrescription() {
    this.addPrescriptionDiv = false;
    this.presGenericName=null;
    this.presTradeName=null;
    this.presItem = new MedPrescriptionTran();
  }
  clearEditPrescription() {
    this.editPrescriptionDiv = false;
    this.presGenericName=null;        
    this.presTradeName=null;
    this.editPresItem = new MedPrescriptionTran();
  }


  deletePrescription() {

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"

    }).then((result) => {
      if (result.value) {
        this.icareservices.deletePrescription(this.editPresItem.runId)
          .subscribe((response) => {
            this.listVisitPrescriptions();
            this.clearAddPrescription();
            this.clearEditPrescription();
          }, err => {
            this.toastr.warning(err.split(":")[3], 'Alert !');
          });
      }
    });

  }

  printPrescriptionModal(){
    this.visitId = this.visitId;
    $(this.ModalPrintPrescription.nativeElement).modal('show');
    this.child.ngOnInit();
  }

  downloadPrescriptionModal(){  
    this.icareservices.listPatientAllPrescriptions(this.patientId).subscribe(response => {
      this.patPrescriptionColl = response;
    }, err => {
      this.toastr.warning(err.split(":")[3], 'Alert !');
    });

    $(this.ModalDownloadPrescription.nativeElement).modal('show');   
  }

  selectAll() {
    for (var i = 0; i < this.patPrescriptionColl.length; i++) {
      this.patPrescriptionColl[i].checked = this.selectedAll;
    }
  }

  addPrescriptionItems(presItemColl) {
    if (this.patientId) {
      const itemColl = [] as any;
      let result = presItemColl.filter(x => x.checked)

      if (result.length > 0) {
        for (let rec of result) {
          this.presItem.visitId = this.visitId;
          this.presItem.genericId=rec.genericId;
          this.presItem.medCourseInstructions=rec.medCourseInstructions;
          this.presItem.medCourseCode=rec.medCourseCode;
          this.presItem.medDosageDesc=rec.medDosageDesc;
          this.presItem.prescribedDays=rec.prescribedDays;
          this.presItem.medicineId=rec.medicineId;
          this.createPatPrescription(this.presItem);
        }
      }
      this.selectedAll = false;
      $(this.ModalDownloadPrescription.nativeElement).modal('hide');   
    }
  }

  autocompleteGeneric = (data: any): SafeHtml => {
    let html = `<span>${data.genericName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleteTradeName = (data: any): SafeHtml => {
    let html = `<span>${data.itemName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  
  autocompleteCourse = (data: any): SafeHtml => {
    let html = `<span>${data.courseName}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
