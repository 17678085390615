import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ICAREServices } from '../services/Icare.Services';

import { ClcVisitQueryVO } from '../resources/ClcVisitQueryVO.resource';
import { ClcVisitView } from '../resources/ClcVisitView.resource';

declare var $;

@Component({
  selector: 'app-his-clinical-desk',
  templateUrl: './app-his-clinical-desk.html',
  styleUrls: ['./app-his-clinical-desk.css']
})
export class ClinicalDeskComponent implements OnInit {

  bsValue = new Date();
  selectedRow: Number;
  listClinics: any;
  private currdate = new Date();;
  private submitted: boolean = false;
  public visitId: string; //number;
  public patientId:number;
  public editYN:string;
  public patColl:any;
  public notesTypeCode:number;

  listVisitItem: any;

  visitModel: ClcVisitQueryVO = new ClcVisitQueryVO();

  @ViewChild('visitSearchModal', { static: true }) visitSearchModal: ElementRef;
  @ViewChild('certificateModal', { static: true }) certificateModal: ElementRef;
  

  constructor(private icareservices: ICAREServices, public datepipe: DatePipe,private toastr: ToastrService) { }

  ngOnInit() {
    this.searchPatientList();
    this.icareservices.listClinics().subscribe(response => {
      this.listClinics = response;
    }, err => {
      this.toastr.warning( err.split(":")[3],'Alert !');           
    });

  }

  onKeyPress(event) {
    // this.searchPatient(event.value);
  }

  dateFormat(param) {
    return this.datepipe.transform(param, 'dd-MM-yyyy');
  }

  /* search patient list */
  visitIdChanged() {

   /*  if (this.visitModel.patientNo) {
      this.visitId = this.visitModel.patientNo//visitId;
      this.notesTypeCode=1;
    }
    else { */
      this.submitted = true;
      $(this.visitSearchModal.nativeElement).modal('show');
      //this.notesTypeCode=1;
    //}
  }

  clinicalCertificateModal(){
    this.visitId=this.patColl.visitId;
    $(this.certificateModal.nativeElement).modal('show');
  }

  closeCertificateModal(){
    $(this.certificateModal.nativeElement).modal('hide');
  }
  
  selectedVisit(visitColl) {
    this.selectedRow = visitColl;
  }

  selectedVisitLisitRow(visitColl) {
    if (visitColl.visitId) {
      this.visitId = visitColl.visitId;
      this.patientId=visitColl.patientId;
      this.editYN=visitColl.editYN;
      this.patColl=visitColl;
      this.submitted = false;
      $(this.visitSearchModal.nativeElement).modal('hide');
    }
  }

  searchPatientList() {
    if (this.visitModel.visitFromDate) {      
      this.icareservices.listClcVisitView(this.visitModel)
        .subscribe(response => {
          this.listVisitItem = response;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
    else {
      var today = new Date();
      var yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
      this.visitModel.visitFromDate = yesterday;
      this.icareservices.listClcVisitView(this.visitModel)
        .subscribe(response => {
          this.listVisitItem = response;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }

  }

  clearVisitList() {
    this.visitModel = new ClcVisitQueryVO();
  }

}
