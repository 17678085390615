import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CollapseModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { DatePipe ,PathLocationStrategy, LocationStrategy} from '@angular/common';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ToastrModule } from 'ngx-toastr';
//import { UserIdleModule } from 'angular-user-idle';

import { routing } from './app-routes';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/index';
import { ErpServices,ImasObservable, IMASTServices, ICAREServices, IBILLServices,CVTServices } from './services/index';
import { LoginComponent } from './login/login.component';
import { DesktopComponent } from './desktop/desktop.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menus/app-menus.component';

import { AuthenticationService } from './services/authentication.service';
import { DeptMastComponent } from './HIMS/app-mast-departments/app-mast-departments';
import { ClinicMastComponent } from './HIMS/app-mast-clinics/app-mast-clinics';
import { SchemeMastComponent } from './HIMS/app-mast-schemes/app-mast-schemes';
import { PolicyMasterComponent } from './HIMS/app-mast-policy/app-mast-policy';
import { PatRegisterComponent } from './HIMS/app-his-patient-register/app-his-patient-register';
import { patientSummaryComponent } from './HIMS/app-his-patient-summary/app-his-patient-summary.component';
import { PatientVisitSummaryComponent } from './HIMS/app-his-visit-summary/app-his-visit-summary.component';
import { NursingDeskComponent } from './HIMS/app-his-nurse-desk/app-his-nurse-desk';
import { ClinicalDeskComponent } from './HIMS/app-his-clinical-desk/app-his-clinical-desk';

/* common component */
import { VisitBarComponent } from './HIMS/app-his-visit-bar/app-his-visit-bar';
import { PatientSearchBarComponent } from './HIMS/app-his-patient-search-bar/app-his-patient-search-bar.component';
import { VitalComponent } from './HIMS/app-his-vitals/app-his-vitals.component';
import { TriageComponent } from './HIMS/app-his-triage-bar/app-his-triage-bar';
import { PatientTriageComponent } from './HIMS/app-his-triage/app-his-triage.component';
import { VisitSearchComponent } from './HIMS/app-his-visit-search/app-his-visit-search.component';
import { PatientSearchComponent } from './HIMS/app-his-patient-search/app-his-patient-search.component';
import { PainAssesmentComponent } from './HIMS/app-his-pain-assesment/app-his-pain-assesment.component';
import { PainInterventionComponent } from './HIMS/app-his-pain-intervention/app-his-pain-intervention.component';

/* Modules Component */
import { ExaminationComponent } from './HIMS/app-his-examination-bar/app-his-examination-bar.component';
import { PrescriptionComponent } from './HIMS/app-his-prescription/app-his-prescription.component';
import { ICDComponent } from './HIMS/app-his-icd/app-his-icd.component';
import { AllergiesComponent } from './HIMS/app-his-allergies/app-his-allergies.component';
import { ServiceOrdersComponent } from './HIMS/app-his-orders/app-his-orders.component';
import { CommonTabsComponent } from './HIMS/app-his-tabs/app-his-tabs.component';
import { OrderReportComponent } from './HIMS/app-his-order-report/app-his-order-report.component';
import { PrescriptionReportComponent } from './HIMS/app-his-prescription-report/app-his-prescription-report.component';
import { OpNotesReportComponent } from './HIMS/app-his-opnotes/app-his-opnotes.component';
import { PatientNotesComponent } from './HIMS/app-his-patient-notes/app-his-patient-notes.component';
import { AppointmentComponent } from './HIMS/app-his-appointment/app-his-appointment.component';
import { BillingComponent } from './HIMS/app-his-billing/app-his-billing.component';
import { ClinicalCertificateComponent } from './HIMS/app-his-clinical-certificates/app-his-clinical-certificates.component';
import { BillViewComponent} from'./HIMS/app-rep-bill-view/app-rep-bill-view.component';
import { VisitStatisticsComponent } from './HIMS/app-rep-visit-view/app-rep-visit-view.component';
import { PatientStatisticsComponent } from './HIMS/app-rep-patient-view/app-rep-patient-view.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';


import { BasicAuthInterceptor } from './_helpers';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { from } from 'rxjs';
import { AppReqEntitiesMastComponent } from './HIMS/app-req-entities-mast/app-req-entities-mast.component';
import { AppReqSlotMastComponent } from './HIMS/app-req-slot-mast/app-req-slot-mast.component';
//import { ModalComponentComponent } from './modal-component/modal-component.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import { AppHisPastHistoryComponent } from './HIMS/app-his-past-history/app-his-past-history.component';
import { AppMastPackagesComponent } from './HIMS/app-mast-packages/app-mast-packages.component';
import { AppHisLabOrderReportComponent } from './HIMS/app-his-lab-order-report/app-his-lab-order-report.component';
import { AppHisDownloadPrescriptionComponent } from './HIMS/app-his-download-prescription/app-his-download-prescription.component';
import { AppDailyBillViewComponent } from './HIMS/app-daily-bill-view/app-daily-bill-view.component';
import { AppMastMedicineComponent } from './HIMS/app-mast-medicine/app-mast-medicine.component';

// primeng module
import { AutoCompleteModule } from 'primeng/autocomplete';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    LoginComponent,
    DesktopComponent,
    DeptMastComponent,
    SchemeMastComponent,
    ClinicMastComponent,
    PolicyMasterComponent,
    PatRegisterComponent,
    patientSummaryComponent,
    PatientVisitSummaryComponent,
    NursingDeskComponent,
    ClinicalDeskComponent,
    VitalComponent,
    TriageComponent,
    VisitBarComponent,
    PatientSearchBarComponent,
    CommonTabsComponent,
    ExaminationComponent,
    PrescriptionComponent,
    ICDComponent,
    AllergiesComponent,
    ServiceOrdersComponent,
    VisitSearchComponent,
    OrderReportComponent,
    PrescriptionReportComponent,
    OpNotesReportComponent,
    PatientNotesComponent,
    PatientTriageComponent,
 
    AppointmentComponent,
    PatientSearchComponent,
    BillingComponent,
    ClinicalCertificateComponent,
    BillViewComponent,
    PainAssesmentComponent,
    PainInterventionComponent,
    VisitStatisticsComponent,
    PatientStatisticsComponent,
    AppReqEntitiesMastComponent,
    AppReqSlotMastComponent,
    AppHisPastHistoryComponent,
    AppMastPackagesComponent,
    AppHisLabOrderReportComponent,
    AppHisDownloadPrescriptionComponent,
    AppDailyBillViewComponent,
    AppMastMedicineComponent
         //,
    //Angular2CsvModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    routing,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn',
      
  }),
  NguiAutoCompleteModule,
  NgxPageScrollCoreModule,
  AngularEditorModule,
  PaginationModule.forRoot(),
  NgxPaginationModule,
  FullCalendarModule, // for FullCalendar!
  ToastrModule.forRoot({
    timeOut: 2000,
    preventDuplicates: true
  }),
  AutoCompleteModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    ErpServices,
    CVTServices,
    ImasObservable, 
    IMASTServices,
    ICAREServices,
    IBILLServices,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
