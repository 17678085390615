export class ClcVisits{
 visitTime: string;
 dischargeTime: Date;
 dischargeRemarks: string;
 admOrderDetails: string;
 visitAgeCategory: number;
 afiVersion: number;
 visitId: number;
 visitType: number;
 patientId: number;
 prevVisitId: number;
 visitStatus: number;
 appReservationId: number;
 deptCode: number;
 clinicCode: number;
 estCode: number;
 visitMode: number;
 dischargeMode: number;
 dischargedBy: number;
 patPolicyId: number;
 lastTrfId: number;
 firstTrfId: number;
 patPackageId: number;
 consultantCode: number;
 billRunId: number;
 discTrfId: number;
 admReasonCode: number;
 dischargeCondition: number;
 intRefId: number;
 extRefId: number;
 admOrderId: number;
 public constructor() {}
}
	
  
