﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as AppUtils from '../common/app.utils';


@Injectable()

export class AuthenticationService {

    
    constructor(private http: HttpClient) {  }

    login(username: string, password: string) {

        let headers = new HttpHeaders();//AppUtils.contentHeaders;

            return this.http.post<any>(AppUtils.AUTHENTICATION_URL, { username: username, password: password })
            .map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    //console.log(user.token)
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(AppUtils.ACCESS_TOKEN, user.token);
                }

                return user;
            }); 
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
    }

    
}