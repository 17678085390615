export class ClcPatientViewExt{
    birthDate: string;
    identityNo: string;
    printName: string;
    sexCode: string;
    contactDetail: string;
    patientType: string;
    firstName: string;
    secondName: string;
    thirdName: string;
    fourthName: string;
    mobileNo: string;
    phoneHome: string;
    patientNo: string;
    patientPhoto: string;
    countryName: string;
    nationalityDesc: string;
    maritalstatusDesc: string;
    salutationDesc: string;
    genderDesc: string;
    patientId: number;
    nationalityCode: number;
    maritalstatusCode: number;
    registerStatus: number;
    confidentialityLevel: number;
    estCode: number;
    salutationCode: number;
    motherPatientId: number;
    lastFileTranId: number;
    mergedToPatientId: number;
    afiVersion: number;
    public constructor() {}
   }