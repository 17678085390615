export class ClcIcdMaster{
 icd: string;
 disease: string;
 opcode: string;
 groupid: number;
 groupidStat: number;
 group10Id: number;
 groupDeptid: number;
 public constructor() {}
}
	
  
