import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpServices } from 'src/app/services/index';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';

import { ClcAllergyTran } from '../resources/ClcAllergyTran.resource';

import swal from 'sweetalert2';

@Component({
  selector: 'app-his-allergies',
  templateUrl: './app-his-allergies.component.html',
  styleUrls: ['./app-his-allergies.component.css']

})

export class AllergiesComponent implements OnInit {
  @Input()
  public visitId: number;
  @Input()
  public editYN:string;
  
  allergyDate = new Date();
  icdMstColl: any;
  allTypeColl: any;
  allergentItems: any;
  courseColl: any;
  listAllergyItem: any;
  listserviceItems: any;
  icdDesc: any;
  patientItem: any;
  //variables for the pagination
  currentPage = 1;
  page: number;
  private userColl = this._service.persInfo;
  private filterResult: any;
  public listTemp: any = [];

  allergModel: ClcAllergyTran = new ClcAllergyTran();
  editAllergModel: ClcAllergyTran = new ClcAllergyTran();
  addAllergiesDiv: boolean;
  editAllergiesDiv: boolean;

  constructor(private icareservices: ICAREServices, private imastservices: IMASTServices, private _service: ErpServices,private toastr: ToastrService) { }

  ngOnInit() {
    this.doInit();
  }

  doInit() {
    this.retrieve();
  }

  ngOnChanges() {
    this.listAllergyTran();

  }

  private retrieve() {

    this.icareservices.listClcAllergyTypes().subscribe(response => {
      this.allTypeColl = response;
    }, err => {
      this.toastr.warning( err.split(":")[3],'Alert !');           
    });

    this.icareservices.listClcAllergyNonMedMast().subscribe(response => {
      this.icdMstColl = response;
    }, err => {
      this.toastr.warning( err.split(":")[3],'Alert !');           
    });


  }
  listAllergyTran() {
    if (this.visitId) {
      this.icareservices.listVisitAllergyTran(this.visitId)
        .subscribe((response) => {
          this.listAllergyItem = response;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
  }

  /** onchange event **/
  onChangeAllergyType(id) {
    return this.filterResult = this.icdMstColl.filter(item => item.allergyTypeCode === id);

    //this.filterResult = this.courseColl.find(item => item.courseCode === id);
  }

  bindAllergyType(id) {
    this.allergentItems = this.onChangeAllergyType(id);
  }

  selectAllergyItem(presColl) {
    this.editAllergModel = presColl;
    //this.icdDesc=this.onChangeAllergyType(presColl.icd).disease;
  }

  addNewAllergy(){
    this.addAllergiesDiv = true;
    this.editAllergiesDiv = false;
  }

  editAllergies(){
    this.addAllergiesDiv = false;
    this.editAllergiesDiv = true;

  }


  clearAllergyTran() {
    this.addAllergiesDiv = false;
    this.allergModel = new ClcAllergyTran();

  }
  clearEditAllergyTran() {
    this.editAllergiesDiv = false;
    this.editAllergModel = new ClcAllergyTran();
  }



  getPatientId() {
    if (this.visitId) {
      this.icareservices.getClcVisitView(this.visitId)
        .subscribe((response) => {
          this.patientItem = response;
        }, err => {
          this.toastr.warning( err.split(":")[3],'Alert !');           
        });
    }
  }


  createClcAllergyTran() {
    if (this.visitId) {
      if (this.allergModel.comments) {
        this.allergModel.visitId = this.visitId;
        this.allergModel.createdDt = this.allergyDate;
        this.allergModel.createdBy = this.userColl.persCode;
        this.allergModel.allergyTypeCode = 9;

        this.icareservices.createClcAllergyTran(this.allergModel)
          .subscribe((response) => {
            this.listAllergyTran();
            this.addAllergiesDiv = false;
            this.toastr.success( 'Allergy Added successfully !','Save !'); 
           // swal("Save", 'Allergy Added successfully ', 'success');
            this.allergModel = new ClcAllergyTran();
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
      else {
        this.toastr.warning( 'Please Enter Allergy Description!','Alert !'); 
        //swal("Oops!", 'Please Enter Allergy Description!', 'warning');
      }
    }
    else {
      this.toastr.warning('Please enter Visit Id','Alert !'); 
      //swal("Alert", 'Please enter Visit Id', 'warning');
    }
  }



  editAllergeis() {   

    if (this.visitId) {
      if (this.editAllergModel.comments) {
        this.editAllergModel.visitId = this.visitId;
        this.editAllergModel.createdDt = this.allergyDate;
        this.editAllergModel.createdBy = this.userColl.persCode;
        this.editAllergModel.afiVersion=this.editAllergModel.afiVersion;
        this.icareservices.editClcAllergyTran(this.editAllergModel)
          .subscribe((response) => {
            this.listAllergyTran();
            this.editAllergiesDiv = false;
            //swal("Edited", 'Allergy Edited successfully ', 'success');
            this.toastr.success('Allergy Edited successfully ' ,'Edited !');
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
      else {
        this.toastr.warning('Please Enter Allergy Description' ,'Warning !');
        //swal("Oops!", 'Please Enter Allergy Description!', 'warning');
      }
    }
  }

  deleteOrders() {

    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, delete it!"

    }).then((result) => {
      if (result.value) {
        this.icareservices.deleteClcAllergyTran(this.editAllergModel.patAllergyId)
          .subscribe((response) => {
            this.listAllergyTran();
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
    });

  }
}
