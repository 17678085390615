import {MstPersonalMast} from './MstPersonalMast.resource';
import {MedCourseMast} from './MedCourseMast.resource';
import {MedAdminMast} from './MedAdminMast.resource';
import {MedGenericMast} from './MedGenericMast.resource';
import {MedPharmacyItemMast} from './MedPharmacyItemMast.resource';
export class MedPrescriptionTranView{
 medicationStartTime: Date;
 stoppedDate: Date;
 entryTime: Date;
 cancelledDate: Date;
 medCourseInstructions: string;
 stoppedRemarks: string;
 cancelledRemarks: string;
 medDosageDesc: string;
 medicineRemarks: string;
 runId: number;
 medicineId: number;
 visitId: number;
 medCourseCode: number;
 medAdminCode: number;
 medicineQty: number;
 stoppedBy: number;
 stoppedReason: number;
 enteredBy: number;
 medicineUnit: number;
 prescribedDays: number;
 billRunId: number;
 status: number;
 estCode: number;
 prescribedBy: number;
 cancelledBy: number;
 lineNo: number;
 medDosage: number;
 prescriptionType: number;
 flowRate: number;
 prevPrescMedId: number;
 afiVersion: number;
 genericId: number;
 mstPersonalMast: MstPersonalMast;
 medCourseMast: MedCourseMast;
 medAdminMast: MedAdminMast;
 medGenericMast: MedGenericMast;
 medPharmacyItemMast: MedPharmacyItemMast;
 public constructor() {}
}
	
  
