export class MstPolicyMast{
 policyName: string;
 shortCode: string;
 activeYn: string;
 paymentFlag: string;
 policyCode: number;
 estCode: number;
 policyCompanyEstCode: number;
 schemeCode: number;
 policyType: number;
 afiVersion: number;
 public constructor() {}
}
	
  
