import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';

import * as AppUtils from 'src/app/common/app.utils';
	
import { MstDepartmentMast } from '../resources/MstDepartmentMast.resource'
@Injectable()
 
 export class ImasObservable {

	private AFFINITY_URL_BASE= AppUtils.BACKEND_API_URL ;
  public headerData;
  
	constructor(private http: Http) { }

	private handleError(error: any): Promise<any> {
	 console.error('Could not complete requested service operation', error);
	 return Promise.reject(error.message || error);
	}
	
	 public listMstDepartmentMastObservable(mstdepartmentmast : MstDepartmentMast) {
    this.headerData = new Headers();
    this.headerData.append('Content-Type', 'application/json');
    this.headerData.append('Authorization', localStorage.getItem("access-token"));

    return this.http.post(`${this.AFFINITY_URL_BASE}listMstDepartmentMast` , mstdepartmentmast, {headers: this.headerData})
        .map(data => {
            data.json();
            return data.json();
    });
}

 }