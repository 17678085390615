import { Component, OnInit, OnChanges,HostListener, Input, ElementRef, ViewChild } from '@angular/core';
import { BsDatepickerDirective ,BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { DatePipe ,formatDate} from '@angular/common';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi,EventInput } from '@fullcalendar/angular';

import swal from 'sweetalert2';
import { ICAREServices } from '../services/Icare.Services';
import { IMASTServices } from '../services';
import { ErpServices } from 'src/app/services/index';

import { ClcAppointmentQueryVO } from '../resources/ClcAppointmentQueryVO.resource';
import { ClcGenAppointment } from '../resources/ClcGenAppointment.resource';
import { ClcPatientQueryVO } from '../resources/ClcPatientQueryVO.resource';
import { ReqAppointmentModel } from '../resources/app-resource/ReqAppointmentModel.resource';
import { ReqAppIdModel } from '../resources/app-resource/ReqAppIdModel.resource';

import { INITIAL_EVENTS, createEventId } from 'src/app/services/event-utils';


declare var $;

@Component({
    selector: 'app-his-appointment',
    templateUrl: './app-his-appointment.component.html',
    styleUrls: ['./app-his-appointment.component.css']

})

export class AppointmentComponent implements OnInit {

    @Input()
    patItem: any;

    bsValuefromDate = new Date();
    bsValueToDate = new Date();
    datePickerConfig;
    minDate = new Date();
    appFromDate:any;
    appToDate:any;
    bsValueAppDate;
    
    selectedRow: Number;
    listClinicMst: any;
    listConsultant:any;
    listAppointmentColl: any;
    patColl: any=[];
    patientId:number;
    afiVersion:number;
    retPatColl:any[];
    patName:any=[];
    filterResult:any;
    public patientItem: any=[];
    public patientNo: string;
    public addPatientNo: number;
    public editpatientNo:number;
    private userColl = this._service.persInfo;
    private submitted: boolean = false;
    listReqEntitySchedules:any;
    listReqScheduleSlots:any;
    reqScheduleSlots:any;
    selectedSlotItem:any;
    addReqAppointment: boolean;
    scheduleId:any;

    eventGuid:number ;

    searchAppItem: ClcAppointmentQueryVO = new ClcAppointmentQueryVO();
    appModel: ClcGenAppointment = new ClcGenAppointment();
    editAppModel: ClcGenAppointment = new ClcGenAppointment();
    searchModel: ClcPatientQueryVO = new ClcPatientQueryVO();
    reqAppointmentModel:ReqAppointmentModel=new ReqAppointmentModel();
    reqAppIdModel:ReqAppIdModel=new ReqAppIdModel();
    INITIAL_EVENTS: EventInput = [];
    events = [];
    //variables for the pagination
    currentPage = 1;
    page: number;

    @ViewChild(BsDatepickerDirective, { static: true }) datepicker: BsDatepickerDirective;
    @ViewChild('ModalPatientSearch', { static: true }) ModalPatientSearch: ElementRef;
    @ViewChild('addAppointmentModal', { static: true }) addAppointmentModal: ElementRef;
    @ViewChild('editAppointmentModal', { static: true }) editAppointmentModal: ElementRef;
    @ViewChild('createReqAppModal', { static: true }) createReqAppModal: ElementRef;
    
    @HostListener('window:scroll')
    onScrollEvent() {
      this.datepicker.hide();
    }
    
    constructor(private icareservices: ICAREServices, private _service: ErpServices, public datepipe: DatePipe, public _bsDatepickerConfig: BsDatepickerConfig,private imastservices: IMASTServices,private toastr: ToastrService) {
        this.datePickerConfig = {
            containerClass: 'theme-blue',
            dateInputFormat: 'DD/MM/YYYY',
            showWeekNumbers: false,
        };
    }

    ngOnInit() {
        this.minDate.setDate(this.minDate.getDate() - 1);        
        this.retrieve();
    }

    calendarVisible = true;
    calendarOptions: CalendarOptions = {
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth' //,timeGridWeek,timeGridDay,listWeek
        },
        initialView: 'dayGridMonth',
        //initialEvents: this.handleSchedules.bind(this),//INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        
        select: this.handleEventClick.bind(this),//this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this)
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
    };

    currentEvents: EventApi[] = [];

    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const { calendarOptions } = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        const title = prompt('Please enter a new title for your event');
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect(); // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            });
        }
    }

    //click event on schedule
    handleEventClick(clickInfo: EventClickArg) {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove();
        // }
        this.scheduleId=null;
        if (clickInfo.event._def.publicId) {
             this.scheduleId = +clickInfo.event._def.publicId;
             this.listReqVScheduleSlots(this.scheduleId);
        }
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }

    handleSchedules() {
        //this.currentEvents = events;
    }

    retrieve() {
        //this.searchAppItem.fromDate = new Date();
        this.icareservices.listAppClinicMast().subscribe(response => {
            this.listClinicMst = response;
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });

        this.imastservices.listPersClinicView().subscribe(response => {
            this.listConsultant = response;
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }

    listReqVScheduleSlots(scheduleId){
        this.icareservices.listReqVScheduleSlots(scheduleId).subscribe(response => {
            this.listReqScheduleSlots = response;
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }
    /** onchange event **/
    onChangeClinicCode(id) {
        this.listReqScheduleSlots=[];
        this.INITIAL_EVENTS=[];
        this.eventGuid = 0;
        let entityCode=+id;//id.target.value.split(":")[1];
        //this.filterResult = this.listConsultant.filter(item => item.clinicCode === +clinicCode);
        this.icareservices.listReqEstEntitySchedules(entityCode).subscribe(response => {
            this.listReqEntitySchedules = response;
            if(response){
                for(let item of response){                  
                    this.INITIAL_EVENTS.push({ id:item.scheduleId,title:item.slotTypeDesc,start:this.dateFormat(item.scheduleDate)});                      
                }  
                this.calendarOptions.events =this.INITIAL_EVENTS //([
                    //this.INITIAL_EVENTS
                    // {
                    //     id: createEventId(),
                    //     title: item.slotTypeDesc,
                    //     start: this.dateFormat(item.scheduleDate)
                    // }
                    // {
                    //     id: createEventId(),
                    //     title: 'All-day event',
                    //     start: '2022-01-10'
                    //   },
                    //   {
                    //     id: createEventId(),
                    //     title: 'Timed event',
                    //     start: '2022-01-11'
                    //   }
                //]);  
            }                      
        })       
    }

     createEventId() {
        return String(this.eventGuid++);
      }

    dateFormat(param) {
        return this.datepipe.transform(param, 'yyyy-MM-dd');
    }

    addAppoitmentModal(item) {
        if (item.appId) {
            this.selectedSlotItem = item;
            this.reqAppointmentModel.appId=item.appId;
            //this.reqAppointmentModel.scheduleSlotId = item.scheduleSlotId;
            this.reqAppointmentModel.patientId=item.patientNo;
            this.getPatientViewColl(item.patientNo);           
            $(this.createReqAppModal.nativeElement).modal('show');
        } else {
            this.selectedSlotItem = item;           
            this.reqAppointmentModel.scheduleSlotId = item.scheduleSlotId;           
            $(this.createReqAppModal.nativeElement).modal('show');
        }
    }

    onKeyEnterGetPatientDetails(){
        let patId=this.reqAppointmentModel.patientId.toString();        
        if (patId) {
            this.getPatientViewColl(patId);
        }
      }

    getPatientViewColl(patId) {
        this.searchModel.patientNo = patId;
        this.icareservices.getPatientViewColl(this.searchModel.patientNo)
            .subscribe((response) => {
                this.patientItem = response;
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
    }

    createReqAppoitment() {
        if (this.reqAppointmentModel.patientId == null || this.reqAppointmentModel.patientId == undefined) {
            return this.toastr.warning( 'Please Enter PatientId !','warning !'); 
            //swal("alert !", 'Please Enter PatientId ', 'warning');
        }
        if (this.reqAppointmentModel.appId != null) {
            this.reqAppointmentModel.patientId = this.patientItem.patientId;
            this.icareservices.rescheduleReqAppointment(this.reqAppointmentModel)
                .subscribe((response) => {
                    if (response) {
                        this.listReqVScheduleSlots(this.scheduleId);
                        this.closeSlotModal();                        
                        this.toastr.success('Appointment Reschedule Successfully ','Save !');  
                    }
                }, err => {
                    this.toastr.warning( err.split(":")[3],'Alert !');           
                  });
        } else {
            this.reqAppointmentModel.patientId = this.patientItem.patientId;
            this.icareservices.createReqAppointment(this.reqAppointmentModel)
                .subscribe((response) => {
                    if (response) {
                        this.listReqVScheduleSlots(this.scheduleId);
                        this.closeSlotModal();
                        this.toastr.success('Appointment Created Successfully','Save !'); 
                        //swal("Save", 'Appointment Created Successfully ', 'success');
                    }
                    else{
                        this.closeSlotModal();
                        this.toastr.warning('Appointment Not Created','Alert !'); 
                        //swal("alert", 'Appointment Not Created ', 'warning');
                    }
                }, err => {
                    this.toastr.warning( err.split(":")[3],'Alert !');           
                  });
        }
    }

    closeSlotModal(){
        //this.addReqAppointment=false;
        $(this.createReqAppModal.nativeElement).modal('hide');
        this.reqAppointmentModel=new ReqAppointmentModel();
        this.selectedSlotItem=null; 
        this.patientItem=null;      
    }
    
    onChangescheduleId(scheduleId){
        this.icareservices.listReqVScheduleSlots(scheduleId).subscribe(response => {
            this.reqScheduleSlots = response;
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }

    deleteAppointment(appColl) {
        swal({
            title: "Are you sure?",
            text: "You want to delete the slot ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!"

        }).then((result) => {
            if (result.value) {
                //this.reqAppIdModel.appId=appColl.appId;
                this.icareservices.cancelReqAppointment(appColl.appId)
                    .subscribe((response) => {
                        if (response) {
                            this.listReqVScheduleSlots(this.scheduleId);
                        }
                    }, err => {
                        this.toastr.warning( err.split(":")[3],'Alert !');           
                      });
            }
        });

    }

    patientSearchModal() {
        this.submitted = true;
        $(this.ModalPatientSearch.nativeElement).modal('show');
    }
   
    searchPatientList(patNo) {
       this.searchModel.patientNo= patNo;//this.patColl.patientNo;
        this.icareservices.listPatientColl(this.searchModel)
          .subscribe((response) => {          
            this.retPatColl = response;           
          }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
      }
    
    /* search patient appointment list */
    searchPatientApp() {  
        this.getPatientAppointmentList();
    }

    onChangeSearchPatientNo() {
        if (this.patientNo) {
            this.searchPatientList(this.patientNo);
        }
    }

    onChangeAddPatientNo() {
        if (this.addPatientNo) {
            this.searchPatientList(this.addPatientNo);
        }
    }

    eventFromChild(data) {
        this.searchPatientList(data.patientNo);
        this.patColl = data;
        this.patientNo = this.patColl.patientNo;
        this.submitted = false;
        $(this.ModalPatientSearch.nativeElement).modal('hide');
    }

    getPatientAppointmentList(){
        if(this.patientNo){
           
            this.searchAppItem.patientId = this.retPatColl[0].patientId;
        }
       
        this.appFromDate=this.dateFormat(this.searchAppItem.fromDate);
        this.searchAppItem.fromDate=this.appFromDate;
        this.appToDate=this.dateFormat(this.searchAppItem.toDate);
        this.searchAppItem.toDate=this.appToDate;       
        this.icareservices.listClcGenAppointment(this.searchAppItem)
        .subscribe((response) => {
            
            if (response) {
                this.listAppointmentColl = response;
                this.searchAppItem.fromDate=new Date(this.appFromDate);
                if(this.searchAppItem.toDate){
                    this.searchAppItem.toDate=new Date(this.appToDate);
                }     
            } else {
                this.toastr.warning('No Appointment Found !','Alert !');  
                //swal("Oops!", 'No Appointment Found !', 'warning');
            }
        }, err => {
            this.toastr.warning( err.split(":")[3],'Alert !');           
          });
    }

    addAppointmentDialog() {
        if(this.patientNo){
            this.addPatientNo=this.retPatColl[0].patientNo;
        }
        this.appModel.appDate=this.searchAppItem.fromDate;
        this.appModel.appClinic=this.searchAppItem.clinicCode;
        
        this.submitted = true;
        $(this.addAppointmentModal.nativeElement).modal('show');
    }

    /* add patient appointment list */
    addPatientAppointment() {
        if( this.addPatientNo){                
          if(this.appModel.appClinic){
            this.appModel.patientId = this.retPatColl[0].patientId;
            this.appModel.createdBy = this.userColl.persCode;
            this.appModel.createTime = new Date();
            this.icareservices.createClcGenAppointment(this.appModel)
            .subscribe((response) => {
                if (response) {
                    this.resetSearchItem();                   
                    this.getPatientAppointmentList();   
                    this.addPatientNo=null;
                    this.searchAppItem.fromDate=new Date(this.appFromDate);
                    if(this.appToDate){
                        this.searchAppItem.toDate=new Date(this.appToDate);
                    }    
                    //this.appModel = new ClcGenAppointment();
                    this.toastr.success('Appointment Added Successfully ','Save !');  
                   
                }
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
          }else{
            this.toastr.warning('Please Select Clinic','Alert !');  
            //swal("Alert!", 'Please Select Clinic', 'warning'); 
          }                     
        }
        else{
            this.toastr.warning('Please Select Patient First','Alert !');             
        }
        
    }

    editAppointmentDialog(appColl){
        this.submitted = true;
        $(this.editAppointmentModal.nativeElement).modal('show');
        if(appColl.clcPatients){
         this.editpatientNo=appColl.clcPatients.patientNo;
        }
     
       this.editAppModel.appId=appColl.appId;
        this.editAppModel.patientId=appColl.patientId;
        this.editAppModel.createdBy=appColl.mstPersonalMast.persCode;
        this.editAppModel.appFromTime=appColl.appFromTime;
        this.editAppModel.appToTime=appColl.appToTime;
        this.editAppModel.appClinic=appColl.appClinic;
        this.editAppModel.afiVersion = appColl.afiVersion;
        this.editAppModel.appRemarks=appColl.appRemarks;
      
       var date = new Date(appColl.appDate);
       this.editAppModel.appDate = date;
    }

    editPatientAppointment() {       
        this.editAppModel.createTime = new Date();
        this.icareservices.editClcGenAppointment(this.editAppModel)
            .subscribe((response) => {
                this.searchPatientApp();
                this.toastr.success('Appointment Updated Successfully','Edited !');                  
                this.editAppModel = new ClcGenAppointment();
                this.editpatientNo = null;
                //this.patientNo = null;
            }, err => {
                this.toastr.warning( err.split(":")[3],'Alert !');           
              });
    }

    


    clearModel() {       
        this.searchAppItem=new ClcAppointmentQueryVO();
        this.searchAppItem.fromDate=new Date();
        this.patName=null;
        this.patientNo=null;
        this.addPatientNo=null;
        this.retPatColl=[];
        //this.listAppointmentColl=[];
        //this.getPatientAppointmentList();
    }
    clearAddModal() {
        this.appModel = new ClcGenAppointment();
        this.patName=null;
        this.patientNo=null;
        this.addPatientNo=null;
        this.retPatColl=[];
    }
    clearEditModal() {
        this.editAppModel = new ClcGenAppointment();
        this.patName=null;
    }

    resetSearchItem(){
        //this.searchAppItem = new ClcAppointmentQueryVO();
        //this.searchAppItem.fromDate=new Date();
        this.appModel= new ClcGenAppointment();
        this.retPatColl=[];
        this.patientNo=null;
        this.addPatientNo=null;
    }
}
